import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Submit from './Submit';
import Categories from './Categories';
import NoMatch from './NoMatch';
import SingleNaat from './SingleNaat';
import SingleCategoryList from './SingleCategoryList';
import OtherWebsites from './OtherWebsites';
import NaatBookPDF from './NaatBookPDF';

const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey) || ''
  );

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);

  return [value, setValue];
};

function App() {
  const [theme_name, setThemeName] = useStateWithLocalStorage('theme_name');
  const [theme_background_color, setBackgroundColor] = useStateWithLocalStorage(
    'theme_background_color'
  );
  const [nav_background_color, setNavBackgroundColor] =
    useStateWithLocalStorage('nav_background_color');
  const [theme_font_color, setThemeFontColor] =
    useStateWithLocalStorage('font_color');
  const [theme_font_color1, setThemeFontColor1] =
    useStateWithLocalStorage('font_color1');
  const [theme_font_color2, setThemeFontColor2] =
    useStateWithLocalStorage('font_color2');

  const setThemeDefault = () => {
    setThemeName('default');
    setBackgroundColor('white');
    setNavBackgroundColor('#000051');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };
  const setThemeMadina = () => {
    setThemeName('madina');
    setBackgroundColor('#F0FFF0');
    setNavBackgroundColor('#003300');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };
  const setThemeMecca = () => {
    setThemeName('mecca');
    //setBackgroundColor('#3B3B3B')
    setBackgroundColor('#3F3F3F');
    setNavBackgroundColor('#212121');
    setThemeFontColor('white');
    setThemeFontColor1('#33FFE9');
    setThemeFontColor2('#FF9962');
    window.location.reload();
  };
  const setThemeAlaHazrat = () => {
    setThemeName('ala_hazrat');
    setBackgroundColor('#F0FFFF');
    setNavBackgroundColor('#00363a');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };

  if (theme_name === '') {
    setThemeName('default');
    setBackgroundColor('white');
    setNavBackgroundColor('#000051');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
  }

  // if (theme_name === '') {
  //   setBackgroundColor('white')
  //   setThemeName('default')
  // }
  // const m_background_color = ''
  // var m_background_color = '#111111'
  // if (theme_name === 'madina') {
  //   m_background_color = '#F0FFF0'
  // } else if (theme_name === 'mecca') {
  //   m_background_color = '#1A1A1A'
  // } else if (theme_name === 'ala_hazrat') {
  //   m_background_color = '#F0FFFF'
  // } else {
  //   m_background_color = 'white'
  // }

  return (
    <React.Fragment>
      <div
        className="app_background"
        style={{ backgroundColor: theme_background_color }}
      >
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => <Home {...props} type="home" />}
            />
            <Route
              exact
              path="/naats"
              render={(props) => <Home {...props} type="naat" />}
            />
            <Route
              exact
              path="/salaams"
              render={(props) => <Home {...props} type="salaam" />}
            />
            <Route
              exact
              path="/hamds"
              render={(props) => <Home {...props} type="hamd" />}
            />
            <Route
              exact
              path="/manqabats"
              render={(props) => <Home {...props} type="manqabat" />}
            />
            <Route
              exact
              path="/naatkhawans"
              render={(props) => <Categories {...props} type="naat_khawan" />}
            />
            <Route
              exact
              path="/categories"
              render={(props) => <Categories {...props} type="categories" />}
            />
            <Route
              exact
              path="/naatwebsites"
              render={(props) => <OtherWebsites {...props} />}
            />
            <Route
              exact
              path="/about"
              render={(props) => <About {...props} />}
            />
            <Route
              exact
              path="/naatbookpdf"
              render={(props) => <NaatBookPDF {...props} />}
            />
            <Route
              exact
              path="/submit"
              render={(props) => <Submit {...props} />}
            />

            <Route
              path="/home/:naat_name"
              render={(props) => <SingleNaat {...props} naat_type="home" />}
            />

            <Route
              path="/naats/:naat_name"
              render={(props) => <SingleNaat {...props} naat_type="naat" />}
            />

            <Route
              path="/salaams/:naat_name"
              render={(props) => <SingleNaat {...props} naat_type="salaam" />}
            />

            <Route
              path="/hamds/:naat_name"
              render={(props) => <SingleNaat {...props} naat_type="hamd" />}
            />

            <Route
              path="/manqabats/:naat_name"
              render={(props) => <SingleNaat {...props} naat_type="manqabat" />}
            />

            {/* Handle Single Naat through NaatKhawan or Category */}
            <Route
              path="/naatkhawans/naats/:naat_name"
              render={(props) => <SingleNaat {...props} naat_type="naat" />}
            />

            <Route
              path="/naatkhawans/salaams/:naat_name"
              render={(props) => <SingleNaat {...props} naat_type="salaam" />}
            />

            <Route
              path="/naatkhawans/hamds/:naat_name"
              render={(props) => <SingleNaat {...props} naat_type="hamd" />}
            />

            <Route
              path="/naatkhawans/manqabats/:naat_name"
              render={(props) => <SingleNaat {...props} naat_type="manqabat" />}
            />

            <Route
              path="/categories/naats/:naat_name"
              render={(props) => <SingleNaat {...props} naat_type="naat" />}
            />

            <Route
              path="/categories/salaams/:naat_name"
              render={(props) => <SingleNaat {...props} naat_type="salaam" />}
            />

            <Route
              path="/categories/hamds/:naat_name"
              render={(props) => <SingleNaat {...props} naat_type="hamd" />}
            />

            <Route
              path="/categories/manqabats/:naat_name"
              render={(props) => <SingleNaat {...props} naat_type="manqabat" />}
            />

            {/* Handle error paths */}
            <Route
              exact
              path="/naatkhawans/naats"
              render={(props) => <Categories {...props} type="naat_khawan" />}
            />

            <Route
              exact
              path="/naatkhawans/salaams"
              render={(props) => <Categories {...props} type="naat_khawan" />}
            />

            <Route
              exact
              path="/naatkhawans/hamds"
              render={(props) => <Categories {...props} type="naat_khawan" />}
            />

            <Route
              exact
              path="/naatkhawans/manqabats"
              render={(props) => <Categories {...props} type="naat_khawan" />}
            />

            <Route
              exact
              path="/categories/naats"
              render={(props) => <Categories {...props} type="categories" />}
            />

            <Route
              exact
              path="/categories/salaams"
              render={(props) => <Categories {...props} type="categories" />}
            />

            <Route
              exact
              path="/categories/hamds"
              render={(props) => <Categories {...props} type="categories" />}
            />

            <Route
              exact
              path="/categories/manqabats"
              render={(props) => <Categories {...props} type="categories" />}
            />

            {/* Handle NaatKhawan or Category */}
            <Route
              path="/naatkhawans/:name"
              render={(props) => (
                <SingleCategoryList {...props} naat_type="naat_khawan" />
              )}
            />

            <Route
              path="/categories/:name"
              render={(props) => (
                <SingleCategoryList {...props} naat_type="category" />
              )}
            />

            <Route component={NoMatch} />
          </Switch>
        </Router>
      </div>
    </React.Fragment>
  );
}

export default App;
