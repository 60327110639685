import React, { useState, useEffect } from 'react';
import './App.css';
import './other.css';
// import nathData from './nath-data/naat-json.json';
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Image,
  Button,
  Form,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';

//Button Group
import ScrollToTop from './ScrollToTop';

const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey) || ''
  );

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);

  return [value, setValue];
};

export const Submit = (props) => {
  const [theme_name, setThemeName] = useStateWithLocalStorage('theme_name');
  const [theme_background_color, setBackgroundColor] = useStateWithLocalStorage(
    'theme_background_color'
  );

  const [nav_background_color, setNavBackgroundColor] =
    useStateWithLocalStorage('nav_background_color');
  const [theme_font_color, setThemeFontColor] =
    useStateWithLocalStorage('font_color');
  const [theme_font_color1, setThemeFontColor1] =
    useStateWithLocalStorage('font_color1');
  const [theme_font_color2, setThemeFontColor2] =
    useStateWithLocalStorage('font_color2');

  const setThemeDefault = () => {
    setThemeName('default');
    setBackgroundColor('white');
    setNavBackgroundColor('#000051');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };
  const setThemeMadina = () => {
    setThemeName('madina');
    setBackgroundColor('#F0FFF0');
    setNavBackgroundColor('#003300');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };
  const setThemeMecca = () => {
    setThemeName('mecca');
    setBackgroundColor('#3F3F3F');
    setNavBackgroundColor('#212121');
    setThemeFontColor('white');
    setThemeFontColor1('#33FFE9');
    setThemeFontColor2('#FF9962');
    window.location.reload();
  };
  const setThemeAlaHazrat = () => {
    setThemeName('ala_hazrat');
    setBackgroundColor('#F0FFFF');
    setNavBackgroundColor('#00363a');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };

  if (theme_name === '') {
    setThemeName('default');
    setBackgroundColor('white');
    setNavBackgroundColor('#000051');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
  }

  // const [theme, setTheme] = useState("light");
  console.log('The Page type to render is: ' + props.type);

  return (
    <React.Fragment>
      {/* <Navbar bg={theme} expand="lg" variant={theme}>
				<Navbar.Brand href="#home">My Naat Book</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="mr-auto">
						<Nav.Link href="/home">Home</Nav.Link>
						<Nav.Link href="/naats">Naat</Nav.Link>
						<Nav.Link href="#salaams">Salaam</Nav.Link>
						<Nav.Link href="#hamds">Hamd</Nav.Link>
						<Nav.Link href="#manqabats">Manqabat</Nav.Link>
						<Nav.Link href="#naat_khawans">Naat Khawans</Nav.Link>
						<Nav.Link href="#categories">Catergories</Nav.Link>
					</Nav>
					<Button onClick={() => theme === "light" ? setTheme("dark") : setTheme("light")} variant="outline-success">Theme</Button>
				</Navbar.Collapse>
			</Navbar> */}
      <Helmet>
        <title>About - My Naat Book</title>
      </Helmet>

      <Navbar
        expand="lg"
        variant="dark"
        style={{ backgroundColor: nav_background_color }}
      >
        <Navbar.Brand
          href="/"
          style={{
            fontFamily: 'font_c',
            color: 'white',
            fontSize: '25px',
          }}
        >
          <img
            src={process.env.PUBLIC_URL + '/madina.png'}
            width="30"
            height="30"
            className="d-inline-block align-top"
            style={{ marginRight: '7px' }}
            alt="My Naat Book"
          />
          My Naat Book
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {/* <Nav.Link href="/">Home</Nav.Link> */}
            <Nav.Link href="/naats">Naat</Nav.Link>
            <Nav.Link href="/salaams">Salaam</Nav.Link>
            <Nav.Link href="/hamds">Hamd</Nav.Link>
            <Nav.Link href="/manqabats">Manqabat</Nav.Link>
            <Nav.Link href="/naatkhawans">Naat Khawans</Nav.Link>
            <Nav.Link href="/categories">Catergories</Nav.Link>
            <NavDropdown title="Theme" id="collasible-nav-dropdown">
              <NavDropdown.Item onClick={setThemeDefault}>
                Default
              </NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeMadina}>
                Madina
              </NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeMecca}>Mecca</NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeAlaHazrat}>
                Ala Hazrat
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="More" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/naatwebsites">
                Other Naat Websites
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/submit">
                Submit a Naat / Contact Us
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/naatbookpdf">
                Naat Books PDF
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/about">About</NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link href="/naatwebsites">Other Naat Websites</Nav.Link>
						<Nav.Link href="/about">About</Nav.Link>
						<Nav.Link className="justify-content-end" href="/app">Android App</Nav.Link> */}
          </Nav>
          <Button
            variant="success"
            href="https://drive.google.com/file/d/1WZ2I4RFURKy4ctVJzoLmZgZn9rB84iwk/view?usp=sharing"
            target="_blank"
          >
            Android App
            <span>
              <FontAwesomeIcon
                icon={faMobileAlt}
                style={{ marginLeft: '8px' }}
              />
            </span>
          </Button>
        </Navbar.Collapse>
      </Navbar>

      <ScrollToTop />

      {/* style={{backgroundColor:"navajowhite"}} */}
      <Container fluid>
        <Row style={{ textAlign: 'center', justifyContent: 'center' }}>
          <h3
            style={{
              textAlign: 'center',
              marginTop: '15px',
              marginBottom: '10px',
              color: theme_font_color,
            }}
          >
            <b>Naat Submission</b>
          </h3>
        </Row>

        <Row
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            marginTop: '15px',
          }}
        >
          <h5 style={{ color: theme_font_color }}>
            You can also send the naat or your suggestions at{' '}
            <b>ashiqerasool.naatbook@gmail.com</b>
          </h5>
        </Row>
      </Container>

      <div
        style={{
          marginLeft: '10px',
          marginRight: '10px',
          color: theme_font_color,
          padding: '50px',
        }}
      >
        <Form name="user_naat_form" method="POST" data-netlify="true">
          <input type="hidden" name="form-name" value="user_naat_form" />
          <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="name@example.com"
              name="user_email_form"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Please provide your name"
              name="user_name_form"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Please input the naat here</Form.Label>
            <Form.Control
              as="textarea"
              rows={15}
              placeholder="Please put the Naat, Salaam, Hamd or Manqabat here..."
              name="user_naat_body_form"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Suggestions</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Please tell use your suggestions. Your suggestions are really important to us. Please provide which area you want us to improve. Jazakallah"
              name="user_suggestion_form"
            />
          </Form.Group>
          <div style={{ textAlign: 'center' }}>
            <Button
              as="input"
              type="submit"
              value="Submit"
              style={{ width: '80%', marginTop: '25px' }}
            />{' '}
          </div>
        </Form>

        <h5
          style={{
            textAlign: 'center',
            marginTop: '50px',
            marginBottom: '150px',
          }}
        >
          <b>
            Inshallah we will try to include this on the website as soon as
            possible. Jazakallah
          </b>
        </h5>
      </div>
    </React.Fragment>
  );
};

export default Submit;
