import React, { useState, useEffect } from 'react';
import './App.css';
// import nathData from './nath-data/naat-json.json';
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Col,
  Image,
  Button,
} from 'react-bootstrap';
// import NaatKhawanRow from './NaatKhawanRow';
import './other.css';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';

//Button Group
// import ScrollToTop from './ScrollToTop';

const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey) || ''
  );

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);

  return [value, setValue];
};

export const OtherWebsites = (props) => {
  // var type = props.type;

  const [theme_name, setThemeName] = useStateWithLocalStorage('theme_name');
  const [theme_background_color, setBackgroundColor] = useStateWithLocalStorage(
    'theme_background_color'
  );

  const [nav_background_color, setNavBackgroundColor] =
    useStateWithLocalStorage('nav_background_color');
  const [theme_font_color, setThemeFontColor] =
    useStateWithLocalStorage('font_color');
  const [theme_font_color1, setThemeFontColor1] =
    useStateWithLocalStorage('font_color1');
  const [theme_font_color2, setThemeFontColor2] =
    useStateWithLocalStorage('font_color2');

  const setThemeDefault = () => {
    setThemeName('default');
    setBackgroundColor('white');
    setNavBackgroundColor('#000051');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };
  const setThemeMadina = () => {
    setThemeName('madina');
    setBackgroundColor('#F0FFF0');
    setNavBackgroundColor('#003300');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };
  const setThemeMecca = () => {
    setThemeName('mecca');
    setBackgroundColor('#3F3F3F');
    setNavBackgroundColor('#212121');
    setThemeFontColor('white');
    setThemeFontColor1('#33FFE9');
    setThemeFontColor2('#FF9962');
    window.location.reload();
  };
  const setThemeAlaHazrat = () => {
    setThemeName('ala_hazrat');
    setBackgroundColor('#F0FFFF');
    setNavBackgroundColor('#00363a');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };

  if (theme_name === '') {
    setThemeName('default');
    setBackgroundColor('white');
    setNavBackgroundColor('#000051');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
  }

  return (
    <React.Fragment>
      <Navbar
        expand="lg"
        variant="dark"
        style={{ backgroundColor: nav_background_color }}
      >
        <Navbar.Brand
          href="/"
          style={{
            fontFamily: 'font_c',
            color: 'white',
            fontSize: '25px',
          }}
        >
          <img
            src={process.env.PUBLIC_URL + '/madina.png'}
            width="30"
            height="30"
            className="d-inline-block align-top"
            style={{ marginRight: '7px' }}
            alt="My Naat Book"
          />
          My Naat Book
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {/* <Nav.Link href="/">Home</Nav.Link> */}
            <Nav.Link href="/naats">Naat</Nav.Link>
            <Nav.Link href="/salaams">Salaam</Nav.Link>
            <Nav.Link href="/hamds">Hamd</Nav.Link>
            <Nav.Link href="/manqabats">Manqabat</Nav.Link>
            <Nav.Link href="/naatkhawans">Naat Khawans</Nav.Link>
            <Nav.Link href="/categories">Catergories</Nav.Link>
            <NavDropdown title="Theme" id="collasible-nav-dropdown">
              <NavDropdown.Item onClick={setThemeDefault}>
                Default
              </NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeMadina}>
                Madina
              </NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeMecca}>Mecca</NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeAlaHazrat}>
                Ala Hazrat
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="More" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/naatwebsites">
                Other Naat Websites
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/submit">
                Submit a Naat / Contact Us
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/naatbookpdf">
                Naat Books PDF
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/about">About</NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link href="/naatwebsites">Other Naat Websites</Nav.Link>
						<Nav.Link href="/about">About</Nav.Link>
						<Nav.Link className="justify-content-end" href="/app">Android App</Nav.Link> */}
          </Nav>
          <Button
            variant="success"
            href="https://drive.google.com/file/d/1WZ2I4RFURKy4ctVJzoLmZgZn9rB84iwk/view?usp=sharing"
            target="_blank"
          >
            Android App
            <span>
              <FontAwesomeIcon
                icon={faMobileAlt}
                style={{ marginLeft: '8px' }}
              />
            </span>
          </Button>
        </Navbar.Collapse>
      </Navbar>

      <Helmet>
        <title>Other Websites - My Naat Book</title>
      </Helmet>

      {/* <ScrollToTop /> */}

      <h3
        style={{
          textAlign: 'center',
          marginTop: '15px',
          marginBottom: '10px',
          color: theme_font_color,
        }}
      >
        <b>Naat Lyrics Websites</b>
      </h3>
      {/* <hr className="type_1" /> */}

      <Container>
        <a href="https://naatepaak.com/islamiclyrics/" target="_blank">
          <Row style={{ backgroundColor: 'cadetblue' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              {/* <NaatKhawanRow category="naat_khawan" name="Owais Raza Qadri" /> */}
              <Image
                src={process.env.PUBLIC_URL + '/naatwebsites/naat_e_paak2.png'}
                alt="Naat-E-Paak.com"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>naatepaak.com</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'white',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                This website offers you praises of the Lord and His Beloved
                (peace and blessings be upon him. (audio naatein, video, naat
                lyrics, etc) We are the followers of ahl al-Sunna wa al-jama’a
                in both belief (‘aqida) and jurisprudence (fiqh). We accept what
                the scholars of ahl al-Sunna accept, and we reject what the
                scholars reject.
              </p>
            </Col>
          </Row>
        </a>
        <hr />
        <a href="http://www.naatsharif.in/" target="_blank">
          <Row style={{ backgroundColor: 'bisque' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={process.env.PUBLIC_URL + '/naatwebsites/naat_sharif.png'}
                alt="NaatSharif.in"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'black',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>naatsharif.in</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'black',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                This website has a large collection of naats, salaams, hamds and
                manqabats. It is a beautiful website and they provide Islamic
                books. They have categories such as Ala Hazrat, Allama Hasan
                Raza Khan, Mufti Aazam e Hind, Azhari Miyan, Nazmi Miyan etc.
                May Allah give them elite place in jannat ul firdous for their
                hard work in making this website.
              </p>
            </Col>
          </Row>
        </a>
        <hr />
        <a href="https://www.kalaam-e-raza.in" target="_blank">
          <Row style={{ backgroundColor: 'brown' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={process.env.PUBLIC_URL + '/naatwebsites/kalaam_e_raza.PNG'}
                alt="kalaam-e-raza.in"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>kalaam-e-raza.in</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'white',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                Kalaam e Raza has a beautiful presentation. It is very easy to
                read naats from the website due to the color theme. Along with
                the lyrics for so many popular naats they provide audio naats as
                well. They have categories such as Kalaam E Raza, Kalaam E
                Noori, Salaam, Hamd, Manqabat etc. May Allah give them elite
                place in jannat ul firdous for their hard work in making this
                website.
              </p>
            </Col>
          </Row>
        </a>
        <hr />
        <a href="https://barkateraza.com/naat-lyrics/" target="_blank">
          <Row style={{ backgroundColor: 'royalblue' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={process.env.PUBLIC_URL + '/naatwebsites/barkate_raza.PNG'}
                alt="barkateraza.com"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>barkateraza.com</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'white',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                Barkate Raza’s Objectives is to provide Authentic Islamic
                information available on scattered websites to mankind (Muslims
                and Non- Muslims) of the world and to educate them with the
                Authentic Teachings of Islam. It is dedicated to increasing
                Islamic knowledge to Muslims all over the world. Knowledge is a
                very powerful tool to understand and practice Islam. Barkate
                Raza is working in about 100 languages of the world which
                includes English, Arabic, Urdu, Hindi, Telugu, Tamil, Kannada,
                and other foreign languages.
              </p>
            </Col>
          </Row>
        </a>
        <hr />
        <a
          href="http://www.islamiclyrics.net/artist/naat-lyrics/"
          target="_blank"
        >
          <Row style={{ backgroundColor: 'aquamarine' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={
                  process.env.PUBLIC_URL + '/naatwebsites/islamic_lyrics.jpg'
                }
                alt="islamiclyrics.net"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'black',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>islamiclyrics.net</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'black',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                slamic Lyrics is a religious media website which contains
                Islamic lyrics and videos. In an artist’s profile we provide
                artist’s information such as picture, biography and website.
                Also, each of the artist profiles below provide the detail
                regarding albums, lyrics and videos. All of the lyrics are
                alphabetically organized and can also be accessed through Muslim
                artists. On the main page the lyrics are theme categorized, for
                instance, naats, nasheeds, and nohas and are mostly in three
                languages: English, Punjabi and Urdu. We tend to provide all
                lyrics and videos that can be most liked by all Muslim brothers
                and sisters and can be heard by both Sunnis and Shias.
              </p>
            </Col>
          </Row>
        </a>
        <hr />
        <a href="https://www.madanilyrics.com/" target="_blank">
          <Row style={{ backgroundColor: 'slateblue' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={process.env.PUBLIC_URL + '/naatwebsites/madani_lyrics.jpg'}
                alt="madanilyrics.com"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>madanilyrics.com</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'white',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                MadaniLyrics.com also known as hamd-o-naatlyrics.blogspot has a
                large collection of naats, salaams, hamds and manqabats. It is a
                beautiful website, along with the lyrics they also provide naat
                videos. They have Labels such as HAFIZTAHIRQADRI,
                KUSHBOOEKUSHTAR, OWAISRAZAQADRI, QARIRIZWAN etc. May Allah give
                them elite place in jannat ul firdous for their hard work in
                making this website.
              </p>
            </Col>
          </Row>
        </a>
        <hr />
        <a href="http://www.iqra.co.in/naat/lyrics" target="_blank">
          <Row style={{ backgroundColor: 'tomato' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={process.env.PUBLIC_URL + '/naatwebsites/iqra.png'}
                alt="iqra.co.in"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>iqra.co.in</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'white',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                Iqra.co.in has a huge collection of naat lyrics. Along with the
                lyrics they also provide Naat videos, Quran, Islamic Wallpapers,
                Islamic Books, Muslim Names, Islamic SMS and Blogs. They present
                lyrics in a simple and readable manner. May Allah give them
                elite place in jannat ul firdous for their hard work in making
                this website.
              </p>
            </Col>
          </Row>
        </a>
        <hr />
        <a href="http://www.naatlyrics.com/" target="_blank">
          <Row style={{ backgroundColor: 'black' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={process.env.PUBLIC_URL + '/naatwebsites/naat_lyrics.png'}
                alt="naatlyrics.com"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>naatlyrics.com</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'white',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                NaatLyrics.com carry abundant collection of Naat lyrics. Along
                with the lyrics they also provide Islamic forum and ability to
                download the naat lyrics in pdf form. They present lyrics 3 main
                categories Naat Khawans, Urdu Naat Lyrics, Roman Naat Lyrics.
                May Allah give them elite place in jannat ul firdous for their
                hard work in making this website.
              </p>
            </Col>
          </Row>
        </a>
        <hr />
        <a href="http://lyricsofnaat.blogspot.com/" target="_blank">
          <Row style={{ backgroundColor: 'seagreen' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={
                  process.env.PUBLIC_URL + '/naatwebsites/lyrics_of_naat.png'
                }
                alt="lyricsofnaat.blogspot.com"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>lyricsofnaat.blogspot.com</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'white',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                LyricsOfNaat.BlogSpot.com have huge collection of Naat lyrics.
                They present lyrics many categories such as Owais Raza Qadri,
                Aamir Liaquat Hussain, Milad Raza Qadri, Qari Mohammed Rizwan,
                Sami Yusuf etc. May Allah give them elite place in jannat ul
                firdous for their hard work in making this website.
              </p>
            </Col>
          </Row>
        </a>
        <hr />
        <a href="http://www.naat-e-sarkar.com/" target="_blank">
          <Row style={{ backgroundColor: 'steelblue' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={
                  process.env.PUBLIC_URL + '/naatwebsites/naat_e_sarkaar.png'
                }
                alt="naat-e-sarkar.com"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>naat-e-sarkar.com</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'white',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                Naat-E-Sarkaar.com have Huge Collection of Lyrics of Naats,
                Manqabats, Salams, Nasheed, etc. They have a facility to request
                lyrics. If someone requests a particular naat lyrics they
                provide it. May Allah give them elite place in jannat ul firdous
                for their hard work in making this website.
              </p>
            </Col>
          </Row>
        </a>
        <hr />
        <a
          href="https://muhammadisite.com/category/naat-lyrics/"
          target="_blank"
        >
          <Row style={{ backgroundColor: 'lightseagreen' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={process.env.PUBLIC_URL + '/naatwebsites/muhammadisite.jpg'}
                alt="muhammadisite.com"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>muhammadisite.com</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'white',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                Welcome to Muhammadi Site. My name is Wasim Sama. I am Sunni
                Muslim from India. Muhammadi site is dedicated to all Muslims
                and non-Muslims who want to know about Islam, Learn Islam and
                looking for Islamic stuff. Muhammadi site provides Islamic stuff
                like Surahs of Quran, 6 Kalimas, Videos, explore Mosques,
                Dargahs, Islamic web directory, download free Islamic books and
                much more Islamic stuffs in many languages like English, Arabic,
                Hindi.
              </p>
            </Col>
          </Row>
        </a>
        <hr />
        <a href="https://naatacademy.com/category/lyrics/" target="_blank">
          <Row style={{ backgroundColor: 'sienna' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={process.env.PUBLIC_URL + '/naatwebsites/naat_academy.png'}
                alt="naatacademy.com"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>naatacademy.com</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'white',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                Naat Academy - A Unique Practical Effort to Promote Naat
                Education and Literature and Promote Standard Naat. I am proud
                of my destiny for this I was also chosen for his Naat Owais
                Azhar Madani. Promoting the love and affection of the Holy
                Prophet, respect for the Ahle Bayt and Sahaba and the honor of
                the saints and Sufis. Communicating the teachings of Naat in the
                light of the secrets of the Salaf-e-Saliheen, Awliya-e-Kamleen
                and the elders of the religion.
              </p>
            </Col>
          </Row>
        </a>

        {/* Audio Naat Websites Start Here */}

        <h3
          style={{
            textAlign: 'center',
            marginTop: '50px',
            marginBottom: '10px',
            color: theme_font_color,
          }}
        >
          <b>Naat Audio Websites</b>
        </h3>
        {/* <hr className="type_1" /> */}

        <a href="https://audionaats.com/" target="_blank">
          <Row style={{ backgroundColor: 'darkgreen' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={process.env.PUBLIC_URL + '/naatwebsites/audio_naats.png'}
                alt="audionaats.com"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>audionaats.com</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'white',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                AudioNaats.com provides you with the latest and old MP3 Naats.
                You can listen and download these best quality Naats with a
                click. We try to make it one of the best MP3 Naats online
                platform. On this website, you will find all the famous Naat
                Khawan and their Classic Naats. You can also find the lyrics of
                almost all Naats. We will add the latest Naats on daily basis.
              </p>
            </Col>
          </Row>
        </a>
        <hr />
        <a href="https://hamariweb.com/islam/naat-sharif.aspx" target="_blank">
          <Row style={{ backgroundColor: 'midnightblue' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={process.env.PUBLIC_URL + '/naatwebsites/hamari_web.png'}
                alt="hamariweb.com"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>hamariweb.com</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'white',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                Hamariweb.com is becoming the most favorite web portal of all
                age groups including students, professionals, youth, teachers,
                parents as well as all the family members like it most. We try
                our best to provide all the essential tools and information of
                your daily needs and interest. Hamariweb.com is a project of
                Webiz Media Pvt. Ltd – a specialized company in area of Online
                Brand & Business Development. The company was established in
                2007, managing now regional portals of various categories of
                interest. Projects include, SHOPREX, KFOODS, PAKBIZ and some
                more as targets to niche market.
              </p>
            </Col>
          </Row>
        </a>
        <hr />
        <a href="https://www.naataudio.com/" target="_blank">
          <Row style={{ backgroundColor: 'teal' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={process.env.PUBLIC_URL + '/naatwebsites/naat_audio.jpg'}
                alt="naataudio.com"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>naataudio.com</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'white',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                Naat Audio website contains thousands of Hamd, Naats, Sufi
                Kalams, Duas, Lectures in MP# format. You can listen online,
                mark favourite, download mp3 and much more. Naat is an Arabic
                word which means praise and admiration. In other words Naat is
                an Islamic poetry that particularly said in praise and honor to
                Last Prophet Muhammad Mustufa P.B.U.H. As per users feedback we
                have created Videos section in Naat Audio website. At the moment
                Videos from YouTube can only be added. Users can create account
                and share their own or favourite videos from YouTube on this
                website.Naat is often called Naat-e-Mustufa, Naat Shareef and
                Naat-e-Pak etc. People who recite Naats are called Naat-Khawan.
                Naats are very popular in Islamic world.
              </p>
            </Col>
          </Row>
        </a>
        <hr />
        <a href="https://islamicdb.com/category/naat-audios" target="_blank">
          <Row style={{ backgroundColor: 'brown' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={process.env.PUBLIC_URL + '/naatwebsites/islamic_db.png'}
                alt="islamicdb.com"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>islamicdb.com</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'white',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                Islamicdb is an online portal that contains Audios, Videos,
                Books & PDF of Holy Quran, Noorani Qaida, Tilawat, Hamd, Naat,
                Bayan, Masnoon Duain, Hadith & Sunnah, Islamic Scholars/Artists
                List, and Islamic Articles etc. Users can read, download, listen
                and watch the available content for free. Please visit our
                website to listen naat shareef online or download mp3 naat with
                Naat lyrics. Our mission is to spread knowledge of Islam and
                Islamic content to all over the world, may ALLAH accept our this
                effort. I need your support please keep visiting this website
                and share with others. You can email us at usmangul.uu@gmail.com
                Or visit my portfolio : Usman Gul.
              </p>
            </Col>
          </Row>
        </a>
        <hr />
        <a href="http://owaisqadri.com/audiocategory/audio/" target="_blank">
          <Row style={{ backgroundColor: 'seagreen' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={process.env.PUBLIC_URL + '/naatwebsites/owais_qadri.png'}
                alt="owaisqadri.com"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>owaisqadri.com</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'white',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                AlHaaj Muhammad Owais Raza Qadri is the crownless king of
                present day Sana Khawans around the world and undoubtedly the
                most heartily loved, popular and legend of Sana Khawans, known
                for his melodious voice and unique style of expressing his great
                love and respect with the Holy Prophet, Hazrat Muhammad (S.A.W).
                He emerged with a revolutionary voice and unique style with a
                zeal to surpass his own targets and a performer par excellence.
                Today he is an ideal for millions of Ushaqan-e-Rasool across the
                globe. He is a legend at the age of 38, and the journey has just
                begun. There are millions of Owais Raza Qadri‘s fans worldwide
                who deeply admire and are inspired with him. They longed to know
                about the personal details which are still undiscovered and how
                he spent his daily life, so some information was collected about
                his daily routine. Further information will continue to be added
                day by day, Insha-Allah-o-Rabb-ul Aalameen.
              </p>
            </Col>
          </Row>
        </a>
        <hr />
        <a href="https://www.audionaat.com/mp3" target="_blank">
          <Row style={{ backgroundColor: 'black' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={process.env.PUBLIC_URL + '/naatwebsites/audio_naat.png'}
                alt="audionaat.com"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>audionaat.com</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'white',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                We are a dedicated group of web developers from Abbottabad,
                Pakistan. We always felt the need of a dedicated website for
                audio naats. Our dream came true when we developed
                AudioNaat.com. AudioNaat is a effort to present all the
                available Naat Sharifs in audio mp3 format on single platform.
                Remember us in your prayers.
              </p>
            </Col>
          </Row>
        </a>
        <hr />
        <a href="http://www.enaats.com/Naats/Mp3_Naats.aspx" target="_blank">
          <Row style={{ backgroundColor: 'darkslateblue' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={process.env.PUBLIC_URL + '/naatwebsites/enaats2.png'}
                alt="enaats.com"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>enaats.com</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'white',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                Expressing Love of Holy Prophet(PBUH) had always been a part of
                religion for Muslims. Poets from different cultures have showed
                deep respect for the Prophet(PBUH) in their respective
                languages. We, at eNaats, aim to make available all such content
                for our respected visitors. eNaats.com has been the top option
                for High Quality MP3 Naat listeners for last 6 years. Listen
                Naats of popular Naat-Khawans such as Owais Raza Qadri, Farhan
                Qadri and Qari Waheed Zafar Qasmi or others in High Quality.
                Download MP3 Naat tracks or see lyrics of Naats.
              </p>
            </Col>
          </Row>
        </a>
        <hr />
        <a
          href="https://alhajowaisrazaqadri.com/audio/audio-naats.htm"
          target="_blank"
        >
          <Row style={{ backgroundColor: 'darkslategray' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={
                  process.env.PUBLIC_URL +
                  '/naatwebsites/alhaj_owais_raza_qadri.jpg'
                }
                alt="alhajowaisrazaqadri.com"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>alhajowaisrazaqadri.com</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'white',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                Alhaj Muhammad Owais Raza Qadri is the crownless king of Sana
                Khawans around the world and undoubtedly the most heartily
                loved, popular and legend of Sana Khawans, known for his
                melodious voice and unique style of expressing his great love
                and respect with the Holy Prophet, Hazrat Muhammad Sallallahu
                Alayhi Wasallam. There are millions of Owais Raza Qadri's fans
                worldwide who deeply admire and inspired with him. They longed
                to know about the personal details which are still undiscovered
                and how he spent his daily life, so some information was
                collected about his daily routine. This is the first edition of
                his biography. Further information will continue to be added day
                by day, Insha-Allah-o-Rabb-ul Aalameen.
              </p>
            </Col>
          </Row>
        </a>
        <hr />
        <a href="https://www.islamwork.com/category/naat" target="_blank">
          <Row style={{ backgroundColor: 'bisque' }}>
            <Col
              xs={12}
              md={4}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <Image
                src={process.env.PUBLIC_URL + '/naatwebsites/islamic_work.png'}
                alt="islamwork.com"
                style={{ width: '300px', height: 'auto' }}
              />
            </Col>
            <Col xs={12} md={8}>
              <p
                style={{
                  color: 'black',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>islamwork.com</b>
              </p>
              <p
                className="naat_khawan_desc"
                style={{
                  color: 'black',
                  fontSize: 'small',
                  overFlow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'webkit-box',
                }}
              >
                Islam Work is an online portal that contains Audios, Videos,
                Books & PDF of Holy Quran, Noorani Qaida, Tilawat, Hamd, Naat,
                Bayan, Masnoon Duain, Hadith & Sunnah, Islamic Scholars/Artists
                List, and Islamic Articles etc. Users can read, download, listen
                and watch the available content for free. As the website is new,
                and do not have much content right now, but no worries we will
                upload the islamic content regulary.
              </p>
            </Col>
          </Row>
        </a>

        <hr />
        <p
          style={{
            textAlign: 'center',
            fontSize: '2.25rem',
            color: theme_font_color,
          }}
        >
          <b>Special thanks to:</b>
          <br />
        </p>
        <p
          style={{
            textAlign: 'center',
            fontSize: '1.25rem',
            color: theme_font_color,
          }}
        >
          <b>Saad Ali</b> from{' '}
          <a href="https://naatepaak.com/islamiclyrics/" target="_blank">
            https://naatepaak.com
          </a>
          <br />
          <b>Ahmed Ashfaq</b> from{' '}
          <a href="https://www.kalaam-e-raza.in" target="_blank">
            https://www.kalaam-e-raza.in/
          </a>
          <br />
          <b>Hasan Shaikh</b> from{' '}
          <a href="https://www.madanilyrics.com/" target="_blank">
            hamd-o-naatlyrics.blogspot.com
          </a>
          <br />
          <b>IslamicLyrics team</b> from{' '}
          <a
            href="http://www.islamiclyrics.net/artist/naat-lyrics/"
            target="_blank"
          >
            http://www.islamiclyrics.net
          </a>
          <br />
          <b>NaatSharif team</b> from{' '}
          <a href="http://www.naatsharif.in/" target="_blank">
            http://www.naatsharif.in/
          </a>
          <br />
          <br />
        </p>

        <p
          style={{
            textAlign: 'center',
            fontSize: '1rem',
            color: theme_font_color,
          }}
        >
          Thank you very much for allowing us to use your naats in our app and
          website
          <br />
          May Allah give you all an elite place in jannat-ul-firdous
        </p>
        <Row>
          <p className="app_background2">&nbsp;</p>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const naat_khawan_name_s = {
  //marginLeft: "5px",
  //marginTop: "0px",
  //marginBottom: "0px"
};

const naat_khawan_desc_s = {
  //marginLeft: "5px",
  //marginTop: "0px",
  fontSize: 'small',
  overFlow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'webkit-box',
};

export default OtherWebsites;
