import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
// import { useWindowScroll } from "react-use";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBaseballBall } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
// import SingleNaat from "./SingleNaat.js";
//import Home from "./Home.js";

const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey) || '',
  )

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value)
  }, [value])

  return [value, setValue]
}

function NaatRow(props) {
  // const history = useHistory();
  //start to work on the audio and video icons -------------------------
  const naat_audio = props.naat_audio
  const naat_video = props.naat_video
  const naat_url = props.naat_url
  // const [naat_link, setNaatLink] = useState("");
  // if (props.naat_type === "home") {
  // 	setNaatLink(props.naat_type + "/" + props.naat_name);
  // } else {
  // 	setNaatLink(props.naat_type + "s/" + props.naat_name)
  // }

  // function handleClick() {
  // 	//console.log(props.naat_name);

  // 	if (props.naat_type === "home") {
  // 		history.push("/" + props.naat_type + "/" + props.naat_name);
  // 		setNaatLink(naat_link + props.naat_type + "/" + props.naat_name);
  // 	} else {
  // 		history.push("/" + props.naat_type + "s/" + props.naat_name);
  // 		setNaatLink(naat_link + props.naat_type + "s/" + props.naat_name)
  // 	}

  // 	console.log(naat_link);
  // 	//return <SingleNaat naat_name={props.naat_name} naat_type={props.naat_type} />;

  // }

  //  {/*<Button variant="primary" size="lg" onClick={this.handleClick} block>
  //         {this.props.naat_name}
  //       </Button>*/}

  // const getHostName = (url) => {
  // 	// run against regex
  // 	const matches = url.match(/^http?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
  // 	// extract hostname (will be null if no match is found)
  // 	return matches && matches[1];
  // }

  const [theme_name, setThemeName] = useStateWithLocalStorage('theme_name')
  const [theme_background_color, setBackgroundColor] = useStateWithLocalStorage(
    'theme_background_color',
  )

  const [
    nav_background_color,
    setNavBackgroundColor,
  ] = useStateWithLocalStorage('nav_background_color')
  const [theme_font_color, setThemeFontColor] = useStateWithLocalStorage(
    'font_color',
  )
  const [theme_font_color1, setThemeFontColor1] = useStateWithLocalStorage(
    'font_color1',
  )
  const [theme_font_color2, setThemeFontColor2] = useStateWithLocalStorage(
    'font_color2',
  )

  const setThemeDefault = () => {
    setThemeName('default')
    setBackgroundColor('white')
    setNavBackgroundColor('#000051')
    setThemeFontColor('black')
    setThemeFontColor1('red')
    setThemeFontColor2('blue')
    window.location.reload()
  }
  const setThemeMadina = () => {
    setThemeName('madina')
    setBackgroundColor('#F0FFF0')
    setNavBackgroundColor('#003300')
    setThemeFontColor('black')
    setThemeFontColor1('red')
    setThemeFontColor2('blue')
    window.location.reload()
  }
  const setThemeMecca = () => {
    setThemeName('mecca')
    setBackgroundColor('#3F3F3F')
    setNavBackgroundColor('#212121')
    setThemeFontColor('white')
    setThemeFontColor1('#33FFE9')
    setThemeFontColor2('#FF9962')
    window.location.reload()
  }
  const setThemeAlaHazrat = () => {
    setThemeName('ala_hazrat')
    setBackgroundColor('#F0FFFF')
    setNavBackgroundColor('#00363a')
    setThemeFontColor('black')
    setThemeFontColor1('red')
    setThemeFontColor2('blue')
    window.location.reload()
  }

  if (theme_name === '') {
    setThemeName('default')
    setBackgroundColor('white')
    setNavBackgroundColor('#000051')
    setThemeFontColor('black')
    setThemeFontColor1('red')
    setThemeFontColor2('blue')
  }

  const getHostName = (url) => {
    let middle_url = ''
    let final_url = ''
    if (url[4] === 's') {
      middle_url = url.substring(8)
      if (middle_url.includes('/')) {
        final_url = middle_url.substring(0, middle_url.indexOf('/'))
      } else {
        final_url = middle_url.substring(0)
      }
    } else {
      middle_url = url.substring(7)
      if (middle_url.includes('/')) {
        final_url = middle_url.substring(0, middle_url.indexOf('/'))
      } else {
        final_url = middle_url.substring(0)
      }
    }

    if (final_url.length > 27) {
      //naatbookdownloadhome.files...
      return final_url.substring(0, 27) + '...'
    }
    return final_url
  }

  return (
    <Container style={{ cursor: 'pointer' }}>
      {props.naat_type === 'home' && (
        <Link
          to={props.naat_type + '/' + props.naat_name}
          style={{ color: 'black' }}
        >
          <Row>
            <Col xs={12} sm={12} style={naat_name_style}>
              <h6 style={{ marginBottom: '5px', color: theme_font_color }}>
                {props.naat_name}
              </h6>
            </Col>
          </Row>
          <Row>
            <Col xs={9} sm={9} style={website_style}>
              <p
                style={{
                  paddingRight: '0px',
                  marginBottom: '0px',
                  color: theme_font_color,
                }}
              >
                {getHostName(naat_url)}
              </p>
            </Col>
            <Col xs={2} sm={2} style={icons_style}>
              <Image
                className="float-right"
                src={process.env.PUBLIC_URL + naat_audio}
                style={audio_icon_style}
              />
              <Image
                className="float-right"
                src={process.env.PUBLIC_URL + naat_video}
                style={youtube_icon_style}
              />
            </Col>
          </Row>
        </Link>
      )}
      {props.naat_type !== 'home' && (
        <Link
          to={props.naat_type + 's/' + props.naat_name}
          style={{ color: 'black' }}
        >
          <Row>
            <Col xs={12} sm={12} style={naat_name_style}>
              <h6 style={{ marginBottom: '5px', color: theme_font_color }}>
                {props.naat_name}
              </h6>
            </Col>
          </Row>
          <Row>
            <Col xs={9} sm={9} style={website_style}>
              <p
                style={{
                  paddingRight: '0px',
                  marginBottom: '0px',
                  color: theme_font_color,
                }}
              >
                {getHostName(naat_url)}
              </p>
            </Col>
            <Col xs={2} sm={2} style={icons_style}>
              <Image
                className="float-right"
                src={process.env.PUBLIC_URL + naat_audio}
                style={audio_icon_style}
              />
              <Image
                className="float-right"
                src={process.env.PUBLIC_URL + naat_video}
                style={youtube_icon_style}
              />
            </Col>
          </Row>
        </Link>
      )}
    </Container>
  )
}

// const button_style = {
// 	textAlign: 'left'
// };

const icons_style = {
  paddingRight: '0px',
  paddingLeft: '0px',
  marginRight: '10px',
}

const youtube_icon_style = {
  width: '20px',
  height: '20px',
  align: 'right',
  marginRight: '7px',
}

const audio_icon_style = {
  width: '20px',
  height: '20px',
  align: 'right',
  //marginRight: '10px'
}

const naat_name_style = {
  //marginLeft: '15px',
  paddingRight: '0px',
  paddingLeft: '0px',
}

const naat_name_text_style = {
  marginBottom: '5px',
  //marginRight: '5px'
}

const website_style = {
  paddingRight: '0px',
  //marginLeft: '15px',
  paddingLeft: '0px',
  marginBottom: '0px',
}

const paragraph_style = {
  paddingRight: '0px',
  marginBottom: '0px',
}

export default NaatRow
