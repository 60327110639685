import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Container } from 'react-bootstrap';
import './App.css';
import './react-player.css';
// import { useWindowScroll } from "react-use";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBaseballBall } from '@fortawesome/free-solid-svg-icons'
// import { Link } from 'react-router-dom'
// import SingleNaat from "./SingleNaat.js";
//import Home from "./Home.js";

function YoutubeListItem(props) {
  // const history = useHistory();
  var naat_video_name = props.name;
  var naat_video_desc = props.desc;
  var naat_video_image = props.img_url;
  // var naat_video_image_width = props.img_width
  // var naat_video_image_height = props.img_height
  //   var naat_kahwan_image_address =
  //     '/' + category + '/' + naat_khawan_image + '.png'

  //console.log(naat_kahwan_image_address);

  // function handleClick() {
  //     console.log(props.name);
  //     if (props.category === "naat_khawan") {
  // 		history.push("/naatkhawans" + "/" + naat_khawan_name);
  // 	} else {
  // 		history.push("/categories" + "/" + naat_khawan_name);
  // 	}
  // }

  //  {/*<Button variant="primary" size="lg" onClick={this.handleClick} block>
  //         {this.props.naat_name}
  //       </Button>*/}

  return (
    <Container style={{ cursor: 'pointer' }}>
      <Row>
        <Col
          // xxs={8}
          // sm={7}
          // md={4}
          xs={4}
          sm={4}
          style={{
            paddingLeft: '0px',
            paddingRight: '0px',
            alignSelf: 'center',
            // width: '160px',
            // height: '90px',
          }}
        >
          <Image
            src={naat_video_image}
            alt={naat_video_name}
            style={{
              width: '100%',
              // height: 'auto',
            }}
          />
        </Col>
        <Col
          // xxs={4}
          // sm={5}
          // md={8}
          xs={8}
          sm={8}
          style={{
            paddingLeft: '0px',
            marginTop: '0px',
            paddingRight: '0px',
          }}
        >
          <p className="youtube_video_title" style={naat_khawan_name_s}>
            <b>{naat_video_name}</b>
          </p>
          <p className="youtube_video_desc" style={naat_khawan_desc_s}>
            {naat_video_desc}
          </p>
        </Col>
      </Row>
    </Container>
    // <div>
    //   <h1>YOUTUBE VIDEO</h1>
    // </div>
  );
}

// const button_style = {
//     textAlign: 'left'
// };

const naat_khawan_name_s = {
  marginLeft: '10px',
  marginTop: '3px',
  marginRight: '2px',
  marginBottom: '0px',
  fontSize: 'small',
};

const naat_khawan_desc_s = {
  marginLeft: '10px',
  marginTop: '0px',
  marginRight: '2px',
  marginBottom: '0px',
  fontSize: 'small',
};

export default YoutubeListItem;
