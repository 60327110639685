import React, { useState, useEffect } from 'react';
import './App.css';
import full_json from './nath-data/naat-json.json';
import { isMobile } from 'react-device-detect';
import {
  Navbar,
  Nav,
  Button,
  Container,
  Row,
  Col,
  Image as Bootstrapimage,
  Collapse,
  Form,
  DropdownButton,
  Dropdown,
  Modal,
  NavDropdown,
} from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
// import ScrollToTop from './ScrollToTop';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import 'bootstrap/dist/css/bootstrap.css';
import './other.css';
import YoutubeListItem from './YoutubeListItem';
// import './fonts/algreya_bold.otf'
// import './fonts/algreya_regular.otf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMusic,
  faStop,
  faTags,
  faTasks,
  faStepForward,
  faStepBackward,
  faChevronDown,
  faChevronUp,
  faThList,
  faListOl,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { useHistory } from 'react-router-dom';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import ReactPlayer from 'react-player';
import './react-player.css';
import ReactAudioPlayer from 'react-audio-player';
import { Helmet } from 'react-helmet';

const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey) || ''
  );

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);

  return [value, setValue];
};

function SingleNaat({ match }) {
  const [font_name, setFontName] = useStateWithLocalStorage('font_name');
  const [font_size, setFontSize] = useStateWithLocalStorage('font_size');

  const setFont1 = () => setFontName('algreya_regular');
  // const [setFont1, setFontName] = useStage(font_name ? '' : 'algreya_regular') setFontName('')
  const setFont2 = () => setFontName('algreya_bold');
  const setFont3 = () => setFontName('bitter_regular');
  const setFont4 = () => setFontName('bree_serif');
  const setFont5 = () => setFontName('itim');
  const setFont6 = () => setFontName('patrick_hand');
  const setFont7 = () => setFontName('roboto_regular');
  const setFont8 = () => setFontName('ubuntu_regular');
  // const setFont4 = () => setFontName('')
  const defaultFontSize = () => setFontSize('15');

  if (font_name === '') {
    setFontName('algreya_regular');
  }

  if (font_size === '') {
    setFontSize('15');
  }

  //------------- Theme Start -------------
  const [theme_name, setThemeName] = useStateWithLocalStorage('theme_name');
  const [theme_background_color, setBackgroundColor] = useStateWithLocalStorage(
    'theme_background_color'
  );

  const [nav_background_color, setNavBackgroundColor] =
    useStateWithLocalStorage('nav_background_color');
  const [theme_font_color, setThemeFontColor] =
    useStateWithLocalStorage('font_color');
  const [theme_font_color1, setThemeFontColor1] =
    useStateWithLocalStorage('font_color1');
  const [theme_font_color2, setThemeFontColor2] =
    useStateWithLocalStorage('font_color2');

  const setThemeDefault = () => {
    setThemeName('default');
    setBackgroundColor('white');
    setNavBackgroundColor('#000051');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };
  const setThemeMadina = () => {
    setThemeName('madina');
    setBackgroundColor('#F0FFF0');
    setNavBackgroundColor('#003300');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };
  const setThemeMecca = () => {
    setThemeName('mecca');
    setBackgroundColor('#3F3F3F');
    setNavBackgroundColor('#212121');
    setThemeFontColor('white');
    setThemeFontColor1('#33FFE9');
    setThemeFontColor2('#FF9962');
    window.location.reload();
  };
  const setThemeAlaHazrat = () => {
    setThemeName('ala_hazrat');
    setBackgroundColor('#F0FFFF');
    setNavBackgroundColor('#00363a');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };

  if (theme_name === '') {
    setThemeName('default');
    setBackgroundColor('white');
    setNavBackgroundColor('#000051');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
  }

  //------------- Theme End -------------

  //const [theme, setTheme] = useState("light");
  //const [font_size, setFontSize] = useState(15)
  const [footer_visible, setFooterVisiblility] = useState(true);
  const [audio_player_visible, setAudioPlayerVisiblility] = useState(false);
  //const [footer_button_text, setFooterText] = useState("Hide");
  const [vidoe_player_visible, setVideoPlayerVisibility] = useState(false);
  const [current_audio_to_play, setCurrentAudio] = useState(
    'https://www.islamicdb.com/downloads/Huzoor%20Jante%20Hain%20MP3%20Naat%20By%20Owais%20Raza%20Qadri.MP3'
  );
  //const [current]
  const [getFooterIcon, setFooterIcon] = useState(faChevronDown);
  var tagsDiv = [];
  const history = useHistory();
  const [get_tags, setTags] = useState(<p>Hello World!</p>);
  //const video_player_ref = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const naat_name = match.params.naat_name;
  var naat_type = '';
  const sub_type = match.url;
  if (sub_type.toLowerCase().includes('naats')) {
    naat_type = 'naat';
  } else if (sub_type.toLowerCase().includes('salaams')) {
    naat_type = 'salaam';
  } else if (sub_type.toLowerCase().includes('hamds')) {
    naat_type = 'hamd';
  } else if (sub_type.toLowerCase().includes('manqabats')) {
    naat_type = 'manqabat';
  } else {
    naat_type = 'home';
  }

  const [show_writer_name, setShowWriterName] = useState(false);
  const handleWriterClose = () => setShowWriterName(false);
  const handleWriterShow = () => setShowWriterName(true);

  const [show_mistake_name, setShowMistake] = useState(false);
  const handleMistakeClose = () => setShowMistake(false);
  const handleMistakeShow = () => setShowMistake(true);

  const [show_font_name, setShowFont] = useState(false);
  const handleFontClose = () => setShowFont(false);
  const handleFontShow = () => setShowFont(true);

  const [show_tags, setShowTag] = useState(false);
  const handleTagClose = () => setShowTag(false);
  function handleTagShow() {
    setShowTag(true);
    setTags(getTags());
  }

  // console.log(
  //   'Can we play this video: ' +
  //     ReactPlayer.canPlay('https://www.youtube.com/watch?v=ytdCW6cKV04'),
  // )
  //console.log(naat_body_component);

  var naat_body = '';
  var naat_author = '';
  var special_format = false;
  var naat_lines_format = [];
  var naat_url = '';
  var naat_audio = [];
  var naat_video_link = [];
  var naat_writer = '';
  //var getFooterIcon;

  //function find_the_naat() {
  //console.log("I got called!!!!");
  if (naat_type === 'naat') {
    for (let naat of full_json.naats) {
      if (naat.naat_name.toLowerCase() === naat_name.toLowerCase()) {
        naat_body = naat.naat_body;
        naat_author = naat.naat_author.toLowerCase();
        special_format = naat.special_format;
        naat_lines_format = naat.naat_lines_format;
        naat_url = naat.naat_url;
        naat_audio = naat.naat_audio;
        naat_video_link = naat.naat_video_link;

        break;
      }
    }
  } else if (naat_type === 'salaam') {
    for (let naat of full_json.salaams) {
      if (naat.naat_name.toLowerCase() === naat_name.toLowerCase()) {
        naat_body = naat.naat_body;
        naat_author = naat.naat_author.toLowerCase();
        special_format = naat.special_format;
        naat_lines_format = naat.naat_lines_format;
        naat_url = naat.naat_url;
        naat_audio = naat.naat_audio;
        naat_video_link = naat.naat_video_link;

        break;
      }
    }
  } else if (naat_type === 'hamd') {
    for (let naat of full_json.hamds) {
      if (naat.naat_name.toLowerCase() === naat_name.toLowerCase()) {
        naat_body = naat.naat_body;
        naat_author = naat.naat_author.toLowerCase();
        special_format = naat.special_format;
        naat_lines_format = naat.naat_lines_format;
        naat_url = naat.naat_url;
        naat_audio = naat.naat_audio;
        naat_video_link = naat.naat_video_link;

        break;
      }
    }
  } else if (naat_type === 'manqabat') {
    for (let naat of full_json.manqabats) {
      if (naat.naat_name.toLowerCase() === naat_name.toLowerCase()) {
        naat_body = naat.naat_body;
        naat_author = naat.naat_author.toLowerCase();
        special_format = naat.special_format;
        naat_lines_format = naat.naat_lines_format;
        naat_url = naat.naat_url;
        naat_audio = naat.naat_audio;
        naat_video_link = naat.naat_video_link;

        break;
      }
    }
  } else {
    //Search in all the arrays
    for (let naat of full_json.naats) {
      if (naat.naat_name.toLowerCase() === naat_name.toLowerCase()) {
        naat_body = naat.naat_body;
        naat_author = naat.naat_author.toLowerCase();
        special_format = naat.special_format;
        naat_lines_format = naat.naat_lines_format;
        naat_url = naat.naat_url;
        naat_audio = naat.naat_audio;
        naat_video_link = naat.naat_video_link;

        break;
      }
    }

    for (let naat of full_json.salaams) {
      if (naat.naat_name.toLowerCase() === naat_name.toLowerCase()) {
        naat_body = naat.naat_body;
        naat_author = naat.naat_author.toLowerCase();
        special_format = naat.special_format;
        naat_lines_format = naat.naat_lines_format;
        naat_url = naat.naat_url;
        naat_audio = naat.naat_audio;
        naat_video_link = naat.naat_video_link;

        break;
      }
    }

    for (let naat of full_json.hamds) {
      if (naat.naat_name.toLowerCase() === naat_name.toLowerCase()) {
        naat_body = naat.naat_body;
        naat_author = naat.naat_author.toLowerCase();
        special_format = naat.special_format;
        naat_lines_format = naat.naat_lines_format;
        naat_url = naat.naat_url;
        naat_audio = naat.naat_audio;
        naat_video_link = naat.naat_video_link;

        break;
      }
    }

    for (let naat of full_json.manqabats) {
      if (naat.naat_name.toLowerCase() === naat_name.toLowerCase()) {
        naat_body = naat.naat_body;
        naat_author = naat.naat_author.toLowerCase();
        special_format = naat.special_format;
        naat_lines_format = naat.naat_lines_format;
        naat_url = naat.naat_url;
        naat_audio = naat.naat_audio;
        naat_video_link = naat.naat_video_link;

        break;
      }
    }
  }
  //debugger
  //}

  //find_the_naat();
  //set_naat_body();
  // var naat_body_string = naat_body.toString();
  // console.log(naat_body_string);
  // naat_body_string = naat_body_string.replace(/,/g, ' ');
  // console.log(naat_body_string);
  // naat_body_string = naat_body_string.replace(/[^\w\s]/gi, '');
  // console.log(naat_body_string);

  if (naat_author.includes(',')) {
    naat_writer = capital_letter(
      naat_author.substr(0, naat_author.indexOf(','))
    );
  } else {
    if (naat_writer.includes('unknown')) {
      naat_writer = 'Writer is Unknown';
    } else {
      naat_writer = capital_letter(naat_author);
    }
  }

  //video_list.length = 0
  //console.log(naat_video_link);

  //------------- YouTube Videos -------------
  const maxResults = '50';
  const videoOrder = 'viewCount';
  const videoType = 'video';
  const videoDefinition = 'any';
  const videoDuration = 'any';
  const get_video_info_url = `https://www.googleapis.com/youtube/v3/videos?key=${process.env.REACT_APP_YOUTUBE_API_KEY}&part=snippet&id=`;
  const get_more_videos_url = `https://www.googleapis.com/youtube/v3/search?part=snippet&order=viewCount&type=video&videoDefinition=any&videoDuration=any&maxResults=50&key=${process.env.REACT_APP_YOUTUBE_API_KEY}&q=`;
  const [youtube_video_list, setYoutubeVideoList] = useState([]);
  const [show_youtube_list, setShowYoutubeList] = useState(false);
  const [more_videos_first_time, setMoreVidosFirstTime] = useState(true);
  const [next_page_token, setNextPageToken] = useState('');
  const [show_more_youtube_videos_button, SetShowMoreYoutubeVideosButton] =
    useState(true);
  const handleYoutubeListClose = () => setShowYoutubeList(false);
  const handleYoutubeListShow = () => {
    setShowYoutubeList(true);
  };

  function youtubeListVideoClicked(youtube_video_id, youtube_video_title) {
    //console.log('Video Clicked ID: ' + youtube_video_id);
    //console.log('Video Clicked TITLE: ' + youtube_video_title);
    // youtube_video_list.find()
    //console.log(youtube_video_list.length);
    setFinalVideoList('https://www.youtube.com/watch?v=' + youtube_video_id);
    setShowYoutubeList(false);
  }

  function youtube_video_list_clicked() {
    if (youtube_video_list.length === 0) {
      for (let one_video of naat_video_link) {
        let video_title = '';
        let video_desc = '';
        let video_img_url = '';
        let video_item = {};
        //const video_id = one_video.split('=')[1];
        const video_id = one_video;
        fetch(get_video_info_url + video_id)
          .then((response) => response.json())
          .then((resp_json) => {
            //console.log(resp_json);
            //Only process if we get the correct json response
            if (resp_json.items.length > 0) {
              video_title = resp_json.items[0].snippet.title;
              // video_desc = resp_json.items[0].snippet.description;
              video_desc = resp_json.items[0].snippet.channelTitle;
              video_img_url = resp_json.items[0].snippet.thumbnails.medium.url;
              video_item = (
                <div
                  key={video_id + video_title}
                  onClick={() => youtubeListVideoClicked(video_id, video_title)}
                  style={{
                    border: '1px solid',
                    marginTop: '5px',
                    borderColor: nav_background_color,
                  }}
                >
                  <YoutubeListItem
                    name={video_title}
                    desc={video_desc}
                    img_url={video_img_url}
                    // onClick={youtubeListVideoClicked(video_id, video_title)}
                    // onClick={() => alert('Clicked Video ID: ') + { video_id }}
                  />
                </div>
              );
              //Add the video item to the list
              //setYoutubeVideoList(...youtube_video_list, video_item)
              setYoutubeVideoList((youtube_video_list) => [
                ...youtube_video_list,
                video_item,
              ]);
            }

            // else {
            //   video_item = <h4>No More Items To Load</h4>
            //   setYoutubeVideoList(...youtube_video_list, video_item)
            //   SetShowMoreYoutubeVideosButton(false)
            // }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  function youtube_more_videos_list_clicked() {
    //console.log('Clicked on the button');

    if (more_videos_first_time) {
      setMoreVidosFirstTime(false);

      const hr_separator = (
        <div
          key={'Seperator'}
          style={{ backgroundColor: nav_background_color }}
        >
          <hr />
          <p
            style={{
              textAlign: 'center',
              color: 'white',
            }}
          >
            --- Getting More Videos From YouTube ---
          </p>
          <hr />
        </div>
      );
      setYoutubeVideoList((youtube_video_list) => [
        ...youtube_video_list,
        hr_separator,
      ]);

      fetch(get_more_videos_url + naat_name)
        .then((response) => response.json())
        .then((resp_json) => {
          //console.log(resp_json);
          if (resp_json.hasOwnProperty('nextPageToken')) {
            setNextPageToken(resp_json.nextPageToken);
          } else {
            SetShowMoreYoutubeVideosButton(false);
          }
          if (resp_json.hasOwnProperty('items')) {
            const temp_list = resp_json.items.map((json_obj, i) => {
              //Returns a YoutubeListItem
              //console.log(json_obj);
              let video_title = json_obj.snippet.title;
              let video_desc = json_obj.snippet.channelTitle;
              let video_img_url = json_obj.snippet.thumbnails.medium.url;
              let video_id = json_obj.id.videoId;
              return (
                // <YoutubeListItem
                //   key={json_obj.id.videoId + i}
                //   name={json_obj.snippet.title}
                //   desc={json_obj.snippet.channelTitle}
                //   img_url={json_obj.snippet.thumbnails.default.url}
                // />
                <div
                  key={json_obj.id.videoId + i}
                  onClick={() => youtubeListVideoClicked(video_id, video_title)}
                  style={{
                    border: '1px solid',
                    marginTop: '5px',
                    borderColor: nav_background_color,
                  }}
                >
                  <YoutubeListItem
                    name={video_title}
                    desc={video_desc}
                    img_url={video_img_url}
                  />
                </div>
              );
            });
            //console.log(temp_list);
            // setYoutubeVideoList(temp_list);
            setYoutubeVideoList((youtube_video_list) => [
              ...youtube_video_list,
              temp_list,
            ]);
            // console.log(
            //   'Button boolean: ' + show_more_youtube_videos_button.toString()
            // );
          }
          // if (show_more_youtube_videos_button === false) {
          //   console.log('Came in here!!!!');
          //   let end_item = (
          //     <h5 key="end_of_the_list" style={{ textAlign: 'center' }}>
          //       No More Videos To Load
          //     </h5>
          //   );
          //   setYoutubeVideoList((youtube_video_list) => [
          //     ...youtube_video_list,
          //     end_item,
          //   ]);
          // }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      fetch(get_more_videos_url + naat_name + '&pageToken=' + next_page_token)
        .then((response) => response.json())
        .then((resp_json) => {
          //console.log(resp_json);
          if (resp_json.hasOwnProperty('nextPageToken')) {
            setNextPageToken(resp_json.nextPageToken);
          } else {
            SetShowMoreYoutubeVideosButton(false);
          }
          if (resp_json.hasOwnProperty('items')) {
            const temp_list = resp_json.items.map((json_obj, i) => {
              //Returns a YoutubeListItem
              //console.log(json_obj);
              let video_title = json_obj.snippet.title;
              let video_desc = json_obj.snippet.channelTitle;
              let video_img_url = json_obj.snippet.thumbnails.medium.url;
              let video_id = json_obj.id.videoId;
              return (
                <div
                  key={json_obj.id.videoId + i}
                  onClick={() => youtubeListVideoClicked(video_id, video_title)}
                  style={{
                    border: '1px solid',
                    marginTop: '5px',
                    borderColor: nav_background_color,
                  }}
                >
                  <YoutubeListItem
                    name={video_title}
                    desc={video_desc}
                    img_url={video_img_url}
                  />
                </div>
              );
            });
            //console.log(temp_list);
            // setYoutubeVideoList(temp_list);
            setYoutubeVideoList((youtube_video_list) => [
              ...youtube_video_list,
              temp_list,
            ]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  // ----------- To Clean the video list ----------- //
  const prepare_video_list = () => {
    const video_list = [];
    for (let i = 0; i < naat_video_link.length; i++) {
      var img = new Image();
      img.src =
        'https://img.youtube.com/vi/' + naat_video_link[i] + '/mqdefault.jpg';
      img.onload = function () {
        checkThumbnail(this.width, naat_video_link[i]);
      };
    }

    function checkThumbnail(width, id) {
      //HACK a mq thumbnail has width of 320.
      //if the video does not exist(therefore thumbnail don't exist), a default thumbnail of 120 width is returned.
      if (width === 120) {
        //console.log('Not able to play: ' + id);
      } else {
        if (!video_list.includes('https://www.youtube.com/watch?v=' + id)) {
          video_list.push('https://www.youtube.com/watch?v=' + id);
          //console.log('Added to list: ' + id);
        }
      }
    }
    return video_list;
  };

  const [final_video_list, setFinalVideoList] = useState(prepare_video_list);
  // ----------- To Clean the video list ----------- //

  function handleFooterClick() {
    //console.log("Handle Footer Click!!!");

    if (footer_visible) {
      //setFooterText("Show");
      setFooterIcon(faChevronUp);
    } else {
      //setFooterText("Hide");
      setFooterIcon(faChevronDown);
    }

    setFooterVisiblility(!footer_visible);
  }

  function capital_letter(str) {
    str = str.toLowerCase();
    if (str.includes(' ')) {
      str = str.split(' ');
      for (let i = 0, x = str.length; i < x; i++) {
        if (str[i].charAt(0).match(/^[A-Za-z]+$/)) {
          str[i] = str[i].charAt(0).toUpperCase() + str[i].substr(1);
        } else {
          str[i] =
            str[i].charAt(0) +
            str[i].charAt(1).toUpperCase() +
            str[i].substr(2);
        }
      }
      return str.join(' ');
    } else {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }

  //const [get_naat_body, setNaatBosy] = useState(<p>Hello World!</p>);

  function set_naat_body() {
    //console.log("Inside the function");

    let red_lines = 0;
    let blue_lines = 0;
    let red_col = true;

    if (naat_lines_format.length > 2) {
      special_format = true;
    } else {
      special_format = false;
    }

    let naat_body_component = [];
    let red = 0;
    let blue = 0;

    if (!special_format) {
      red_lines = naat_lines_format[0];
      blue_lines = naat_lines_format[1];

      for (let i = 0; i < naat_body.length; i++) {
        if (red_col) {
          let naat_line = naat_body[i];
          naat_line = capital_letter(naat_line);
          if (!naat_line.includes('`')) {
            naat_body_component.push(
              <p
                key={i}
                style={{
                  color: theme_font_color1,
                  marginLeft: '10px',
                  marginBottom: '0px',
                  fontSize: font_size + 'px',
                  fontFamily: font_name,
                }}
              >
                {naat_line}
              </p>
            );
          } else {
            //what if the line contains `writer name`
            let first_part = naat_line.substring(0, naat_line.indexOf('`'));
            let second_part = naat_line.substring(naat_line.indexOf('`') + 1);
            let last_index = second_part.indexOf('`');
            let third_part = '';
            if (last_index + 1 >= second_part.length) {
              third_part = '';
            } else {
              third_part = second_part.substring(last_index + 1);
            }
            second_part = second_part.substring(0, second_part.indexOf('`'));
            naat_body_component.push(
              <p
                key={i}
                style={{
                  color: theme_font_color1,
                  marginLeft: '10px',
                  marginBottom: '0px',
                  fontSize: font_size + 'px',
                  fontFamily: font_name,
                }}
              >
                {first_part}
                <mark style={{ backgroundColor: 'yellow' }}>{second_part}</mark>
                {third_part}
              </p>
            );
            //console.log(first_part);
            //console.log(second_part);
            //console.log(third_part);
          }
          red++;
          if (red >= red_lines) {
            naat_body_component.push(<br key={i + 1000} />);
            red_col = false;
            red = 0;
          }
        } else {
          let naat_line = naat_body[i];
          naat_line = capital_letter(naat_line);
          if (!naat_line.includes('`')) {
            naat_body_component.push(
              <p
                key={i}
                style={{
                  color: theme_font_color2,
                  marginLeft: '10px',
                  marginBottom: '0px',
                  fontSize: font_size + 'px',
                  fontFamily: font_name,
                }}
              >
                {naat_line}
              </p>
            );
          } else {
            let first_part = naat_line.substring(0, naat_line.indexOf('`'));
            let second_part = naat_line.substring(naat_line.indexOf('`') + 1);
            let last_index = second_part.indexOf('`');
            let third_part = '';
            if (last_index + 1 >= second_part.length) {
              third_part = '';
            } else {
              third_part = second_part.substring(last_index + 1);
            }
            second_part = second_part.substring(0, second_part.indexOf('`'));
            naat_body_component.push(
              <p
                key={i}
                style={{
                  color: theme_font_color2,
                  marginLeft: '10px',
                  marginBottom: '0px',
                  fontSize: font_size + 'px',
                  fontFamily: font_name,
                }}
              >
                {first_part}
                <mark style={{ backgroundColor: 'yellow' }}>{second_part}</mark>
                {third_part}
              </p>
            );
            //console.log(first_part);
            //console.log(second_part);
            //console.log(third_part);
          }
          blue++;
          if (blue >= blue_lines) {
            naat_body_component.push(<br key={i + 1000} />);
            red_col = true;
            blue = 0;
          }
        }
      }
      //debugger
      //console.log(naat_body_component);
      return <React.Fragment>{naat_body_component}</React.Fragment>;
    } else {
      let counter = 0;
      for (let i = 0; i < naat_body.length; i++) {
        red_lines = naat_lines_format[counter];

        if (red_col) {
          let naat_line = naat_body[i];
          naat_line = capital_letter(naat_line);
          if (!naat_line.includes('`')) {
            naat_body_component.push(
              <p
                key={i}
                style={{
                  color: theme_font_color1,
                  marginLeft: '10px',
                  marginBottom: '0px',
                  fontSize: font_size + 'px',
                  fontFamily: font_name,
                }}
              >
                {naat_line}
              </p>
            );
          } else {
            //what if the line contains `writer name`
            let first_part = naat_line.substring(0, naat_line.indexOf('`'));
            let second_part = naat_line.substring(naat_line.indexOf('`') + 1);
            let last_index = second_part.indexOf('`');
            let third_part = '';
            if (last_index + 1 >= second_part.length) {
              third_part = '';
            } else {
              third_part = second_part.substring(last_index + 1);
            }
            second_part = second_part.substring(0, second_part.indexOf('`'));
            naat_body_component.push(
              <p
                key={i}
                style={{
                  color: theme_font_color1,
                  marginLeft: '10px',
                  marginBottom: '0px',
                  fontSize: font_size + 'px',
                  fontFamily: font_name,
                }}
              >
                {first_part}
                <mark style={{ backgroundColor: 'yellow' }}>{second_part}</mark>
                {third_part}
              </p>
            );
            //console.log(first_part);
            //console.log(second_part);
            //console.log(third_part);
          }
          red++;
          if (red >= red_lines) {
            naat_body_component.push(<br key={i + 1000} />);
            red_col = false;
            red = 0;
            counter++;
          }
        } else {
          let naat_line = naat_body[i];
          naat_line = capital_letter(naat_line);
          if (!naat_line.includes('`')) {
            naat_body_component.push(
              <p
                key={i}
                style={{
                  color: theme_font_color2,
                  marginLeft: '10px',
                  marginBottom: '0px',
                  fontSize: font_size + 'px',
                  fontFamily: font_name,
                }}
              >
                {naat_line}
              </p>
            );
          } else {
            //what if the line contains `writer name`
            let first_part = naat_line.substring(0, naat_line.indexOf('`'));
            let second_part = naat_line.substring(naat_line.indexOf('`') + 1);
            let last_index = second_part.indexOf('`');
            let third_part = '';
            if (last_index + 1 >= second_part.length) {
              third_part = '';
            } else {
              third_part = second_part.substring(last_index + 1);
            }
            second_part = second_part.substring(0, second_part.indexOf('`'));
            naat_body_component.push(
              <p
                key={i}
                style={{
                  color: theme_font_color2,
                  marginLeft: '10px',
                  marginBottom: '0px',
                  fontSize: font_size + 'px',
                  fontFamily: font_name,
                }}
              >
                {first_part}
                <mark style={{ backgroundColor: 'yellow' }}>{second_part}</mark>
                {third_part}
              </p>
            );
            //console.log(first_part);
            //console.log(second_part);
            //console.log(third_part);
          }
          red++;
          if (red >= red_lines) {
            naat_body_component.push(<br key={i + 1000} />);
            red_col = true;
            red = 0;
            counter++;
          }
        }
      }
      //console.log("=============");
      //console.log(naat_body_component);
      return <React.Fragment>{naat_body_component}</React.Fragment>;
    }
  }

  function handleVideoClick() {
    //console.log('Video button clicked');
    //console.log('Mobile: ' + isMobile);
    setVideoPlayerVisibility(true);
    setAudioPlayerVisiblility(false);
  }

  function audioPlay(link_number) {
    setAudioPlayerVisiblility(true);
    setVideoPlayerVisibility(false);
    setCurrentAudio(naat_audio[link_number]);
    //console.log("Play audio link: " + link_number);
  }

  function handleAudioNext() {
    //handle the audio after the list
    let audio_index = naat_audio.indexOf(current_audio_to_play);
    if (audio_index !== -1) {
      if (audio_index >= naat_audio.length - 1) {
        setCurrentAudio(naat_audio[0]);
      } else {
        setCurrentAudio(naat_audio[audio_index + 1]);
      }
    } else {
      setCurrentAudio(naat_audio[0]);
    }
  }

  function handleAudioPrev() {
    //handle the audio after the list
    let audio_index = naat_audio.indexOf(current_audio_to_play);
    if (audio_index !== -1) {
      if (audio_index <= 0) {
        setCurrentAudio(naat_audio[naat_audio.length - 1]);
      } else {
        setCurrentAudio(naat_audio[audio_index - 1]);
      }
    } else {
      setCurrentAudio(naat_audio[0]);
    }
  }

  //const [audio_links, setFooterIcon] = useState(getAudioLinks());

  function getAudioLinks() {
    let naat_audio_menu = [];
    if (naat_audio.length > 0) {
      for (let i = 0; i < naat_audio.length; i++) {
        naat_audio_menu.push(
          <Dropdown.Item key={i} onClick={() => audioPlay(i)}>{`Audio Link ${
            i + 1
          }`}</Dropdown.Item>
        );
      }
    } else {
      naat_audio_menu.push(
        <Dropdown.Item key={110011}>No Audio Links found</Dropdown.Item>
      );
    }
    //console.log("audio_method_got_called");
    return <React.Fragment>{naat_audio_menu}</React.Fragment>;
  }

  // function getVideoLinks() {
  //     let naat_video_menu = []
  //     for (let i = 0; i < naat_video_link.length; i++) {
  //         naat_video_menu.push(
  //             <Dropdown.Item key={i} onClick={() => handleVideoClick()}>{`Youtube Link + ${naat_video_link[i]}`}</Dropdown.Item>
  //         )
  //     }
  //     //setVideoPlayerVisiblity(true);
  //     return (<React.Fragment>{naat_video_menu}</React.Fragment>);
  // }

  return (
    <React.Fragment>
      <Collapse in={vidoe_player_visible}>
        <div className="fixed-top">
          <Row
            className="justify-content-center"
            style={{ backgroundColor: 'black' }}
          >
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{ paddingLeft: '0px', paddingRight: '0px' }}
            >
              <div className="player-wrapper">
                {vidoe_player_visible && (
                  <ReactPlayer
                    className="react-player"
                    url={final_video_list}
                    //url="https://www.youtube.com/watch?v=ytdCW6cKV04"
                    controls={true}
                    playing={isMobile ? false : true}
                    //pip={true}
                    width="100%"
                    height="100%"
                    // onReady={() => console.log('onReady callback')}
                    // onStart={() => console.log('onStart callback')}
                    // onPlay={() => console.log('onPlay callback')}
                    // onPause={() => console.log('onPause callback')}
                    // onEnded={() => console.log('onEnded callback')}
                    // onError={() => console.log('onError callback')}
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Collapse>

      <Navbar
        expand="lg"
        variant="dark"
        style={{ backgroundColor: nav_background_color }}
      >
        <Navbar.Brand
          href="/"
          style={{
            fontFamily: 'font_c',
            color: 'white',
            fontSize: '25px',
          }}
        >
          <img
            src={process.env.PUBLIC_URL + '/madina.png'}
            width="30"
            height="30"
            className="d-inline-block align-top"
            style={{ marginRight: '7px' }}
            alt="My Naat Book"
          />
          My Naat Book
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {/* <Nav.Link href="/">Home</Nav.Link> */}
            <Nav.Link href="/naats">Naat</Nav.Link>
            <Nav.Link href="/salaams">Salaam</Nav.Link>
            <Nav.Link href="/hamds">Hamd</Nav.Link>
            <Nav.Link href="/manqabats">Manqabat</Nav.Link>
            <Nav.Link href="/naatkhawans">Naat Khawans</Nav.Link>
            <Nav.Link href="/categories">Catergories</Nav.Link>
            <NavDropdown title="Theme" id="collasible-nav-dropdown">
              <NavDropdown.Item onClick={setThemeDefault}>
                Default
              </NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeMadina}>
                Madina
              </NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeMecca}>Mecca</NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeAlaHazrat}>
                Ala Hazrat
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="More" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/naatwebsites">
                Other Naat Websites
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/submit">
                Submit a Naat / Contact Us
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/naatbookpdf">
                Naat Books PDF
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/about">About</NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link href="/naatwebsites">Other Naat Websites</Nav.Link>
						<Nav.Link href="/about">About</Nav.Link>
						<Nav.Link className="justify-content-end" href="/app">Android App</Nav.Link> */}
          </Nav>
          <Button
            variant="success"
            href="https://drive.google.com/file/d/1WZ2I4RFURKy4ctVJzoLmZgZn9rB84iwk/view?usp=sharing"
            target="_blank"
          >
            Android App
            <span>
              <FontAwesomeIcon
                icon={faMobileAlt}
                style={{ marginLeft: '8px' }}
              />
            </span>
          </Button>
        </Navbar.Collapse>
      </Navbar>

      <Helmet>
        <title>{capital_letter(naat_name) + ' - My Naat Book'}</title>
      </Helmet>

      <Modal show={show_youtube_list} onHide={handleYoutubeListClose} centered>
        <Modal.Header
          closeButton
          style={{ backgroundColor: nav_background_color }}
        >
          <Modal.Title style={{ color: 'white' }}>
            {capital_letter(naat_name)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            color: theme_font_color,
            backgroundColor: theme_background_color,
          }}
        >
          <div>{youtube_video_list}</div>
          <div>
            {show_more_youtube_videos_button && (
              <Button
                style={{
                  marginTop: '50px',
                }}
                onClick={youtube_more_videos_list_clicked}
                variant="success"
                block
              >
                More Videos
              </Button>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: nav_background_color }}>
          <Button variant="danger" onClick={handleYoutubeListClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show_writer_name} onHide={handleWriterClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Naat Writer</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontFamily: 'algreya_bold', color: 'black' }}>
          {naat_writer}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleWriterClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show_mistake_name} onHide={handleMistakeClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Please submit the mistake</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please send us an email at: <b>ashiqerasool.naatbook@gmail.com</b>{' '}
          <br /> <br />
          <b>Subject:</b> {naat_name} <br />
          <b>Body:</b> Please provide what is the mistake <br /> <br />
          Inshallah we will correct it as soon as possible
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleMistakeClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show_font_name}
        onHide={handleFontClose}
        style={{
          transform: 'translate(0, 20%)',
          height: '90%',
          color: 'darkgreen',
        }}
        // dialogClassName="font-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Please select a font</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="outline-primary" onClick={setFont1} block>
            Algreya
          </Button>
          <Button variant="outline-primary" onClick={setFont2} block>
            Algreya Bold
          </Button>
          <Button variant="outline-primary" onClick={setFont3} block>
            Bitter
          </Button>
          <Button variant="outline-primary" onClick={setFont4} block>
            Bree Serif
          </Button>
          <Button variant="outline-primary" onClick={setFont5} block>
            Itim
          </Button>
          <Button variant="outline-primary" onClick={setFont6} block>
            Patrick Hand
          </Button>
          <Button variant="outline-primary" onClick={setFont7} block>
            Roboto
          </Button>
          <Button variant="outline-primary" onClick={setFont8} block>
            Ubuntu
          </Button>
          <p
            style={{
              marginTop: '20px',
              fontFamily: font_name,
              fontSize: '20px',
              textAlign: 'center',
            }}
          >
            Sallallahu Alaihi Wasallam
          </p>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="primary" onClick={handleFontClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={show_tags} onHide={handleTagClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Tags/Categories</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Container>
                        <Row>
                            <Col xs={3} style={{ paddingLeft: "0px" }}>
                                <Image
                                    src={process.env.PUBLIC_URL + "/naat_khawan/owais_raza_qadri.png"}
                                    alt="Owais Raza Qadri"
                                    style={{ width: "80px", height: "auto" }}
                                />
                            </Col>
                            <Col xs={9} style={{ paddingLeft: "5px" }}>
                                <p style={naat_khawan_name_s}><b>Owais Raza Qadri</b></p>
                                <p className="naat_khawan_desc" style={naat_khawan_desc_s}>Owais Raza Qadri is undoubtedly one of the best Naat Khawan in the world. He is also known as Bulbul-e-Madina.</p>
                            </Col>
                        </Row>
                    </Container> */}
          {get_tags}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleTagClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        {vidoe_player_visible && <p className="space-before-naat-body"></p>}

        <h5
          style={{
            textAlign: 'center',
            marginTop: '25px',
            marginLeft: '7px',
            fontFamily: 'algreya_bold',
            color: theme_font_color,
          }}
        >
          {capital_letter(naat_name)}
        </h5>
        <hr />

        {/* <a 
                    href="https://naataudio.com/downloads/owaisRazaQadri/Huzoor%20Jante%20Hain%20-%20Owais%20Raza%20Qadri.mp3"
                    download="huzoor_jaante_hai.mp3"
                    target="_blank"
                >Download MP3 File</a> */}

        <div className="space-left-naat-body">{set_naat_body()}</div>
        {/* <p style={{ marginLeft: "7px" }}>{naat_body}</p>
                <p style={{ marginLeft: "7px" }}>{naat_body}</p>
                <p style={{ marginLeft: "7px" }}>{naat_body}</p>
                <p style={{ marginLeft: "7px" }}>{naat_body}</p> */}

        {/* {naat_body.map((naat_line, idx) => 
                    {
                        let red_lines = 0;
                        let blue_lines = 0;
                        let red_col = true;

                        console.log(naat_line);
                        return (<p>{naat_line}</p>);
                    }
                )} */}

        <p className="app_background2">&nbsp;</p>
      </div>

      {/* <div className="fixed-bottom" style={{ width: "100%"}}> */}
      <div className="fixed-bottom" style={{ width: '100%' }}>
        <Row className="float-right">
          <Button
            className="float-right"
            // variant="dark"
            onClick={handleFooterClick}
            aria-controls="collapse_footer"
            aria-expanded={footer_visible}
            style={{
              marginRight: '15px',
              borderRadius: '0px',
              backgroundColor: nav_background_color,
            }}
          >
            <span>
              <FontAwesomeIcon
                icon={getFooterIcon}
                style={{ marginRight: '7px', marginLeft: '7px' }}
              />
            </span>
          </Button>
          {/* <Image className="float-right" src="up_arrow4.png" alt="Hide" onClick={handleFooterClick}/> */}
        </Row>

        <Collapse in={footer_visible}>
          <Row
            style={{ width: '100%', marginLeft: '0px', marginRight: '0px' }}
            id="collapse_footer"
          >
            <Navbar
              variant="dark"
              style={{ width: '100%', backgroundColor: nav_background_color }}
            >
              <div style={{ width: '100%' }}>
                <Row
                  style={{
                    marginBottom: '15px',
                    marginTop: '10px',
                    textAlign: 'center',
                    justifyContent: 'center',
                    color: 'white',
                  }}
                >
                  <Col xs={3}>
                    <Button onClick={handleTagShow}>
                      <span>
                        <FontAwesomeIcon
                          icon={faTags}
                          style={{ marginRight: '7px', marginLeft: '7px' }}
                        />
                      </span>
                    </Button>{' '}
                  </Col>
                  <Col xs={3}>
                    {/* <DropdownButton
                          id="dropdown-basic-button"
                          drop="up"
                          title={
                              <span><FontAwesomeIcon icon={faYoutube} />  </span>
                          }
                        >
                            {getVideoLinks()}
                            {/* <Image alt="Youtube"
                                src={process.env.PUBLIC_URL + "/youtube_icon_on.png"}
                                style={{ marginRight: "25px", width: "35px", height: "35px", cursor: "pointer" }}
                                onClick={handleVideoClick}
                            ></Image> 
                        </DropdownButton> */}

                    {naat_video_link.length > 0 && (
                      <Button
                        variant="primary"
                        onClick={() => handleVideoClick()}
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={faYoutube}
                            style={{ marginRight: '8px', marginLeft: '8px' }}
                          />
                        </span>
                      </Button>
                    )}

                    {naat_video_link.length === 0 && (
                      <Button variant="primary" disabled>
                        <span>
                          <FontAwesomeIcon
                            icon={faYoutube}
                            style={{ marginRight: '8px', marginLeft: '8px' }}
                          />
                        </span>
                      </Button>
                    )}
                  </Col>
                  <Col xs={3}>
                    {naat_audio.length > 0 && (
                      <DropdownButton
                        id="dropdown-basic-button"
                        drop="up"
                        alignRight
                        title={
                          <span>
                            <FontAwesomeIcon icon={faMusic} />{' '}
                          </span>
                        }
                      >
                        {getAudioLinks()}
                      </DropdownButton>
                    )}
                    {naat_audio.length === 0 && (
                      <Button variant="primary" disabled>
                        <span>
                          <FontAwesomeIcon
                            icon={faMusic}
                            style={{ marginRight: '8px', marginLeft: '8px' }}
                          />{' '}
                        </span>
                      </Button>
                    )}
                  </Col>
                  <Col xs={3}>
                    <DropdownButton
                      id="dropdown-basic-button"
                      drop="up"
                      alignRight
                      title={
                        <span>
                          <FontAwesomeIcon
                            icon={faTasks}
                            style={{ marginRight: '3px', marginLeft: '2px' }}
                          />
                        </span>
                      }
                    >
                      <Dropdown.Item key={'writer'} onClick={handleWriterShow}>
                        Writer Name
                      </Dropdown.Item>
                      <Dropdown.Item
                        key={'goto_website'}
                        href={naat_url}
                        target="_blank"
                      >
                        Go to the source website
                      </Dropdown.Item>
                      <Dropdown.Item
                        key={'mistakes'}
                        onClick={handleMistakeShow}
                      >
                        Tell us if any Mistakes
                      </Dropdown.Item>
                      <Dropdown.Item key={'fonts'} onClick={handleFontShow}>
                        Change Font
                      </Dropdown.Item>
                    </DropdownButton>
                  </Col>
                </Row>

                <Collapse in={vidoe_player_visible}>
                  <Row
                    style={{
                      marginBottom: '15px',
                      marginLeft: '15px',
                      marginRight: '15px',
                    }}
                  >
                    <Col>
                      <Button
                        variant="danger"
                        onClick={() => setVideoPlayerVisibility(false)}
                        block
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={faStop}
                            style={{ marginRight: '15px' }}
                          />
                        </span>
                        Stop
                      </Button>{' '}
                    </Col>
                    <Col>
                      <Button
                        variant="success"
                        onClick={() => {
                          youtube_video_list_clicked();
                          handleYoutubeListShow();
                        }}
                        block
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={faList}
                            style={{ marginRight: '15px' }}
                          />
                        </span>
                        Video List
                      </Button>{' '}
                    </Col>
                  </Row>
                </Collapse>

                <Collapse
                  in={audio_player_visible}
                  style={{ marginBottom: '15px' }}
                >
                  <div>
                    <Row>
                      {/* <H5AudioPlayer
                                                src={current_audio_to_play}
                                                showSkipControls={true}
                                                showJumpControls={false}
                                                autoPlayAfterSrcChange={true}
                                            /> */}
                      <Col style={{ textAlign: 'center' }}>
                        {audio_player_visible && (
                          <ReactAudioPlayer
                            src={current_audio_to_play}
                            autoPlay
                            controls
                            loop
                          />
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col style={{ textAlign: 'center', color: 'white' }}>
                        <p>
                          {'Now Playing Link: ' +
                            (naat_audio.indexOf(current_audio_to_play) + 1)}
                        </p>
                      </Col>
                    </Row>

                    <Row
                      style={{
                        marginTop: '2px',
                        justifyContent: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <Col xs={4}>
                        <Button
                          variant="success"
                          onClick={handleAudioPrev}
                          block
                        >
                          <span>
                            <FontAwesomeIcon
                              icon={faStepBackward}
                              style={{ marginRight: '15px' }}
                            />
                          </span>
                          Prev
                        </Button>{' '}
                      </Col>
                      {/* <Col xs={6}>
                                                <Button variant="success" block>Download</Button>{' '}
                                            </Col> */}
                      <Col xs={4}>
                        <Button
                          variant="danger"
                          onClick={() => setAudioPlayerVisiblility(false)}
                          block
                        >
                          <span>
                            <FontAwesomeIcon
                              icon={faStop}
                              style={{ marginRight: '15px' }}
                            />
                          </span>
                          Stop
                        </Button>{' '}
                      </Col>
                      {/* <Col xs={6}>
                                                <Button variant="danger" onClick={() => setAudioPlayerVisiblility(false)}
                                                    block
                                                >Stop</Button>{' '}
                                            </Col> */}
                      <Col xs={4}>
                        <Button
                          variant="success"
                          onClick={handleAudioNext}
                          block
                        >
                          Next
                          <span>
                            <FontAwesomeIcon
                              icon={faStepForward}
                              style={{ marginLeft: '15px' }}
                            />
                          </span>
                        </Button>{' '}
                      </Col>
                    </Row>
                  </div>
                </Collapse>

                <Row
                  className="justify-content-center"
                  style={{ marginBottom: '5px', float: 'center' }}
                >
                  <Form>
                    <Form.Group as={Row} style={{ justifyContent: 'center' }}>
                      <Col xs={8} md={9}>
                        <RangeSlider
                          value={font_size}
                          onChange={(e) => setFontSize(e.target.value)}
                          tooltipPlacement="bottom"
                          min={5}
                          max={50}
                        />
                      </Col>
                      <Col xs={3}>
                        <Form.Control
                          value={font_size}
                          onChange={(e) => setFontSize(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Form>

                  {/* <Col >
                                        <RangeSlider
                                            value={font_size}
                                            onChange={e => setFontSize(e.target.value)}
                                            tooltipPlacement='top'
                                            min={10}
                                            max={200}
                                        />
                                    </Col> */}
                </Row>
              </div>
            </Navbar>
          </Row>
        </Collapse>
      </div>
    </React.Fragment>
  );

  function pushTagInDiv(v_category, v_name, v_desc) {
    let image_name = v_name.toLowerCase().replace(/\s/g, '_');
    let v_category2 = v_category;
    if (v_category2 === 'naat_khawan') {
      v_category2 = 'naatkhawans';
    }
    tagsDiv.push(
      <Row
        key={v_name}
        onClick={() =>
          history.push('/' + v_category2 + '/' + capital_letter(v_name))
        }
        style={{ cursor: 'pointer' }}
      >
        <Col xs={3} style={{ paddingLeft: '0px' }}>
          <Bootstrapimage
            src={
              process.env.PUBLIC_URL +
              '/' +
              v_category +
              '/' +
              image_name +
              '.png'
            }
            alt={capital_letter(v_name)}
            style={{ width: '80px', height: '80px' }}
          />
        </Col>
        <Col xs={9} style={{ paddingLeft: '5px' }}>
          <p style={naat_khawan_name_s}>
            <b>{capital_letter(v_name)}</b>
          </p>
          <p className="naat_khawan_desc" style={naat_khawan_desc_s}>
            {v_desc}
          </p>
        </Col>
      </Row>
    );
  }

  // function handleTagClick(category, name) {
  //     //console.log(props.name);
  //     if (props.category === "naat_khawan") {
  // 		history.push("/" + category + "/" + naat_khawan_name);
  // 	} else {
  // 		history.push("/categories" + "/" + naat_khawan_name);
  // 	}
  // }

  function getTags() {
    //Here starts the Tags code
    //return the code that should be in the MODAL
    //tagsDiv
    //console.log(naat_author);
    // console.log('getTags() got called')
    if (naat_author.includes('ala hazrat')) {
      //add the code to include Ala Hazrat in Tags/Category Modal
      let v_category = 'categories';
      let v_name = 'ala hazrat';
      let v_desc =
        'Imam Ahmed Raza Khan (radi Allahu Ta’ala anhu), popularly known as “A’la Hadrat” in the Islamic world, was born on the 10th of Shawaal 1272 A.H. (14 June 1856) in Bareilly Shareef, India. His father, Moulana Naqi Ali Khan (radi Allahu Ta’ala anhu), and illustrious grandfather, Moulana Rida Ali Khan (radi Allahu Ta’ala anhu), were great Awliya and celebrated theologians recognized as such by academic circles of the entire sub-continent.\n' +
        '\n' +
        'Imam Ahmed Raza Khan Al-Qaaderi (radi Allahu Ta’ala anhu) received his Sanads (Certificates of Proficiency) in Fiqh, Tafseer, Usool-e-Fiqha and Sihah Sitta (Six Authentic Books of Ahadith) from the learned Ulema of his period, including learned Arab Scholars from Makkatul Mukarramah.\n' +
        '\n' +
        'A’la Hadrat (radi Allahu Ta’ala anhu) achieved the status of a versatile scholar and obtained a high distinction in over 50 branches of learning pertaining to the Ancient Sciences, Modern Sciences, Oriental learning, Islamic Jurisprudence, Tassawuf, and numerous other branches.\n' +
        '\n' +
        'On his visit to Makkatul Mukarramah and Madinatul Munawwarah, Imam Ahmed Raza Al-Qaaderi (radi Allahu Ta’ala anhu) was treated with great dignity and was conferred the title of “Imam-e-Ahle-Sunnat” by eminent Ulema. He was also hailed as the Mujaddid “Revivalist” of the Century. Deadly opposed to fanatical extremism, he expounded the pure teachings of the Ahle Sunnah Wa Jamaah. He acted as a shield against those who wanted to assault the principles of the Ahle Sunnah Wa Jamaah.\n' +
        '\n' +
        'As a devout Sufi, A’la Hadrat (radi Allahu Ta’ala anhu) was awarded the Ijaazah and Khilafat (Certificate of Spiritual Successorship) in the Qaaderiya Silsila (Order), as well as in 13 other branches of Sufism. His Sheikh-ut-Tareeqah (Spiritual Master) was the celebrated Grand Master, Sayyid Sha Ale-Rasool Ahmadi Al-Qaaderi Al Barkaati (radi Allahu Ta’ala anhu) of Mahrerah Shareef, India.\n' +
        '\n' +
        'As an author, Imam Ahmed Raza Khan Al-Qaaderi (radi Allahu Ta’ala anhu) has left to his credit more than a 1 000 (thousand) books on 50 different subjects ranging from Tafseer, Logic, Grammar, Literature, Islamic Jurisprudence, Education, Sociology, Astronomy, Mathematics, Physics, History, Science of History, Engineering, Biographies, Philosophy, Mysticism to Persian, Arabic, Urdu and Hindi Literature.\n' +
        '\n' +
        'A’la Hadrat, Imam Ahmed Raza Khan Al-Qaaderi (radi Allahu Ta’ala anhu) left behind many students and Khulafa (Spiritual Successors) who have achieved world-wide prestige for their contributions towards Islam and some, for their exemplary missionary activities. Among them were A’la Hadrat’s (radi Allahu Ta’ala anhu) two sons, Hujjatul Islam, Moulana Sha Haamid Raza Al-Qaaderi Barkaati Noori and Mufti-e-Azam-e-Hind, Moulana Sha Mustapha Raza Al-Qaaderi Barkaati Noori (radi Allahu Ta’ala anhuma); Muballigh-e-Islam, Moulana Abdul Aleem Siddique Al-Qaaderi (radi Allahu Ta’ala anhu), Sadrush Sharee’ah Moulana Amjad Ali Al-Qaaderi (radi Allahu Ta’ala anhu), Malikul Ulema Sayyid Sha Zafruddeen Behari (radi Allahu Ta’ala anhu), Sayyidain Kareemain Abul Barkaat Sayyid Ahmed and Abul Hasanaat Sayyid Muhammad (radi Allahu Ta’ala anhuma), Ustaazul Ulema Moulana Sha Ahmed Mukhtaar Siddique Meerati (radi Allahu Ta’ala anhu), Qutbe Madina Sheikh Ziauddeen Ahmed Siddique Al-Qaaderi Madani (radi Allahu Ta’ala anhu) – to name, but a few.\n' +
        '\n' +
        'A’la Hadrat, Imam Ahmed Raza Khan (radi Allahu Ta’ala anhu) passed away on Friday, the 25th of Safar 1340 A.H. (28 October 1921), when the Mu’azzin was calling “Hayya alal Falaah” in the Azaan of Jummah Salaah. Inna lillahi wa inna ilayhi Raaji’un.\n' +
        '\n' +
        'All in all: Imam Ahmed Raza Khan Al-Qaaderi (radi Allahu Ta’ala anhu) was a multi-dimensional scholar, orator, teacher, author, spiritual leader and true servant of Islam. It can justifiably be said that the mission of A’la Hazrat (radi Allahu Ta’ala anhu) has become a world-wide movement which is gaining popularity day-by-day.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.includes('noori')) {
      let v_category = 'categories';
      let v_name = 'Huzoor Mufti e Azam Hind';
      let v_desc =
        "Maulana Mustafa Raza Khan, commonly known among the Muslims of the Indian sub-continent as 'Mufti-e-Azam Hind' was born in Bareilly (India) in 1892. His father, Moulana Ahmed Raza Khan (1856 – 1921) (radi Allahu anhu), and grandfather, Moulana Naqi Ali Khan (d. 1880) (radi Allahu anhu), were recognised as great scholars of Islam in Islamic academic circles all over the world.";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.includes('tajush shariah')) {
      let v_category = 'categories';
      let v_name = 'Tajush Shariah';
      let v_desc =
        "Taajush Shari'ah, Hadrat Allama Mufti Mohammed Akhtar Raza Khan Azhari Qadri, son of Mufassir-e-A'zam, Hadrat Mawlana Ibrahim Raza Khan (Alaihir Rahmah), son of Hujjatul Islam, Hadrat Mawlana Haamid Raza Khan (Alaihir Rahmah), son of Imam-e-Ahle Sunnah, Mujaddid-e-Deen-o-Millat, Ash Shah Imam Ahmad Raza Khan (Alaihir Rahmah) was born on the 25th of Safar 1942, in the district of Saudagran in the city of Bareilly Shareef, India. When he reached the age of 4 years, 4 months, 4 days, his respected father, Hadrat Mawlana Ibrahim Raza (Alaihir Rahmah), who was a great Islamic Scholar of his age, arranged for his Bismillah Kwaani (Commencement of Islamic Studies).";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.includes('hasan')) {
      let v_category = 'categories';
      let v_name = 'Ustaad e Zaman';
      let v_desc =
        'Shahensha-e-Sukhan, Huzoor Ustad-e-Zaman Hazrat Moulana Hasan Raza Khan RadiaAllahu anhu the younger brother of Aala Hazrat Moulana Ash Shah Imam Ahmad Raza Khan RadiaAllahu anhu son of Raisul Atqiya Hazrat Maulana Naqi Ali Khan RadiaAllahu anhu.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.includes('manqabat e ahle bait')) {
      let v_category = 'categories';
      let v_name = 'manqabat e ahle bait';
      let v_desc =
        'By covering Hazrat Ali (A.S), Hazrat Fatima (S.A), Hazrat Imam Hassan (A.S) and Hazrat Imam Hussain (A.S) in his blanket, the Holy Prophet (peace and blessings be upon him) declared them to be members of his family and said that he was leaving behind two things of vital importance: the Holy Quran and his family, Ahl-e-Bayt. The Holy Prophet (peace and blessings be upon him) connected the love and high esteem of his family with the Holy Quran with a view to making it part of ‘Iman’ and declared that there should be no reduction in importance and respect of the Prophet’s family and Hazrat Ali (A.S) is part of Ahl-e-Bayt.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.includes('manqabat e gause aazam')) {
      let v_category = 'categories';
      let v_name = 'manqabat e gause aazam';
      let v_desc =
        "The birth of Huzoor Ghaus-e-Azam was a great blessing to the Ummah. It was the arrival of Sultanul Awliya (King of the Awliya), which had been foretold for centuries before his birth. He was born on the eve of the 1st of Ramadaan 470 Hijri in the city of Jilan and was blessed to his mother at an age when women are usually unable to have children. Sayyida Ummul Khair Fathima was sixty years old when Sarkaar-e-Ghaus-e-Azam was born. This alone was one of the astonishing incidents related to the birth of the great saint. He is a descendant of the Holy Prophet Muhammad Mustafa (Peace and Blessing be upon Him) from both his mother's and father's side. He is Hassani from his father and Hussaini from his mother. His father's name was Sayyid Abu Saleh Moosa and his mother's name was Sayyida Ummul Khair Fathima.";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.includes('manqabat e garib nawaz')) {
      let v_category = 'categories';
      let v_name = 'manqabat e garib nawaz';
      let v_desc =
        'Khwaja Moinuddin Chisthy (R.A.) was born in Seistan (East Persia) also known as Sejistan, around 537 Hijri (1135-39 a.d.) in a respected family. His father Khwaja Ghayasuddin (R.A.) and mother Syeda Bibi Ummalwara allas Bibi Mahe-Noor were the descendants of Hazrat Ali (K.A.W.) through his sons Imam Hassan and Imam Hussain Khwaja Saheb lost both his father and mother at an early age of sixteen years. He inherited an orchard and a wind mill which serve his means of livelihood. One day when Khwaja Saheb was working in his orchard, a pious dervish and majzoob. Ebrahim Qandoozi, came and took his seat under shade of a tree. When Khwaja Saheb saw him, he brought a bunch of grapes and presented it to his guest. The visitor ate those grapes and was delighted, and took something out of his bag, chewed it and then offered it to his young host. Khwaja Saheb ate it without any hesitation and at once the light of wisdom and knowledge dawned upon the young Khwaja. Thereafter he disposed of all his worldly belongings and distributed the money amongst the poor. Having thus broken all the ties with worldly affairs, he started for Samarqand and Bukhara, which were the great centres of learning, for acquiring Islamic education and Knowledge.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.includes('nazmi')) {
      let v_category = 'categories';
      let v_name = 'Nazmi Sahab';
      let v_desc =
        'Hazrat Hasnain Miyan Nazmi was born on 6th of Ramadan, 1365 H 4th of August, 1946 in a small town of Kasgunj, Distt. Etah, Uttar Pradesh (India). The Arabic letters of his name Syed Fadhlullah Qadri bear the year of his birth. His family name is Syed Muhammad Haider. Nazmi Miyan is the son of great Wali and Jurist of his time, Syed Shah Aley Mustafa alias Syed Miyan (rahmatullahi alaih).';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.includes('ujaagar')) {
      let v_category = 'categories';
      let v_name = 'Ujaagar Sahab';
      let v_desc =
        "Allama Nisar Ali Ujaagar is a Famous Naat Author & Alim E Deen. Some of his popular naat includes 'Aao Mere Nabi Ki Shaan Suno', 'Qadri Aastana Salamat Rahe' and 'Kaaba Dikha De Maula'. May Allah give him high status in jannat ul firdous";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.includes('maulana shakir noorie')) {
      let v_category = 'categories';
      let v_name = 'Maulana Shakir Noorie';
      let v_desc =
        "Born in Junagadh town of Gujarat State in India, Maulana Muhammed Shakir Noorie comes from a humble family background and has completed his education with memorization of the Holy Quran & its recitation, learning Islamic sciences and Ulooms relating to the Sunnah of the Holy Prophet Muhammed Swallalahu alaihi wa sallam. He has been lucky to have the chances of spending valuable times in the company of several pious & blessed friends of Allah, of his times, through whose blessings and guidance, today he rose as a mentor to several people with titles of 'Ataa e Huzoor Mufti e Azam e Hind” and 'Daaiye Kabeer'.";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.includes('madina')) {
      let v_category = 'categories';
      let v_name = 'madina munawara';
      let v_desc =
        'Al Madinah  (the ‘radiant city’ or the ’enlightened city’) or simply called: Al Madinah the ‘City of the Prophet’. It is situated in the Hejaz region of Western Saudi Arabia about 340km north of Makkah and has a population of  1,512,724. Similar to Makkah, entry to Al Madinah is restricted to Muslims only as it is a holy place.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.includes('makkah')) {
      let v_category = 'categories';
      let v_name = 'makkah sharif';
      let v_desc =
        'Makkah is the blessed city which is the most beloved land in the sight of Allah (ﷻ) and the chosen location of His House. It was here that the final prophet and guide of the whole of mankind, Muhammad (ﷺ), was born and commenced his Prophethood.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.includes('ramzan')) {
      let v_category = 'categories';
      let v_name = 'ramzan';
      let v_desc =
        'Ramzan is a time of spiritual reflection, improvement and increased devotion and worship. Ramzan is a holy month, fasting during this month is one of the five pillars of Islam. Muslims are expected to put more effort into following the teachings of Islam. The fast (sawm) begins at dawn and ends at sunset.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.includes('milad_un_nabi')) {
      let v_category = 'categories';
      let v_name = 'milad un nabi';
      let v_desc =
        'The birth of the Holy Prophet (SAW) is the greatest favour of Allah Almighty on humanity. That is why the Muslim community celebrates the birth anniversary of the Holy Prophet (SAW) with traditional zeal and zest on the 12th of Rabi-ul-Awwal and strengthens their faith. However, some mischievous elements, who are devoid of spiritualism, speak against the celebration of birth anniversary of the Holy Prophet (SAW) and declare it as unjustified. The fact remains that in order to strengthen their relationship with the Holy Prophet (SAW). Keeping this in view, Shaykh-ul-Islam Dr Muhammad Tahir-ul-Qadri accorded special importance to the subject of Mawlid-un-Nabi (SAW) in the beginning of his preaching.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.includes('meraj')) {
      let v_category = 'categories';
      let v_name = 'shab e meraj';
      let v_desc =
        'Shab e Miraj is known as The Night Journey in islamic faith and Muslims worldwide. Shab e Miraj observes every year in month of Islamic Date of Rajab 27. Shab e Meraj is also called Lailat al Miraj in Arabic world. Shab e Meraj also called Lailat al Miraj and Isra Wal Miraj, a historical night event much celebrated and respected in Islamic history is believed to be an important physical as well as spiritual journey in the history of Islam. The lailatul Miraj / Shab e Meraj was basically a remarkable ascension of Prophet Muhammad (صلى الله عليه وسلم) to seven heavens which he took on back of an animal Buraq.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.includes('dua')) {
      let v_category = 'categories';
      let v_name = 'dua';
      let v_desc =
        'Dua means supplication, which means communicating with a deity, comes closer than invocation. It is an important part of our faith and belief. We ask from Almighty Allah for everything that we want in our life no matter its how big or small. Dua is a means of keeping in touch with Allah and due to Dua, we get closer to our Lord. So, Dua is a source of keeping the supplicant closer to the Almighty Allah.\n' +
        '\n' +
        'Dua is a form of prayer and it could be made at any time anywhere there is no fixed time and place of making Dua. It is considered as a weapon with the help of which we can change our situation by asking help from Almighty Allah. Dua can change the destiny so try to make Dua in every situation because with Dua you will always get closer to Almighty Allah. It maintained a person’s belief in One Lord and it rejects all forms of idolatry. Dua is essentially a submission to the Creator and a demonstration of a person’s need for Allah.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.includes('punjabi')) {
      let v_category = 'categories';
      let v_name = 'punjabi';
      let v_desc =
        "Punjabi is the most widely spoken language in Pakistan and the third most-spoken native language in the Indian subcontinent. Punjabi language from it's core has a tone of love and poetry.";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.includes('english')) {
      let v_category = 'categories';
      let v_name = 'english';
      let v_desc =
        'English is the largest language by number of speakers. English has developed over the course of more than 1,400 years. There are many writers who wrote Naats, Hamds, Salaams, and Manqabats in English language.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.includes('arabic')) {
      let v_category = 'categories';
      let v_name = 'arabic';
      let v_desc =
        'Arabic is the language of the Qurʾān (The sacred book of Islam) and the religious language of all Muslims. Since understanding Arabic is so important, most Muslims try to learn at least the basics. And a great many Muslims pursue further study in order to understand the full text of the Quran in its original form.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.includes('syed abbas muttaqui')) {
      let v_category = 'categories';
      let v_name = 'syed abbas muttaqui';
      let v_desc =
        'Dr. Syed Abbas Muttaqui has authored five books of poetry in Urdu and four in Persian. He started his career as an amazing teacher of government school and later taughed as a professor in Usmania University. Right from the fifth standard, he started composing poetry and showed it to his Urdu teacher, Saaduddin Qureshi. When the teacher was transferred to another school, Muttaqui was sad and penned a farewell poem in honour of his teacher. He continued his education and did his Master’s and PhD in Urdu and, after retirement, in Persian language.';
      pushTagInDiv(v_category, v_name, v_desc);
    }

    //Naat Khawans Start Here......

    if (naat_author.toLowerCase().includes('owais'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Owais Raza Qadri';
      let v_desc =
        'Owais Raza Qadri is undoubtedly one of the best Naat Khawan in the world. He is also known as Bulbul-e-Madina. He has millions of fans worldwide who love his melodious voice. Alhaj Owais Raza Qadri was born on 1970 in Karachi. He started Naat Khawani at the age of 8. He was married in 1994 in Madina Sharif and has four children. Owais Qadri is greatly inspired by Yousuf Ashrafi late. His several albums have released and his first album was released in 1983.\n' +
        '\n' +
        'He has traveled worldwide to attend the Mehfil-e-Naats and Mehfil-e-Milad. His travels destinations include most of the countries where the Muslim resides. He advised to newcomers that Naat Khawani not a profession but an Ibadat. Before starting Naats Khawani essential knowledge should be learned. Furthermore Naat Khawans should follow the teachings of Islam and the Sunnah of the Holy Prophet (PBUH). The fans from all over the world love his sweet and melodious voice. He has recited many classic Naats. We will try to provide you the all classic Naats of Owais Raza Qadri.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('soharwardi'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Syed Fasihuddin Soharwardi';
      let v_desc =
        'Syed Fasihuddin Soharwardi is one of the most popular Naat Khawan and Nasheed reciter in the Muslim world. He was born on 1957 in Karachi. Fasihuddin Soharwardi started reciting Nasheed at the age of five. He has a unique style with a powerful voice to recite Naats. He has traveled to various countries to attend the Mahafil-e-Naats. Fasihuddin Soharwardi has recited thousands of Naats and Nasheeds. Most of his recited Naats are in Urdu but he also recited in Punjabi, Arabic & Persian. He is greatly impressed by his father Syed Riazuddin Soharwardy. He has released more than 60 albums. Some of his popular Naats are ‘Khuda Ka Zikr Karey, Main Tu Panjtan Ka Ghulam Hoon. Ay Subz gumbad walay. Some of the most famous Naats of Fasihuddin Soharwardi listed below.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('farhan'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Farhan Ali Qadri';
      let v_desc =
        'Farhan Ali Qadri is one of the most popular Naat Khawan in Pakistan and around the world. His naats are in Urdu, Punjabi, Sariki, Sindhi & Balochi languages. He started reciting naats just at the age of five. He got fame with his melodious voice and unique style. Farhan has performed in every corner of Pakistan and all over the world. He is capable of performing for hours. He was born in 1995 in Jacobabad. Farhan Ali named by his elder brother Hamid Ali. He got his training of reciting naats from Sheikh Muhammad Shafi of Jacobabad. In his early days, he performed locally in Milads and school events. He won many prizes at school, district, provincial and national level. His first popular naat was (Noor Wala aya hai). His first album was released in 2006 and in the next two years he released three more albums. All his albums are super duper hits. He won PTV award and numerous awards for his heart touching naat Khawani. Nowadays he is performing in more than one event daily.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('ahmed raza qadri'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Hafiz Ahmed Raza Qadri';
      let v_desc =
        'Hafiz Ahmed Raza Qadri was born on 1990 in Lahore in a religious Family. He started Naat Khawani in his childhood. He had won many Naat competitions in his student life. His first Naat was Ya Muhammad Muhammad Main Kehta Raha. He got fame in Sehri transmission of Geo News with Aamir Liaqat Hussain. In 2018 he was more searched then Owais Raza Qadri on Google. He traveled worldwide like Saudi Arabia, UAE, South Africa, UK, Australia etc. He won several awards worldwide like Most divine Naat Khawan, Award from British Muslim Association London, Award from Pak Welfare Association Kuwait. Ahmed has released more than 10 Naats albums till now. He has attracted the young generation towards Naat Khawani and Islam. A large collection of MP3 Naats of Ahmed Raza Qadri listed below. You can listen online or download some of the famous Naats from the below list.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('tahir'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Hafiz Tahir Qadri';
      let v_desc =
        "Hafiz Tahir Qadri's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice.";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Khursheed Ahmed'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Khursheed Ahmed';
      let v_desc =
        'Khursheed Ahmed was born on 1956 in Rahim Yar Khan. He was amongst the best Naat Khawan of his era. He was a typist in Radio Pakistan and Mehdi Zaheer introduced him on Radio Pakistan. Alhaj Khursheed Ahmed recited Naats in various languages includes Urdu, Arabic, Persian, Sindhi Pashto, etc. His first Naat on PTV was in 1978. He got fame from the Naat Zameen o Zaman Tumharay Liye. Some other popular Naats are Yeh sub-Tumhara Karam Hai Aaqa, Janay Walo Ye Tu Batao, Jashne Amade Rasool, Mehboob Ki Mehfil Ko and so on. He was awarded Pride of performance award and Nigar Award. Alhaaj Khursheed Ahmed died with Brain Hemorrhage. May Allah grant him the place in Jannat-tu-Firdus Ameen.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Qari Rizwan'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Qari Rizwan';
      let v_desc =
        'Qari Rizwan is an extremely talented and increasingly popular Sufi inspired Nasheed artist who has captured the love and passion for the noble Prophet Muhammad (PBUH) in his artistic and melodious expression in a way where he is able to connect to people of all backgrounds and ages. He is an incredible Naat writer and an excellent Naat Khawan';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Asad Attari'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Asad Attari';
      let v_desc =
        'Asad Atrari Al Madani which is one the best naat and quran reciters of Madani Channel as well as Imam of Global Centre of Dawateislami, Faizan-e-Madina. Asad Attari Al-Madani is also included in the list of the top ten most beautiful Quran reciters of the world. He become famous because of his beautiful voice. Many people like him too much and want to Listen his recite. He lives in Pakistan . He is Quran Reciter and also he is Naat Khawan. He performing his duties as Imam in great Mosque Faizan e Madina which situted in Karachi Pakistan. as well as Imam of Global Centre of Dawateislami, Faizan-e-Madina.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author.toLowerCase().includes('Qari Shahid Mehmood'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Qari Shahid Mehmood';
      let v_desc =
        'Qari Shahid Mehmood started Naat Khawani at a very young age. Now he is a well-known Naat Khawan in Pakistan and around the world. He won many prizes in his school life with his melodious voice. He has also won the title of Bulbul e Madian. Shahid Mahmood is one of the most popular Naat Khawan according to International Naat Association. He has a very good fan following nationally and internationally.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author.toLowerCase().includes('Ghulam Mustafa Qadri'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Ghulam Mustafa Qadri';
      let v_desc =
        "Ghulam Mustafa Qadri's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice.";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author.toLowerCase().includes('Mushtaq Attar Qadri'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Mushtaq Attar Qadri';
      let v_desc =
        'Muhammad Mushtaq Qadri was a renowned Pakistani religious Naat Khu’wan and poet. He was a renowned religious speaker and recited various popular Naats and Hamd. Mushtaq Qadri was a very close follower and friend of Muhammad Ilyas Attar Qadri, who is the leader of the peaceful Islamic movement Dawat-e-Islami. He was a Qari and Naat Khu’wan equally loved by people. He died after suffering from throat and chest cancer. Mushtaq Qadri Naats shows his love and devotion towards Prophet (SAW) that will live forever. Mushtaq Qadri was one of the greatest Naat Khu’wan in The Muslim World. He was popular due to his great voice.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author.toLowerCase().includes('Zulfiqar Ali Hussaini'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Zulfiqar Ali Hussaini';
      let v_desc =
        'Zulfiqar Ali is one of the well-known Naat Khawan in Pakistan and worldwide. He participated in many Naat competitions and won several awards. Zulfiqar Ali also performed in many TV shows and Ramzan Transmissions. He has recited Naats in various regional languages. His most popular Naats are Dar e Nabi Per, Waqat e Dua hai, Naat zindgi Hai and so on.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author
        .toLowerCase()
        .includes('Mahmood Ul Hassan Ashrafi'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Mahmood Ul Hassan Ashrafi';
      let v_desc =
        'Mahmood Ul Hassan Ashrafi is a famous Naat Khawan in Pakistan and around the world. He emerged himself with revolutionary voice and unique style. Mahmood Ul Hassan traveled worldwide to attend the Naat events.  He has thousands of die heart fans in Pakistan and all over the world. His most popular Naats are Qaseeda Burda Shareef, Haq La Ilaha Illallah, Baitha Hoon Masjid e Nabvi main etc.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author.toLowerCase().includes('Waheed Zafar Qasmi'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Waheed Zafar Qasmi';
      let v_desc =
        'Waheed Zafar Qasmi was born in 1962. He was a famous Naat Khawan and Qari. He Hifz the Quran-e-Majeed at the age of nine years. Qari Waheed Zafar Qasmi started his career from Radio Pakistan in 1970. His most beautiful recitation of Quran gives him international recognition. He taught Quran Majeed for many years on PTV. He won many national and international awards including Pride of performance award in 1984. His first popular Naat is Allah Hu Allah Hu. Some other popular Naats are Faslon Ko Takalif hay, Zahe muqadar & Midhat us ki. Naat Khawan like Umme Habiba, Javed Akhtar, Roshan Ara Samad are students of Qasmi Sahib.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Milad Raza Qadri'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Milad Raza Qadri';
      let v_desc =
        'Milad Raza Qadri is an extremely talented and increasingly popular young Sufi inspired Nasheed artist who has captured the love and passion for the noble Prophet Muhammad (PBUH) in his artistic and melodious expression in a way where he is able to connect to people of all backgrounds and ages. He performed his first Nasheed with a live audience when he was just four years old and has since committed himself to his faith and passion for Islam. Closely affiliated and strongly encouraged by Shaykh-Ul-Islam Dr Muhammad Tahir Ul Qadri, he pursued his talent and is now one of the most globally popular artists of our time.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('rufi'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Abdul Rauf Rufi';
      let v_desc =
        "Abdul Rauf Rufi's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice.";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Bilal Qadri'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Bilal Qadri';
      let v_desc =
        "Bilal Qadri's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice.";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('fareedi'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Shahbaz Qamar Fareedi';
      let v_desc =
        'Shahbaz Qamar Fareedi is one of the most popular Naat Khawan nowadays. He is reciting Naats from an early age. He also participated in many computations at the school level and National level. Shahbaz also won the title of Bulbul e Pakistan at a very younger age. Sher Ali and Mehar Ali well Known Qawals are his teachers according to him. He also inspired by Ustad Nusrat Ali Khan. He has traveled to Saudi Arabia, Japan, UAE, UK, South Africa, Norway, etc to attend Mahafil-e-Naat. His son Usman has also recited Naats in his melodious voice. His popular Naats are Zameen Mailee Nahi Hoti Zaman Maila Nahi Hota, Nori mukhra tay Zulfan nay kaliayan etc.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author.toLowerCase().includes('Imran Sheikh Attari'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Imran Sheikh Attari';
      let v_desc =
        "Imran Sheikh Attari's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice.";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author
        .toLowerCase()
        .includes('Hafiz Nisar Ahmed Marfani'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Hafiz Nisar Ahmed Marfani';
      let v_desc =
        "Hafiz Nisar Ahmed Marfani's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice.";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author.toLowerCase().includes('Khalid Hasnain Khalid'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Khalid Hasnain Khalid';
      let v_desc =
        'Khalid Hasnain Khalid is a famous Naat Khawan from Chakwal. He started Naat Khawani when he was a kid. He goes religious education from Rarululoom Mohammadia Islamabad. His most popular Naats are Apni Rehmat K Sumandar me, Kis Tarah Se Chand, Saaye Mein Tumhare Hain.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Amir Liaqat'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Amir Liaqat';
      let v_desc =
        'A Muslim scholar, TV host, and a socialist Amir Liaquat Hussain need no introduction. He is now a household name in Pakistan. Aamir Liaquat Hussain was a great Muslim scholar. He was born in July 5, 1971 in Karachi, Pakistan, is the former Pakistani minister of state for religious affairs. He is the son of Sheikh Liaquat Hussain and Begum Mehmooda Sultana who, like himself, was active politicians. Aamir Liaquat entered politics in 1988 by joining the All Pakistan Mohajir Students Organization. Amir Liaquat Hussain had a religious mind and he has a great voice in Naats. Listen and free download Naats of Amir Liaquat Hussain.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Sami Yusuf'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Sami Yusuf';
      let v_desc =
        'Sami Yusuf is by no means your typical British celebrity: The Guardian named him “the biggest British star in the Middle East”, Time Magazine dubbed him “Islam’s Biggest Rockstar”, while the United Nations has appointed him a UN Global Ambassador Against Hunger and recognized him as a promoter of world peace and harmony. Millions have come to know him as an iconic singer and his strikingly strong, clear voice is heard worldwide on radio, on TV, and in sold-out concert halls, stadiums, and city squares. But over time Sami Yusuf has proven to be much more. Those who follow his music recognize his enormous talent as an instrumentalist, composer, arranger, and producer while many others have come to know him through his influential humanitarian work.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Rehan Qadri'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Rehan Qadri';
      let v_desc =
        'Syed Rehan Qadri is one of the famous Naat Khawan. Allhamdulilah he has more then 45 albums. He was born in 1973 and now living in Karachi he has 2 daughters & one son.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author.toLowerCase().includes('Hafiz Tassawor Attari'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Hafiz Tassawor Attari';
      let v_desc =
        'Hafiz Tassawor Attari Sahab is one of the pupular naat Khawan. His voice touches millions of hearts. He has a unique style to recite Naats which shows his love towards Prophet Muhammad Mustafa Salle Ala Hu Alihi Wasallam.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author.toLowerCase().includes('Imran Mustafa Attari'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Imran Mustafa Attari';
      let v_desc =
        'Syed Imran Mustafa Attari is one of those lucky few youngsters who have had their name famous all over the world, but not in any ordinary sense. His claim to fame is his fervor for spreading the Deen, and Ishq-E-Rusul (S.A.W).';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Furqan qadri'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Furqan qadri';
      let v_desc =
        'Syed Furqan Qadri is good aswel in his style. They are all good aslong as they praise our Prohpet (SAW). The reason of his popularity was his impressive and simple personality. The reason of his popularity was his impressive and simple personality. He earned great respect and appreciation due to his unique way of reciting Naats. Syed Furqan Qadri has awarded with a great voice by Allah Subhan o Tala.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author.toLowerCase().includes('Syed Khalid Hussain'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Syed Khalid Hussain';
      let v_desc =
        'Syed Khalid Hussain Shah is one the famous Naat khwans of Pakistan. He got marvelous accent which attracts his fans. He is a living legend and an asset for Islamic World & our Homeland; He is the symbol of pride for the Nation & Islamic Ummah. The service for the promotion of Hamd & Naat Khuwani he has rendered is the reflection of his love & dedication with the Prophet of Peace Hazrat Muhammad SAW.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('jamshed'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Junaid Jamshed';
      let v_desc =
        'Junaid Jamshed was a Naat Khawan, pop star, fashion designer, host & preacher. He got fame from his song Dil Dil Pakistan. He was born in 1964 in Karachi. His father was in Pakistan Air Force as a group captain. He is a bachelor in Mechanical Engineering. He started his singing career in 1983. His band Vital Signs released his first album in 1987. He leaves the music industry when his career was at the peak. Junaid Jamshaid devoted himself to the preaching of Islam. He was also in the list of top 500 influential Muslims.\n' +
        '\n' +
        '\n' +
        ' \n' +
        'In the late 1990s’ he met with renowned scholar Maulana Tariq Jameel in a Tablighi Ijtima. Junaid Jamshaid leaves the music industry and devoted himself for religion. He came back into music for a while and leave the music industry completely in 2004. Junaid struggled financially after leaving the music industry. He came over his financial problems after the success of her clothing brand J. Junaid Jamshaid recites many naats from 2003 until his death. His popular Naat albums are Jalwa-e-Janan, Badr-Ud-Duja, Hadi-Ul-Anaam, Noor-ul-Huda, etc.\n' +
        '\n' +
        'He died in a plane crash with his second wife while he was returning to Islamabad from Chitral. His plane was crashed near Havelian killing all the passengers. His death remembered as a national tragedy. Maulana Tariq Jameel offered his funeral prayer and he was buried in Darul Uloom Karachi.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Yusuf Memon'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Yusuf Memon';
      let v_desc =
        'Yusuf Memon had taken part in numerous gatherings where he recited his poems in praise of Prophet Muhammad (PBUH). A few famous naats recited by him included ‘Maire Kamli Wale Ki Shan’ and ‘Huzoor Koi Aisa Intizam’.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author.toLowerCase().includes('Marghoob Ahmed Hamdani'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Marghoob Ahmed Hamdani';
      let v_desc =
        'Marghoob Ahmed Hamdani is a popular Naat Khawan of his era. He was born in Lahore. His father Manzoor Ahmed Hamdani was also a Naat Khawan. He recites Naats on Radio Pakistan and PTV for many years. Marghoob Ahmed appeared on Radio and PTV for the first time in 1967. He has recited many famous Urdu and Punjabi Naats. His Brother Mehboob Ahmed Hamdani is also a well-known Naat Khawan. He won Pride of Performance by the Government of Pakistan. He has a huge fan following worldwide who likes Naats in his melodious and powerful voice. Some of his popular Naats are Banay Hain Dono Jahan, Wo Suey Lalazar Phirte Hain, Jab Masjid E Nabvi Ke Minar Nazar Aaye, Amina Bibi Ke Gulshan Mein Etc.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('sabihuddin'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Syed Sabihuddin Sabih Rehmani';
      let v_desc =
        'Sabih Rehmani was born at Karachi in 1965. He is reciting Naats for last 30 years. He served as Sr, Manager Planning Research in QTV till 2010. Since 1994, he is serving as Secretary-General for Naat research center. His unique style of reciting Naat renewed globally.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author
        .toLowerCase()
        .includes('Syed Zabeeb Masood Shah'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Syed Zabeeb Masood Shah';
      let v_desc =
        'Syed Zabeeb Masood Shah is a very popular Naat Khawan. He has learned Naat Khawani from Syed Manzoor ul Kounain Shah Sb. He belongs from Wah Cantt Rawalpindi. Zabeeb Masood has won many prizes from school level to national level. He has performed in all over Pakistan and worldwide.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author.toLowerCase().includes('Muhammad Umair Zubair'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Muhammad Umair Zubair';
      let v_desc =
        'Muhammad Umair Zubair is very popular Naat Khawan of Pakistan. He has a melodious voice with a unique style. Some of his popular Naats are Nabi Ka Zikar Hi, Q Log Ye Kehtay Hain, Dil Karda Allah Allah, etc.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Amjad Sabri'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Amjad Sabri';
      let v_desc =
        "Amjad Sabri's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice.";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author.toLowerCase().includes('Akhtar Hussain Qureshi'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Akhtar Hussain Qureshi';
      let v_desc =
        "Akhtar Hussain Qureshi's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice.";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Abdul Hameed Rana'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Abdul Hameed Rana';
      let v_desc =
        "Abdul Hameed Rana's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice.";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author.toLowerCase().includes('Mehboob Ahmed Hamdani'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Mehboob Ahmed Hamdani';
      let v_desc =
        "Mehboob Ahmed Hamdani's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice.";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author.toLowerCase().includes('Abdul Basit Hassani'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Abdul Basit Hassani';
      let v_desc =
        "Abdul Basit Hassani's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice.";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Rehan Naqshbandi'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Rehan Naqshbandi';
      let v_desc =
        "Rehan Naqshbandi's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice.";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Hafiz Abu Bakar'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Hafiz Abu Bakar';
      let v_desc =
        "Hafiz Abu Bakar's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice.";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Anas Younus'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Anas Younus';
      let v_desc =
        "Anas Younus's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice.";
      pushTagInDiv(v_category, v_name, v_desc);
    }

    //Female Naat Khawans
    if (
      naat_author.toLowerCase().includes('Hooria Faheem Qadri'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Hooria Faheem Qadri';
      let v_desc =
        'Hooria Faheem Qadri was born in Karachi. She is one of the most famous female Naat Khawan nowadays. She is appearing regularly on QTV and other Islamic TV channels. Hooria started Naat Khawani just at the age of five years. Her more than 20 albums released till now. She was married to Faheem ud Din Nizami and has one son and one daughter. She traveled to many countries for Mahafil-e-Naats, especially in the UK. Hooria Faheem has millions of fans worldwide. She always encourages females to recite Naats.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Javeria Saleem'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Javeria Saleem';
      let v_desc =
        'Javeria Saleem is a well-known female Naat Khawan. She has recited Naats on QTV, ARY Digital, PTV, HUM TV, Geo TV, etc. She also recites Naats in Ramzan transmission on different TV channels. Allah Tala has gifted her with a great melodious voice. She is also Hafiza of Quran and known as the one of the best Qariya in Pakistan. She has won many prizes from the provincial level to the national level. Javeria Saleem is also known for her simplicity.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Abida Khanum'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Abida Khanum';
      let v_desc =
        "Abida Khanum's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through her voice.";
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Umme Habiba'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Umme Habiba';
      let v_desc =
        'Umme Habiba is one of the best female Naat Khawan of Pakistan and around the world. Fan following around the world enjoy her melodious voices. She is reciting Naats around 40 years now. She born in Karachi and moved to America 30 years ago. Umme Habiba started reciting Naats in 1969. She awarded with Pride of Performance by the President of Pakistan. She traveled throughout the USA and around the world for Naat reciting. Her most famous Naats are Jab Zaban Pay Muhammad Ka Naam, Meri Janib Bhi Ho Aik Nighahe Karam, Kabay Per Pari jab Pehli Nazar and so on.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Hina Nasrullah'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Hina Nasrullah';
      let v_desc =
        'Hina Nasrullah is a popular Naat Khawan and Sufi singer in Pakistan. She discovered by Yousuf Salahuddin. She appeared in many musical programs in his childhood. Hina blessed with a sweet melodious voice.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author.toLowerCase().includes('Ayesha Abdul Basit'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Ayesha Abdul Basit';
      let v_desc =
        'Ayesha Basit got fame after reciting the Naat Hasbi Rabbi Jalallah. She belongs to India and now lived in Abu Dhabi UAE with her parents. More than 45 million people have watched her Naat Hasbi Rabbi on YouTube.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (
      naat_author.toLowerCase().includes('Syeda Amber Saleem'.toLowerCase())
    ) {
      let v_category = 'naat_khawan';
      let v_name = 'Syeda Amber Saleem';
      let v_desc =
        'Amber Saleem is a well-known female Naat Khawan of Pakistan. She has recited many famous Naats. You can listen or download famous Naats of Amber Saleem with one click on audionaats.com. Beautiful Naat by Amber Saleem.';
      pushTagInDiv(v_category, v_name, v_desc);
    }
    if (naat_author.toLowerCase().includes('Zara Rasheed'.toLowerCase())) {
      let v_category = 'naat_khawan';
      let v_name = 'Zara Rasheed';
      let v_desc =
        'Zara Rasheed born in Karachi. No doubt she is true Aashiq-e-Rasool (Peace Be Upon Him). Her voice is like voice of heaven. The beautiful Naat recited by this True Ashiq-e-Rasool (Peace Be Upon Him) girl. she has no light in her eyes but i believe her heart is lit with the light of Eshq e Rasool (Peace Be Upon Him).';
      pushTagInDiv(v_category, v_name, v_desc);
    }

    //check if none of the above tags are found then say no tags
    if (tagsDiv === undefined || tagsDiv.length == 0) {
      tagsDiv.push(
        <p
          key="empty_div"
          style={{ color: 'darkgreen', fontFamily: 'algreya_regular' }}
        >
          <b>No Tags/Categories found</b>
        </p>
      );
    }

    return <Container>{tagsDiv}</Container>;
  }
}

// const audio_icon_style = {
//     width: '20px',
//     height: '20px',
//     align: 'right',
// };

const naat_khawan_name_s = {
  marginLeft: '5px',
  marginTop: '0px',
  marginBottom: '0px',
};

const naat_khawan_desc_s = {
  marginLeft: '5px',
  marginTop: '0px',
  fontSize: 'small',
  overFlow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'webkit-box',
};

export default SingleNaat;
