import React, { useState, useEffect } from 'react'
import { Row, Col, Image, Container } from 'react-bootstrap'
// import { useWindowScroll } from "react-use";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBaseballBall } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
// import SingleNaat from "./SingleNaat.js";
import './other.css'
//import Home from "./Home.js";

const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey) || '',
  )

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value)
  }, [value])

  return [value, setValue]
}

function NaatKhawanRow(props) {
  const [theme_name, setThemeName] = useStateWithLocalStorage('theme_name')
  const [theme_background_color, setBackgroundColor] = useStateWithLocalStorage(
    'theme_background_color',
  )

  const [
    nav_background_color,
    setNavBackgroundColor,
  ] = useStateWithLocalStorage('nav_background_color')
  const [theme_font_color, setThemeFontColor] = useStateWithLocalStorage(
    'font_color',
  )
  const [theme_font_color1, setThemeFontColor1] = useStateWithLocalStorage(
    'font_color1',
  )
  const [theme_font_color2, setThemeFontColor2] = useStateWithLocalStorage(
    'font_color2',
  )

  const setThemeDefault = () => {
    setThemeName('default')
    setBackgroundColor('white')
    setNavBackgroundColor('#000051')
    setThemeFontColor('black')
    setThemeFontColor1('red')
    setThemeFontColor2('blue')
    window.location.reload()
  }
  const setThemeMadina = () => {
    setThemeName('madina')
    setBackgroundColor('#F0FFF0')
    setNavBackgroundColor('#003300')
    setThemeFontColor('black')
    setThemeFontColor1('red')
    setThemeFontColor2('blue')
    window.location.reload()
  }
  const setThemeMecca = () => {
    setThemeName('mecca')
    setBackgroundColor('#3F3F3F')
    setNavBackgroundColor('#212121')
    setThemeFontColor('white')
    setThemeFontColor1('#33FFE9')
    setThemeFontColor2('#FF9962')
    window.location.reload()
  }
  const setThemeAlaHazrat = () => {
    setThemeName('ala_hazrat')
    setBackgroundColor('#F0FFFF')
    setNavBackgroundColor('#00363a')
    setThemeFontColor('black')
    setThemeFontColor1('red')
    setThemeFontColor2('blue')
    window.location.reload()
  }

  if (theme_name === '') {
    setThemeName('default')
    setBackgroundColor('white')
    setNavBackgroundColor('#000051')
    setThemeFontColor('black')
    setThemeFontColor1('red')
    setThemeFontColor2('blue')
  }
  // const history = useHistory();
  var category = props.category
  var naat_khawan_name = props.name
  var naat_khawan_desc = ''
  var naat_khawan_image = props.name.toLowerCase().replace(/\s/g, '_')
  var naat_kahwan_image_address =
    '/' + category + '/' + naat_khawan_image + '.png'

  //console.log(naat_kahwan_image_address);

  if (category === 'naat_khawan') {
    if (props.name === 'Owais Raza Qadri') {
      naat_khawan_desc =
        'Owais Raza Qadri is undoubtedly one of the best Naat Khawan in the world. He is also known as Bulbul-e-Madina. He has millions of fans worldwide who love his melodious voice. Alhaj Owais Raza Qadri was born on 1970 in Karachi. He started Naat Khawani at the age of 8. He was married in 1994 in Madina Sharif and has four children. Owais Qadri is greatly inspired by Yousuf Ashrafi late. His several albums have released and his first album was released in 1983.\n' +
        '\n' +
        'He has traveled worldwide to attend the Mehfil-e-Naats and Mehfil-e-Milad. His travels destinations include most of the countries where the Muslim resides. He advised to newcomers that Naat Khawani not a profession but an Ibadat. Before starting Naats Khawani essential knowledge should be learned. Furthermore Naat Khawans should follow the teachings of Islam and the Sunnah of the Holy Prophet (PBUH). The fans from all over the world love his sweet and melodious voice. He has recited many classic Naats. We will try to provide you the all classic Naats of Owais Raza Qadri.'
    } else if (props.name === 'Syed Fasihuddin Soharwardi') {
      naat_khawan_desc =
        'Syed Fasihuddin Soharwardi is one of the most popular Naat Khawan and Nasheed reciter in the Muslim world. He was born on 1957 in Karachi. Fasihuddin Soharwardi started reciting Nasheed at the age of five. He has a unique style with a powerful voice to recite Naats. He has traveled to various countries to attend the Mahafil-e-Naats. Fasihuddin Soharwardi has recited thousands of Naats and Nasheeds. Most of his recited Naats are in Urdu but he also recited in Punjabi, Arabic & Persian. He is greatly impressed by his father Syed Riazuddin Soharwardy. He has released more than 60 albums. Some of his popular Naats are ‘Khuda Ka Zikr Karey, Main Tu Panjtan Ka Ghulam Hoon. Ay Subz gumbad walay. Some of the most famous Naats of Fasihuddin Soharwardi listed below.'
    } else if (props.name === 'Farhan Ali Qadri') {
      naat_khawan_desc =
        'Farhan Ali Qadri is one of the most popular Naat Khawan in Pakistan and around the world. His naats are in Urdu, Punjabi, Sariki, Sindhi & Balochi languages. He started reciting naats just at the age of five. He got fame with his melodious voice and unique style. Farhan has performed in every corner of Pakistan and all over the world. He is capable of performing for hours. He was born in 1995 in Jacobabad. Farhan Ali named by his elder brother Hamid Ali. He got his training of reciting naats from Sheikh Muhammad Shafi of Jacobabad. In his early days, he performed locally in Milads and school events. He won many prizes at school, district, provincial and national level. His first popular naat was (Noor Wala aya hai). His first album was released in 2006 and in the next two years he released three more albums. All his albums are super duper hits. He won PTV award and numerous awards for his heart touching naat Khawani. Nowadays he is performing in more than one event daily.'
    } else if (props.name === 'Hafiz Ahmed Raza Qadri') {
      naat_khawan_desc =
        'Hafiz Ahmed Raza Qadri was born on 1990 in Lahore in a religious Family. He started Naat Khawani in his childhood. He had won many Naat competitions in his student life. His first Naat was Ya Muhammad Muhammad Main Kehta Raha. He got fame in Sehri transmission of Geo News with Aamir Liaqat Hussain. In 2018 he was more searched then Owais Raza Qadri on Google. He traveled worldwide like Saudi Arabia, UAE, South Africa, UK, Australia etc. He won several awards worldwide like Most divine Naat Khawan, Award from British Muslim Association London, Award from Pak Welfare Association Kuwait. Ahmed has released more than 10 Naats albums till now. He has attracted the young generation towards Naat Khawani and Islam. A large collection of MP3 Naats of Ahmed Raza Qadri listed below. You can listen online or download some of the famous Naats from the below list.'
    } else if (props.name === 'Hafiz Tahir Qadri') {
      naat_khawan_desc =
        "Hafiz Tahir Qadri's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice."
    } else if (props.name === 'Khursheed Ahmed') {
      naat_khawan_desc =
        'Khursheed Ahmed was born on 1956 in Rahim Yar Khan. He was amongst the best Naat Khawan of his era. He was a typist in Radio Pakistan and Mehdi Zaheer introduced him on Radio Pakistan. Alhaj Khursheed Ahmed recited Naats in various languages includes Urdu, Arabic, Persian, Sindhi Pashto, etc. His first Naat on PTV was in 1978. He got fame from the Naat Zameen o Zaman Tumharay Liye. Some other popular Naats are Yeh sub-Tumhara Karam Hai Aaqa, Janay Walo Ye Tu Batao, Jashne Amade Rasool, Mehboob Ki Mehfil Ko and so on. He was awarded Pride of performance award and Nigar Award. Alhaaj Khursheed Ahmed died with Brain Hemorrhage. May Allah grant him the place in Jannat-tu-Firdus Ameen.'
    } else if (props.name === 'Qari Rizwan') {
      naat_khawan_desc =
        'Qari Rizwan is an extremely talented and increasingly popular Sufi inspired Nasheed artist who has captured the love and passion for the noble Prophet Muhammad (PBUH) in his artistic and melodious expression in a way where he is able to connect to people of all backgrounds and ages. He is an incredible Naat writer and an excellent Naat Khawan'
    } else if (props.name === 'Asad Attari') {
      naat_khawan_desc =
        'Asad Atrari Al Madani which is one the best naat and quran reciters of Madani Channel as well as Imam of Global Centre of Dawateislami, Faizan-e-Madina. Asad Attari Al-Madani is also included in the list of the top ten most beautiful Quran reciters of the world. He become famous because of his beautiful voice. Many people like him too much and want to Listen his recite. He lives in Pakistan . He is Quran Reciter and also he is Naat Khawan. He performing his duties as Imam in great Mosque Faizan e Madina which situted in Karachi Pakistan. as well as Imam of Global Centre of Dawateislami, Faizan-e-Madina.'
    } else if (props.name === 'Qari Shahid Mehmood') {
      naat_khawan_desc =
        'Qari Shahid Mehmood started Naat Khawani at a very young age. Now he is a well-known Naat Khawan in Pakistan and around the world. He won many prizes in his school life with his melodious voice. He has also won the title of Bulbul e Madian. Shahid Mahmood is one of the most popular Naat Khawan according to International Naat Association. He has a very good fan following nationally and internationally.'
    } else if (props.name === 'Ghulam Mustafa Qadri') {
      naat_khawan_desc =
        "Ghulam Mustafa Qadri's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice."
    } else if (props.name === 'Mushtaq Attar Qadri') {
      naat_khawan_desc =
        'Muhammad Mushtaq Qadri was a renowned Pakistani religious Naat Khu’wan and poet. He was a renowned religious speaker and recited various popular Naats and Hamd. Mushtaq Qadri was a very close follower and friend of Muhammad Ilyas Attar Qadri, who is the leader of the peaceful Islamic movement Dawat-e-Islami. He was a Qari and Naat Khu’wan equally loved by people. He died after suffering from throat and chest cancer. Mushtaq Qadri Naats shows his love and devotion towards Prophet (SAW) that will live forever. Mushtaq Qadri was one of the greatest Naat Khu’wan in The Muslim World. He was popular due to his great voice.'
    } else if (props.name === 'Zulfiqar Ali Hussaini') {
      naat_khawan_desc =
        'Zulfiqar Ali is one of the well-known Naat Khawan in Pakistan and worldwide. He participated in many Naat competitions and won several awards. Zulfiqar Ali also performed in many TV shows and Ramzan Transmissions. He has recited Naats in various regional languages. His most popular Naats are Dar e Nabi Per, Waqat e Dua hai, Naat zindgi Hai and so on.'
    } else if (props.name === 'Siddique Ismail') {
      naat_khawan_desc =
        'Siddique Ismail was born in 1954 in Karachi. He started Naat Khawani at the age of nine years. Due to polio, he lost his legs at an early age. He is reciting Naats for more than 50 years now. The first time he appeared on radio Pakistan in 1956 and late on in 1969 he recited Naats on PTV. He is one of the first Naat Khawan of PTV. He got fame from the Documentary of the construction of Makkah & Madian. In his melodious voice, the Azaan telecasted for many years on PTV.\n' +
        '\n' +
        '\n' +
        ' \n' +
        'He was awarded pride of performance in 1985 & Sitara-e-Imtiaz in 2012. Most of his Naats are in Urdu language but he also recites Naats in Arabic, Persian, Sindhi, Pashtu languages. He recited more than 1200 Naats. His most famous Naats are Ya Rasool Allah Teray Dar k Ghulamon, Noori Mehfil Pay Chadr Tani Noor key, Madinay Ka Safar Hai, etc.'
    } else if (props.name === 'Mahmood Ul Hassan Ashrafi') {
      naat_khawan_desc =
        'Mahmood Ul Hassan Ashrafi is a famous Naat Khawan in Pakistan and around the world. He emerged himself with revolutionary voice and unique style. Mahmood Ul Hassan traveled worldwide to attend the Naat events.  He has thousands of die heart fans in Pakistan and all over the world. His most popular Naats are Qaseeda Burda Shareef, Haq La Ilaha Illallah, Baitha Hoon Masjid e Nabvi main etc.'
    } else if (props.name === 'Waheed Zafar Qasmi') {
      naat_khawan_desc =
        'Waheed Zafar Qasmi was born in 1962. He was a famous Naat Khawan and Qari. He Hifz the Quran-e-Majeed at the age of nine years. Qari Waheed Zafar Qasmi started his career from Radio Pakistan in 1970. His most beautiful recitation of Quran gives him international recognition. He taught Quran Majeed for many years on PTV. He won many national and international awards including Pride of performance award in 1984. His first popular Naat is Allah Hu Allah Hu. Some other popular Naats are Faslon Ko Takalif hay, Zahe muqadar & Midhat us ki. Naat Khawan like Umme Habiba, Javed Akhtar, Roshan Ara Samad are students of Qasmi Sahib.'
    } else if (props.name === 'Milad Raza Qadri') {
      naat_khawan_desc =
        'Milad Raza Qadri is an extremely talented and increasingly popular young Sufi inspired Nasheed artist who has captured the love and passion for the noble Prophet Muhammad (PBUH) in his artistic and melodious expression in a way where he is able to connect to people of all backgrounds and ages. He performed his first Nasheed with a live audience when he was just four years old and has since committed himself to his faith and passion for Islam. Closely affiliated and strongly encouraged by Shaykh-Ul-Islam Dr Muhammad Tahir Ul Qadri, he pursued his talent and is now one of the most globally popular artists of our time.'
    } else if (props.name === 'Abdul Rauf Rufi') {
      naat_khawan_desc =
        "Abdul Rauf Rufi's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice."
    } else if (props.name === 'Bilal Qadri') {
      naat_khawan_desc =
        "Bilal Qadri's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice."
    } else if (props.name === 'Shahbaz Qamar Fareedi') {
      naat_khawan_desc =
        'Shahbaz Qamar Fareedi is one of the most popular Naat Khawan nowadays. He is reciting Naats from an early age. He also participated in many computations at the school level and National level. Shahbaz also won the title of Bulbul e Pakistan at a very younger age. Sher Ali and Mehar Ali well Known Qawals are his teachers according to him. He also inspired by Ustad Nusrat Ali Khan. He has traveled to Saudi Arabia, Japan, UAE, UK, South Africa, Norway, etc to attend Mahafil-e-Naat. His son Usman has also recited Naats in his melodious voice. His popular Naats are Zameen Mailee Nahi Hoti Zaman Maila Nahi Hota, Nori mukhra tay Zulfan nay kaliayan etc.'
    } else if (props.name === 'Imran Sheikh Attari') {
      naat_khawan_desc =
        "Imran Sheikh Attari's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice."
    } else if (props.name === 'Hafiz Nisar Ahmed Marfani') {
      naat_khawan_desc =
        "Hafiz Nisar Ahmed Marfani's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice."
    } else if (props.name === 'Khalid Hasnain Khalid') {
      naat_khawan_desc =
        'Khalid Hasnain Khalid is a famous Naat Khawan from Chakwal. He started Naat Khawani when he was a kid. He goes religious education from Rarululoom Mohammadia Islamabad. His most popular Naats are Apni Rehmat K Sumandar me, Kis Tarah Se Chand, Saaye Mein Tumhare Hain.'
    } else if (props.name === 'Amir Liaqat') {
      naat_khawan_desc =
        'A Muslim scholar, TV host, and a socialist Amir Liaquat Hussain need no introduction. He is now a household name in Pakistan. Aamir Liaquat Hussain was a great Muslim scholar. He was born in July 5, 1971 in Karachi, Pakistan, is the former Pakistani minister of state for religious affairs. He is the son of Sheikh Liaquat Hussain and Begum Mehmooda Sultana who, like himself, was active politicians. Aamir Liaquat entered politics in 1988 by joining the All Pakistan Mohajir Students Organization. Amir Liaquat Hussain had a religious mind and he has a great voice in Naats. Listen and free download Naats of Amir Liaquat Hussain.'
    } else if (props.name === 'Sami Yusuf') {
      naat_khawan_desc =
        'Sami Yusuf is by no means your typical British celebrity: The Guardian named him “the biggest British star in the Middle East”, Time Magazine dubbed him “Islam’s Biggest Rockstar”, while the United Nations has appointed him a UN Global Ambassador Against Hunger and recognized him as a promoter of world peace and harmony. Millions have come to know him as an iconic singer and his strikingly strong, clear voice is heard worldwide on radio, on TV, and in sold-out concert halls, stadiums, and city squares. But over time Sami Yusuf has proven to be much more. Those who follow his music recognize his enormous talent as an instrumentalist, composer, arranger, and producer while many others have come to know him through his influential humanitarian work.'
    } else if (props.name === 'Rehan Qadri') {
      naat_khawan_desc =
        'Syed Rehan Qadri is one of the famous Naat Khawan. Allhamdulilah he has more then 45 albums. He was born in 1973 and now living in Karachi he has 2 daughters & one son.'
    } else if (props.name === 'Hafiz Tassawor Attari') {
      naat_khawan_desc =
        'Hafiz Tassawor Attari Sahab is one of the pupular naat Khawan. His voice touches millions of hearts. He has a unique style to recite Naats which shows his love towards Prophet Muhammad Mustafa Salle Ala Hu Alihi Wasallam.'
    } else if (props.name === 'Imran Mustafa Attari') {
      naat_khawan_desc =
        'Syed Imran Mustafa Attari is one of those lucky few youngsters who have had their name famous all over the world, but not in any ordinary sense. His claim to fame is his fervor for spreading the Deen, and Ishq-E-Rusul (S.A.W).'
    } else if (props.name === 'Furqan qadri') {
      naat_khawan_desc =
        'Syed Furqan Qadri is good aswel in his style. They are all good aslong as they praise our Prohpet (SAW). The reason of his popularity was his impressive and simple personality. The reason of his popularity was his impressive and simple personality. He earned great respect and appreciation due to his unique way of reciting Naats. Syed Furqan Qadri has awarded with a great voice by Allah Subhan o Tala.'
    } else if (props.name === 'Syed Khalid Hussain') {
      naat_khawan_desc =
        'Syed Khalid Hussain Shah is one the famous Naat khwans of Pakistan. He got marvelous accent which attracts his fans. He is a living legend and an asset for Islamic World & our Homeland; He is the symbol of pride for the Nation & Islamic Ummah. The service for the promotion of Hamd & Naat Khuwani he has rendered is the reflection of his love & dedication with the Prophet of Peace Hazrat Muhammad SAW.'
    } else if (props.name === 'Junaid Jamshed') {
      naat_khawan_desc =
        'Junaid Jamshed was a Naat Khawan, pop star, fashion designer, host & preacher. He got fame from his song Dil Dil Pakistan. He was born in 1964 in Karachi. His father was in Pakistan Air Force as a group captain. He is a bachelor in Mechanical Engineering. He started his singing career in 1983. His band Vital Signs released his first album in 1987. He leaves the music industry when his career was at the peak. Junaid Jamshaid devoted himself to the preaching of Islam. He was also in the list of top 500 influential Muslims.\n' +
        '\n' +
        '\n' +
        ' \n' +
        'In the late 1990s’ he met with renowned scholar Maulana Tariq Jameel in a Tablighi Ijtima. Junaid Jamshaid leaves the music industry and devoted himself for religion. He came back into music for a while and leave the music industry completely in 2004. Junaid struggled financially after leaving the music industry. He came over his financial problems after the success of her clothing brand J. Junaid Jamshaid recites many naats from 2003 until his death. His popular Naat albums are Jalwa-e-Janan, Badr-Ud-Duja, Hadi-Ul-Anaam, Noor-ul-Huda, etc.\n' +
        '\n' +
        'He died in a plane crash with his second wife while he was returning to Islamabad from Chitral. His plane was crashed near Havelian killing all the passengers. His death remembered as a national tragedy. Maulana Tariq Jameel offered his funeral prayer and he was buried in Darul Uloom Karachi.'
    } else if (props.name === 'Yusuf Memon') {
      naat_khawan_desc =
        'Yusuf Memon had taken part in numerous gatherings where he recited his poems in praise of Prophet Muhammad (PBUH). A few famous naats recited by him included ‘Maire Kamli Wale Ki Shan’ and ‘Huzoor Koi Aisa Intizam’.'
    } else if (props.name === 'Marghoob Ahmed Hamdani') {
      naat_khawan_desc =
        'Marghoob Ahmed Hamdani is a popular Naat Khawan of his era. He was born in Lahore. His father Manzoor Ahmed Hamdani was also a Naat Khawan. He recites Naats on Radio Pakistan and PTV for many years. Marghoob Ahmed appeared on Radio and PTV for the first time in 1967. He has recited many famous Urdu and Punjabi Naats. His Brother Mehboob Ahmed Hamdani is also a well-known Naat Khawan. He won Pride of Performance by the Government of Pakistan. He has a huge fan following worldwide who likes Naats in his melodious and powerful voice. Some of his popular Naats are Banay Hain Dono Jahan, Wo Suey Lalazar Phirte Hain, Jab Masjid E Nabvi Ke Minar Nazar Aaye, Amina Bibi Ke Gulshan Mein Etc.'
    } else if (props.name === 'Syed Sabihuddin Sabih Rehmani') {
      naat_khawan_desc =
        'Sabih Rehmani was born at Karachi in 1965. He is reciting Naats for last 30 years. He served as Sr, Manager Planning Research in QTV till 2010. Since 1994, he is serving as Secretary-General for Naat research center. His unique style of reciting Naat renewed globally.'
    } else if (props.name === 'Syed Zabeeb Masood Shah') {
      naat_khawan_desc =
        'Syed Zabeeb Masood Shah is a very popular Naat Khawan. He has learned Naat Khawani from Syed Manzoor ul Kounain Shah Sb. He belongs from Wah Cantt Rawalpindi. Zabeeb Masood has won many prizes from school level to national level. He has performed in all over Pakistan and worldwide.'
    } else if (props.name === 'Muhammad Umair Zubair') {
      naat_khawan_desc =
        'Muhammad Umair Zubair is very popular Naat Khawan of Pakistan. He has a melodious voice with a unique style. Some of his popular Naats are Nabi Ka Zikar Hi, Q Log Ye Kehtay Hain, Dil Karda Allah Allah, etc.'
    } else if (props.name === 'Amjad Sabri') {
      naat_khawan_desc =
        "Amjad Sabri's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice."
    } else if (props.name === 'Akhtar Hussain Qureshi') {
      naat_khawan_desc =
        "Akhtar Hussain Qureshi's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice."
    } else if (props.name === 'Abdul Hameed Rana') {
      naat_khawan_desc =
        "Abdul Hameed Rana's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice."
    } else if (props.name === 'Mehboob Ahmed Hamdani') {
      naat_khawan_desc =
        "Mehboob Ahmed Hamdani's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice."
    } else if (props.name === 'Abdul Basit Hassani') {
      naat_khawan_desc =
        "Abdul Basit Hassani's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice."
    } else if (props.name === 'Rehan Naqshbandi') {
      naat_khawan_desc =
        "Rehan Naqshbandi's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice."
    } else if (props.name === 'Hafiz Abu Bakar') {
      naat_khawan_desc =
        "Hafiz Abu Bakar's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice."
    } else if (props.name === 'Anas Younus') {
      naat_khawan_desc =
        "Anas Younus's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through his voice."
    }

    //Lady naat_khawans
    else if (props.name === 'Hooria Faheem Qadri') {
      naat_khawan_desc =
        'Hooria Faheem Qadri was born in Karachi. She is one of the most famous female Naat Khawan nowadays. She is appearing regularly on QTV and other Islamic TV channels. Hooria started Naat Khawani just at the age of five years. Her more than 20 albums released till now. She was married to Faheem ud Din Nizami and has one son and one daughter. She traveled to many countries for Mahafil-e-Naats, especially in the UK. Hooria Faheem has millions of fans worldwide. She always encourages females to recite Naats.'
    } else if (props.name === 'Javeria Saleem') {
      naat_khawan_desc =
        'Javeria Saleem is a well-known female Naat Khawan. She has recited Naats on QTV, ARY Digital, PTV, HUM TV, Geo TV, etc. She also recites Naats in Ramzan transmission on different TV channels. Allah Tala has gifted her with a great melodious voice. She is also Hafiza of Quran and known as the one of the best Qariya in Pakistan. She has won many prizes from the provincial level to the national level. Javeria Saleem is also known for her simplicity.'
    } else if (props.name === 'Abida Khanum') {
      naat_khawan_desc =
        "Abida Khanum's popular naats in audio and video. Listen and immerse yourself into love of Prophet Mohammad Mustafa (S.A.W) through her voice."
    } else if (props.name === 'Umme Habiba') {
      naat_khawan_desc =
        'Umme Habiba is one of the best female Naat Khawan of Pakistan and around the world. Fan following around the world enjoy her melodious voices. She is reciting Naats around 40 years now. She born in Karachi and moved to America 30 years ago. Umme Habiba started reciting Naats in 1969. She awarded with Pride of Performance by the President of Pakistan. She traveled throughout the USA and around the world for Naat reciting. Her most famous Naats are Jab Zaban Pay Muhammad Ka Naam, Meri Janib Bhi Ho Aik Nighahe Karam, Kabay Per Pari jab Pehli Nazar and so on.'
    } else if (props.name === 'Hina Nasrullah') {
      naat_khawan_desc =
        'Hina Nasrullah is a popular Naat Khawan and Sufi singer in Pakistan. She discovered by Yousuf Salahuddin. She appeared in many musical programs in his childhood. Hina blessed with a sweet melodious voice.'
    } else if (props.name === 'Ayesha Abdul Basit') {
      naat_khawan_desc =
        'Ayesha Basit got fame after reciting the Naat Hasbi Rabbi Jalallah. She belongs to India and now lived in Abu Dhabi UAE with her parents. More than 45 million people have watched her Naat Hasbi Rabbi on YouTube.'
    } else if (props.name === 'Syeda Amber Saleem') {
      naat_khawan_desc =
        'Amber Saleem is a well-known female Naat Khawan of Pakistan. She has recited many famous Naats. You can listen or download famous Naats of Amber Saleem with one click on audionaats.com. Beautiful Naat by Amber Saleem.'
    } else if (props.name === 'Zara Rasheed') {
      naat_khawan_desc =
        'Zara Rasheed born in Karachi. No doubt she is true Aashiq-e-Rasool (Peace Be Upon Him). Her voice is like voice of heaven. The beautiful Naat recited by this True Ashiq-e-Rasool (Peace Be Upon Him) girl. she has no light in her eyes but i believe her heart is lit with the light of Eshq e Rasool (Peace Be Upon Him).'
    } else {
      naat_khawan_desc = 'No Description'
    }
  } else {
    //Categories
    if (naat_khawan_name === 'Ala Hazrat') {
      naat_khawan_desc =
        'Imam Ahmed Raza Khan (radi Allahu Ta’ala anhu), popularly known as “A’la Hadrat” in the Islamic world, was born on the 10th of Shawaal 1272 A.H. (14 June 1856) in Bareilly Shareef, India. His father, Moulana Naqi Ali Khan (radi Allahu Ta’ala anhu), and illustrious grandfather, Moulana Rida Ali Khan (radi Allahu Ta’ala anhu), were great Awliya and celebrated theologians recognized as such by academic circles of the entire sub-continent.\n' +
        '\n' +
        'Imam Ahmed Raza Khan Al-Qaaderi (radi Allahu Ta’ala anhu) received his Sanads (Certificates of Proficiency) in Fiqh, Tafseer, Usool-e-Fiqha and Sihah Sitta (Six Authentic Books of Ahadith) from the learned Ulema of his period, including learned Arab Scholars from Makkatul Mukarramah.\n' +
        '\n' +
        'A’la Hadrat (radi Allahu Ta’ala anhu) achieved the status of a versatile scholar and obtained a high distinction in over 50 branches of learning pertaining to the Ancient Sciences, Modern Sciences, Oriental learning, Islamic Jurisprudence, Tassawuf, and numerous other branches.\n' +
        '\n' +
        'On his visit to Makkatul Mukarramah and Madinatul Munawwarah, Imam Ahmed Raza Al-Qaaderi (radi Allahu Ta’ala anhu) was treated with great dignity and was conferred the title of “Imam-e-Ahle-Sunnat” by eminent Ulema. He was also hailed as the Mujaddid “Revivalist” of the Century. Deadly opposed to fanatical extremism, he expounded the pure teachings of the Ahle Sunnah Wa Jamaah. He acted as a shield against those who wanted to assault the principles of the Ahle Sunnah Wa Jamaah.\n' +
        '\n' +
        'As a devout Sufi, A’la Hadrat (radi Allahu Ta’ala anhu) was awarded the Ijaazah and Khilafat (Certificate of Spiritual Successorship) in the Qaaderiya Silsila (Order), as well as in 13 other branches of Sufism. His Sheikh-ut-Tareeqah (Spiritual Master) was the celebrated Grand Master, Sayyid Sha Ale-Rasool Ahmadi Al-Qaaderi Al Barkaati (radi Allahu Ta’ala anhu) of Mahrerah Shareef, India.\n' +
        '\n' +
        'As an author, Imam Ahmed Raza Khan Al-Qaaderi (radi Allahu Ta’ala anhu) has left to his credit more than a 1 000 (thousand) books on 50 different subjects ranging from Tafseer, Logic, Grammar, Literature, Islamic Jurisprudence, Education, Sociology, Astronomy, Mathematics, Physics, History, Science of History, Engineering, Biographies, Philosophy, Mysticism to Persian, Arabic, Urdu and Hindi Literature.\n' +
        '\n' +
        'A’la Hadrat, Imam Ahmed Raza Khan Al-Qaaderi (radi Allahu Ta’ala anhu) left behind many students and Khulafa (Spiritual Successors) who have achieved world-wide prestige for their contributions towards Islam and some, for their exemplary missionary activities. Among them were A’la Hadrat’s (radi Allahu Ta’ala anhu) two sons, Hujjatul Islam, Moulana Sha Haamid Raza Al-Qaaderi Barkaati Noori and Mufti-e-Azam-e-Hind, Moulana Sha Mustapha Raza Al-Qaaderi Barkaati Noori (radi Allahu Ta’ala anhuma); Muballigh-e-Islam, Moulana Abdul Aleem Siddique Al-Qaaderi (radi Allahu Ta’ala anhu), Sadrush Sharee’ah Moulana Amjad Ali Al-Qaaderi (radi Allahu Ta’ala anhu), Malikul Ulema Sayyid Sha Zafruddeen Behari (radi Allahu Ta’ala anhu), Sayyidain Kareemain Abul Barkaat Sayyid Ahmed and Abul Hasanaat Sayyid Muhammad (radi Allahu Ta’ala anhuma), Ustaazul Ulema Moulana Sha Ahmed Mukhtaar Siddique Meerati (radi Allahu Ta’ala anhu), Qutbe Madina Sheikh Ziauddeen Ahmed Siddique Al-Qaaderi Madani (radi Allahu Ta’ala anhu) – to name, but a few.\n' +
        '\n' +
        'A’la Hadrat, Imam Ahmed Raza Khan (radi Allahu Ta’ala anhu) passed away on Friday, the 25th of Safar 1340 A.H. (28 October 1921), when the Mu’azzin was calling “Hayya alal Falaah” in the Azaan of Jummah Salaah. Inna lillahi wa inna ilayhi Raaji’un.\n' +
        '\n' +
        'All in all: Imam Ahmed Raza Khan Al-Qaaderi (radi Allahu Ta’ala anhu) was a multi-dimensional scholar, orator, teacher, author, spiritual leader and true servant of Islam. It can justifiably be said that the mission of A’la Hazrat (radi Allahu Ta’ala anhu) has become a world-wide movement which is gaining popularity day-by-day.'
    } else if (naat_khawan_name === 'Huzoor Mufti e Azam Hind') {
      naat_khawan_desc =
        "Maulana Mustafa Raza Khan, commonly known among the Muslims of the Indian sub-continent as 'Mufti-e-Azam Hind' was born in Bareilly (India) in 1892. His father, Moulana Ahmed Raza Khan (1856 – 1921) (radi Allahu anhu), and grandfather, Moulana Naqi Ali Khan (d. 1880) (radi Allahu anhu), were recognised as great scholars of Islam in Islamic academic circles all over the world."
    } else if (naat_khawan_name === 'Tajush Shariah') {
      naat_khawan_desc =
        "Taajush Shari'ah, Hadrat Allama Mufti Mohammed Akhtar Raza Khan Azhari Qadri, son of Mufassir-e-A'zam, Hadrat Mawlana Ibrahim Raza Khan (Alaihir Rahmah), son of Hujjatul Islam, Hadrat Mawlana Haamid Raza Khan (Alaihir Rahmah), son of Imam-e-Ahle Sunnah, Mujaddid-e-Deen-o-Millat, Ash Shah Imam Ahmad Raza Khan (Alaihir Rahmah) was born on the 25th of Safar 1942, in the district of Saudagran in the city of Bareilly Shareef, India. When he reached the age of 4 years, 4 months, 4 days, his respected father, Hadrat Mawlana Ibrahim Raza (Alaihir Rahmah), who was a great Islamic Scholar of his age, arranged for his Bismillah Kwaani (Commencement of Islamic Studies)."
    } else if (naat_khawan_name === 'Ustaad e Zaman') {
      naat_khawan_desc =
        'Shahensha-e- Sukhan, Huzoor Ustad-e-Zaman Hazrat Moulana Hasan Raza Khan RadiaAllahu anhu the younger brother of Aala Hazrat Moulana Ash Shah Imam Ahmad Raza Khan RadiaAllahu anhu son of Raisul Atqiya Hazrat Maulana Naqi Ali Khan RadiaAllahu anhu.'
    } else if (naat_khawan_name === 'Manqabat e Ahle Bait') {
      naat_khawan_desc =
        'By covering Hazrat Ali (A.S), Hazrat Fatima (S.A), Hazrat Imam Hassan (A.S) and Hazrat Imam Hussain (A.S) in his blanket, the Holy Prophet (peace and blessings be upon him) declared them to be members of his family and said that he was leaving behind two things of vital importance: the Holy Quran and his family, Ahl-e-Bayt. The Holy Prophet (peace and blessings be upon him) connected the love and high esteem of his family with the Holy Quran with a view to making it part of ‘Iman’ and declared that there should be no reduction in importance and respect of the Prophet’s family and Hazrat Ali (A.S) is part of Ahl-e-Bayt.'
    } else if (naat_khawan_name === 'Manqabat e Gause Aazam') {
      naat_khawan_desc =
        "The birth of Huzoor Ghaus-e-Azam was a great blessing to the Ummah. It was the arrival of Sultanul Awliya (King of the Awliya), which had been foretold for centuries before his birth. He was born on the eve of the 1st of Ramadaan 470 Hijri in the city of Jilan and was blessed to his mother at an age when women are usually unable to have children. Sayyida Ummul Khair Fathima was sixty years old when Sarkaar-e-Ghaus-e-Azam was born. This alone was one of the astonishing incidents related to the birth of the great saint. He is a descendant of the Holy Prophet Muhammad Mustafa (Peace and Blessing be upon Him) from both his mother's and father's side. He is Hassani from his father and Hussaini from his mother. His father's name was Sayyid Abu Saleh Moosa and his mother's name was Sayyida Ummul Khair Fathima."
    } else if (naat_khawan_name === 'Manqabat e Garib Nawaz') {
      naat_khawan_desc =
        'Khwaja Moinuddin Chisthy (R.A.) was born in Seistan (East Persia) also known as Sejistan, around 537 Hijri (1135-39 a.d.) in a respected family. His father Khwaja Ghayasuddin (R.A.) and mother Syeda Bibi Ummalwara allas Bibi Mahe-Noor were the descendants of Hazrat Ali (K.A.W.) through his sons Imam Hassan and Imam Hussain Khwaja Saheb lost both his father and mother at an early age of sixteen years. He inherited an orchard and a wind mill which serve his means of livelihood. One day when Khwaja Saheb was working in his orchard, a pious dervish and majzoob. Ebrahim Qandoozi, came and took his seat under shade of a tree. When Khwaja Saheb saw him, he brought a bunch of grapes and presented it to his guest. The visitor ate those grapes and was delighted, and took something out of his bag, chewed it and then offered it to his young host. Khwaja Saheb ate it without any hesitation and at once the light of wisdom and knowledge dawned upon the young Khwaja. Thereafter he disposed of all his worldly belongings and distributed the money amongst the poor. Having thus broken all the ties with worldly affairs, he started for Samarqand and Bukhara, which were the great centres of learning, for acquiring Islamic education and Knowledge.'
    } else if (naat_khawan_name === 'Nazmi Sahab') {
      naat_khawan_desc =
        'Hazrat Hasnain Miyan Nazmi was born on 6th of Ramadan, 1365 H 4th of August, 1946 in a small town of Kasgunj, Distt. Etah, Uttar Pradesh (India). The Arabic letters of his name Syed Fadhlullah Qadri bear the year of his birth. His family name is Syed Muhammad Haider. Nazmi Miyan is the son of great Wali and Jurist of his time, Syed Shah Aley Mustafa alias Syed Miyan (rahmatullahi alaih).'
    } else if (naat_khawan_name === 'Ujaagar Sahab') {
      naat_khawan_desc =
        "Allama Nisar Ali Ujaagar is a Famous Naat Author & Alim E Deen. Some of his popular naat includes 'Aao Mere Nabi Ki Shaan Suno', 'Qadri Aastana Salamat Rahe' and 'Kaaba Dikha De Maula'. May Allah give him high status in jannat ul firdous"
    } else if (naat_khawan_name === 'Maulana Shakir Nooree') {
      naat_khawan_desc =
        "Born in Junagadh town of Gujarat State in India, Maulana Muhammed Shakir Noorie comes from a humble family background and has completed his education with memorization of the Holy Quran & its recitation, learning Islamic sciences and Ulooms relating to the Sunnah of the Holy Prophet Muhammed Swallalahu alaihi wa sallam. He has been lucky to have the chances of spending valuable times in the company of several pious & blessed friends of Allah, of his times, through whose blessings and guidance, today he rose as a mentor to several people with titles of 'Ataa e Huzoor Mufti e Azam e Hind” and 'Daaiye Kabeer'."
    } else if (naat_khawan_name === 'Madina Munawara') {
      naat_khawan_desc =
        'Al Madinah  (the ‘radiant city’ or the ’enlightened city’) or simply called: Al Madinah the ‘City of the Prophet’. It is situated in the Hejaz region of Western Saudi Arabia about 340km north of Makkah and has a population of  1,512,724. Similar to Makkah, entry to Al Madinah is restricted to Muslims only as it is a holy place.'
    } else if (naat_khawan_name === 'Makkah Sharif') {
      naat_khawan_desc =
        'Makkah is the blessed city which is the most beloved land in the sight of Allah (ﷻ) and the chosen location of His House. It was here that the final prophet and guide of the whole of mankind, Muhammad (ﷺ), was born and commenced his Prophethood.'
    } else if (naat_khawan_name === 'Ramzan') {
      naat_khawan_desc =
        'Ramzan is a time of spiritual reflection, improvement and increased devotion and worship. Ramzan is a holy month, fasting during this month is one of the five pillars of Islam. Muslims are expected to put more effort into following the teachings of Islam. The fast (sawm) begins at dawn and ends at sunset.'
    } else if (naat_khawan_name === 'Milad Un Nabi') {
      naat_khawan_desc =
        'The birth of the Holy Prophet (SAW) is the greatest favour of Allah Almighty on humanity. That is why the Muslim community celebrates the birth anniversary of the Holy Prophet (SAW) with traditional zeal and zest on the 12th of Rabi-ul-Awwal and strengthens their faith. However, some mischievous elements, who are devoid of spiritualism, speak against the celebration of birth anniversary of the Holy Prophet (SAW) and declare it as unjustified. The fact remains that in order to strengthen their relationship with the Holy Prophet (SAW). Keeping this in view, Shaykh-ul-Islam Dr Muhammad Tahir-ul-Qadri accorded special importance to the subject of Mawlid-un-Nabi (SAW) in the beginning of his preaching.'
    } else if (naat_khawan_name === 'Shab E Meraj') {
      naat_khawan_desc =
        'Shab e Miraj is known as The Night Journey in islamic faith and Muslims worldwide. Shab e Miraj observes every year in month of Islamic Date of Rajab 27. Shab e Meraj is also called Lailat al Miraj in Arabic world. Shab e Meraj also called Lailat al Miraj and Isra Wal Miraj, a historical night event much celebrated and respected in Islamic history is believed to be an important physical as well as spiritual journey in the history of Islam. The lailatul Miraj / Shab e Meraj was basically a remarkable ascension of Prophet Muhammad (صلى الله عليه وسلم) to seven heavens which he took on back of an animal Buraq.'
    } else if (naat_khawan_name === 'Dua') {
      naat_khawan_desc =
        'Dua means supplication, which means communicating with a deity, comes closer than invocation. It is an important part of our faith and belief. We ask from Almighty Allah for everything that we want in our life no matter its how big or small. Dua is a means of keeping in touch with Allah and due to Dua, we get closer to our Lord. So, Dua is a source of keeping the supplicant closer to the Almighty Allah.\n' +
        '\n' +
        'Dua is a form of prayer and it could be made at any time anywhere there is no fixed time and place of making Dua. It is considered as a weapon with the help of which we can change our situation by asking help from Almighty Allah. Dua can change the destiny so try to make Dua in every situation because with Dua you will always get closer to Almighty Allah. It maintained a person’s belief in One Lord and it rejects all forms of idolatry. Dua is essentially a submission to the Creator and a demonstration of a person’s need for Allah.'
    } else if (naat_khawan_name === 'Punjabi') {
      naat_khawan_desc =
        "Punjabi is the most widely spoken language in Pakistan and the third most-spoken native language in the Indian subcontinent. Punjabi language from it's core has a tone of love and poetry."
    } else if (naat_khawan_name === 'English') {
      naat_khawan_desc =
        'English is the largest language by number of speakers. English has developed over the course of more than 1,400 years. There are many writers who wrote Naats, Hamds, Salaams, and Manqabats in English language.'
    } else if (naat_khawan_name === 'Arabic') {
      naat_khawan_desc =
        'Arabic is the language of the Qurʾān (The sacred book of Islam) and the religious language of all Muslims. Since understanding Arabic is so important, most Muslims try to learn at least the basics. And a great many Muslims pursue further study in order to understand the full text of the Quran in its original form.'
    } else if (naat_khawan_name === 'Syed Abbas Muttaqui') {
      naat_khawan_desc =
        'Dr. Syed Abbas Muttaqui has authored five books of poetry in Urdu and four in Persian. He started his career as an amazing teacher of government school and later taughed as a professor in Usmania University. Right from the fifth standard, he started composing poetry and showed it to his Urdu teacher, Saaduddin Qureshi. When the teacher was transferred to another school, Muttaqui was sad and penned a farewell poem in honour of his teacher. He continued his education and did his Master’s and PhD in Urdu and, after retirement, in Persian language.'
    } else {
      naat_khawan_desc = 'Description not found'
    }
  }

  // function handleClick() {
  //     console.log(props.name);
  //     if (props.category === "naat_khawan") {
  // 		history.push("/naatkhawans" + "/" + naat_khawan_name);
  // 	} else {
  // 		history.push("/categories" + "/" + naat_khawan_name);
  // 	}
  // }

  //  {/*<Button variant="primary" size="lg" onClick={this.handleClick} block>
  //         {this.props.naat_name}
  //       </Button>*/}

  return (
    <Container style={{ cursor: 'pointer', marginTop: '10px' }}>
      {props.category === 'naat_khawan' && (
        <Link
          to={'/naatkhawans/' + naat_khawan_name}
          style={{ color: theme_font_color }}
        >
          <Row>
            <Col xs={3} style={{ paddingLeft: '0px' }}>
              <Image
                src={process.env.PUBLIC_URL + naat_kahwan_image_address}
                alt={naat_khawan_name}
                style={{ width: '80px', height: '80px' }}
              />
            </Col>
            <Col xs={9} style={{ paddingLeft: '5px' }}>
              <p style={naat_khawan_name_s}>
                <b>{naat_khawan_name}</b>
              </p>
              <p className="naat_khawan_desc" style={naat_khawan_desc_s}>
                {naat_khawan_desc}
              </p>
            </Col>
          </Row>
        </Link>
      )}
      {props.category !== 'naat_khawan' && (
        <Link
          to={'/categories/' + naat_khawan_name}
          style={{ color: theme_font_color }}
        >
          <Row>
            <Col xs={3} style={{ paddingLeft: '0px' }}>
              <Image
                src={process.env.PUBLIC_URL + naat_kahwan_image_address}
                alt={naat_khawan_name}
                style={{ width: '80px', height: '80px' }}
              />
            </Col>
            <Col xs={9} style={{ paddingLeft: '5px' }}>
              <p style={naat_khawan_name_s}>
                <b>{naat_khawan_name}</b>
              </p>
              <p className="naat_khawan_desc" style={naat_khawan_desc_s}>
                {naat_khawan_desc}
              </p>
            </Col>
          </Row>
        </Link>
      )}
    </Container>
  )
}

// const button_style = {
//     textAlign: 'left'
// };

const naat_khawan_name_s = {
  marginLeft: '5px',
  marginTop: '0px',
  marginBottom: '0px',
}

const naat_khawan_desc_s = {
  marginLeft: '5px',
  marginTop: '0px',
  fontSize: 'small',
  overFlow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'webkit-box',
}

export default NaatKhawanRow
