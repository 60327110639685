import React, { useState } from 'react'
import './App.css'
import { Form, Button, Container, Row, Col, Modal } from 'react-bootstrap'
import Fuse from 'fuse.js'
import nathData from './nath-data/naat-json.json'
import SearchRow from './SearchRow.js'

function SearchForm(props) {
  var naat_search_list = []
  //const [naat_fuse_list, setNaatFuseList] = useState([]);
  var naat_fuse_list = []
  var temp_list = []
  const [search_list_div, setSearchListDiv] = useState([])

  for (let naat of nathData.naats) {
    let naat_name = naat.naat_name
    let naat_body = naat.naat_body
    let naat_body_string = naat_body.toString()
    naat_body_string = naat_body_string.replace(/[,-]/g, ' ')
    naat_body_string = naat_body_string.replace(/[^\w\s]/gi, '')
    pushNaatInList(naat_name, naat_body_string)
  }
  for (let naat of nathData.salaams) {
    let naat_name = naat.naat_name
    let naat_body = naat.naat_body
    let naat_body_string = naat_body.toString()
    naat_body_string = naat_body_string.replace(/[,-]/g, ' ')
    naat_body_string = naat_body_string.replace(/[^\w\s]/gi, '')
    pushNaatInList(naat_name, naat_body_string)
  }
  for (let naat of nathData.hamds) {
    let naat_name = naat.naat_name
    let naat_body = naat.naat_body
    let naat_body_string = naat_body.toString()
    naat_body_string = naat_body_string.replace(/[,-]/g, ' ')
    naat_body_string = naat_body_string.replace(/[^\w\s]/gi, '')
    pushNaatInList(naat_name, naat_body_string)
  }
  for (let naat of nathData.manqabats) {
    let naat_name = naat.naat_name
    let naat_body = naat.naat_body
    let naat_body_string = naat_body.toString()
    naat_body_string = naat_body_string.replace(/[,-]/g, ' ')
    naat_body_string = naat_body_string.replace(/[^\w\s]/gi, '')
    pushNaatInList(naat_name, naat_body_string)
  }

  const fuse_options = {
    // isCaseSensitive: false,
    includeScore: true,
    // shouldSort: true,
    includeMatches: true,
    // findAllMatches: false,
    minMatchCharLength: 4,
    // location: 0,
    // threshold: 0.6,
    // distance: 100,
    // useExtendedSearch: false,
    ignoreLocation: true,
    // ignoreFieldNorm: false,
    keys: [
      //"naat_name",
      'naat_body',
    ],
  }

  //console.log(naat_search_list);

  const fuse = new Fuse(naat_search_list, fuse_options)
  const [search_pattern, setSearchPattern] = useState('N/A')

  const [show_search_results, setShowSearchResults] = useState(false)
  function handleSearchResultsClose() {
    setShowSearchResults(false)
    setSearchButtonHit(false)
  }
  const handleSearchResultsShow = () => setShowSearchResults(true)

  const [searchButtonHit, setSearchButtonHit] = useState(false)

  return (
    <React.Fragment>
      <Form onSubmit={(event) => event.preventDefault()} autoComplete="on">
        {/* <Form autoComplete="on"> */}
        <Container>
          <Row className="justify-content-center">
            <Col xs={8} className="ml-2 mt-3">
              <Form.Group>
                <Form.Control
                  type="search"
                  placeholder="Search For Any Keyword"
                  autoComplete="on"
                  onChange={(e) => setSearchPattern(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSearchClick()
                    }
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs="auto">
              <Button className="mt-3" onClick={handleSearchClick}>
                Search
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>

      <Modal
        show={show_search_results}
        onHide={handleSearchResultsClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Search Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {searchButtonHit && getSearchResults()} */}
          {search_list_div.map((list_item, idx) => {
            return (
              <SearchRow
                naat_name={capital_letter(list_item.naat_name)}
                naat_desc={list_item.naat_desc}
                length={list_item.length}
              />
            )
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSearchResultsClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )

  function handleSearchClick() {
    //console.log("Search Button Clicked");
    //console.log(search_pattern);
    //setNaatFuseList
    naat_fuse_list = fuse.search(search_pattern)

    setSearchButtonHit(true)
    // console.log(naat_fuse_list);
    // console.log(naat_fuse_list.length);

    // console.log("I got called");
    // console.log(naat_fuse_list.length);

    if (naat_fuse_list.length >= 25) {
      for (let i = 0; i < 25; i++) {
        let fuse_naat_name = naat_fuse_list[i].item.naat_name

        //Need a better approach than the wrong indices approach
        let common_string = longest_common_string(
          search_pattern.toLowerCase(),
          naat_fuse_list[i].item.naat_body.toLowerCase(),
        )
        //console.log(common_string);
        /*let matches_array = naat_fuse_list[i].matches[1].indices;
                
                let max_match_first = 0;
                let max_match_second = 0;
                let max_match_length = 0;
                for (let j = 0; j < matches_array.length; j++) {
                    if ((matches_array[j][1] - matches_array[j][0]) > max_match_length) {
                        max_match_length = matches_array[j][1] - matches_array[j][0];
                        max_match_first = matches_array[j][0];
                        max_match_second = matches_array[j][1];
                    }
                }*/

        // for(let j = 0; j < matches_array2.length; j++) {
        //     if((matches_array2[j][1] - matches_array2[j][0]) > max_match_length) {
        //         max_match_length = matches_array2[j][1] - matches_array2[j][0];
        //         max_match_first = matches_array2[j][0];
        //         max_match_second = matches_array2[j][1];
        //     }
        // }

        // if (naat_fuse_list[i].item.naat_body.length < max_match_second + 300) {
        //     naat_desc = naat_fuse_list[i].item.naat_body.substring(max_match_first);
        // } else {
        //     naat_desc = naat_fuse_list[i].item.naat_body.substring(max_match_first, max_match_second + 299);
        // }

        //different approach than the indices provided by the fuse component

        let naat_desc = ''
        let common_start_index = naat_fuse_list[i].item.naat_body
          .toLowerCase()
          .indexOf(common_string)
        let sub_naat_body = naat_fuse_list[i].item.naat_body.substring(
          common_start_index,
        )
        if (sub_naat_body.length < 300) {
          naat_desc = sub_naat_body
        } else {
          naat_desc = sub_naat_body.substring(0, 299)
        }

        pushTempListDiv(fuse_naat_name, naat_desc, common_string.length)
      }
    } else {
      for (let i = 0; i < naat_fuse_list.length; i++) {
        let fuse_naat_name = naat_fuse_list[i].item.naat_name

        let common_string = longest_common_string(
          search_pattern.toLowerCase(),
          naat_fuse_list[i].item.naat_body.toLowerCase(),
        )
        console.log(common_string)
        let naat_desc = ''
        let sub_naat_body = naat_fuse_list[i].item.naat_body.substring(
          naat_fuse_list[i].item.naat_body.indexOf(common_string),
        )
        if (sub_naat_body.length < 300) {
          naat_desc = sub_naat_body
        } else {
          naat_desc = sub_naat_body.substring(0, 299)
        }

        pushTempListDiv(fuse_naat_name, naat_desc, common_string.length)
      }
    }

    //console.log(search_list_div);
    //console.log(temp_list);
    setSearchListDiv(temp_list)
    handleSearchResultsShow()
  }

  function longest_common_string(lcstest, lcstarget) {
    let matchfound = 0
    let lsclen = lcstest.length
    let result = ''
    for (let lcsi = 0; lcsi < lcstest.length; lcsi++) {
      let lscos = 0
      for (let lcsj = 0; lcsj < lcsi + 1; lcsj++) {
        let re = new RegExp('(?:.{' + lscos + '})(.{' + lsclen + '})', 'i')
        let temp = re.test(lcstest)
        re = new RegExp('(' + RegExp.$1 + ')', 'i')
        if (re.test(lcstarget)) {
          matchfound = 1
          result = RegExp.$1
          break
        }
        lscos = lscos + 1
      }
      if (matchfound == 1) {
        return result
        break
      }
      lsclen = lsclen - 1
    }
    result = ''
    return result
  }

  // function getSearchResults() {
  //     return (<React.Fragment>{search_list_div}</React.Fragment>);
  // }

  function pushNaatInList(naat_name, naat_body) {
    var o = new Object()
    o.naat_name = naat_name
    o.naat_body = naat_body
    naat_search_list.push(o)
  }

  function pushTempListDiv(naat_name, naat_desc, length) {
    var o = new Object()
    o.naat_name = naat_name
    o.naat_desc = naat_desc
    o.length = length
    temp_list.push(o)
  }

  function capital_letter(str) {
    str = str.toLowerCase()
    if (str.includes(' ')) {
      str = str.split(' ')
      for (let i = 0, x = str.length; i < x; i++) {
        if (str[i].charAt(0).match(/^[A-Za-z]+$/)) {
          str[i] = str[i].charAt(0).toUpperCase() + str[i].substr(1)
        } else {
          str[i] =
            str[i].charAt(0) + str[i].charAt(1).toUpperCase() + str[i].substr(2)
        }
      }
      return str.join(' ')
    } else {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  }
}

export default SearchForm
