import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
// import { useWindowScroll } from "react-use";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBaseballBall } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
// import SingleNaat from "./SingleNaat.js";
import './other.css'
//import Home from "./Home.js";

function SearchRow(props) {
  const history = useHistory()
  const search_name = props.naat_name
  const search_desc = props.naat_desc
  const length = props.length
  const highlight_part = search_desc.substring(0, length)
  const other_part = search_desc.substring(length)

  function handleClick() {
    console.log(props.naat_name)

    history.push('/home/' + props.naat_name)
    //return <SingleNaat naat_name={props.naat_name} naat_type={props.naat_type} />;
  }

  //  {/*<Button variant="primary" size="lg" onClick={this.handleClick} block>
  //         {this.props.naat_name}
  //       </Button>*/}

  return (
    <Container onClick={handleClick} style={{ cursor: 'pointer' }}>
      <Row>
        <Col xs={12} sm={12} style={{ paddingLeft: '5px' }}>
          <p style={naat_khawan_name_s}>
            <b>{search_name}</b>
          </p>
          <p className="naat_khawan_desc" style={naat_khawan_desc_s}>
            <mark style={{ backgroundColor: 'yellow' }}>{highlight_part}</mark>
            {other_part}
          </p>
        </Col>
      </Row>
    </Container>
    //good
  )
}

// const button_style = {
//     textAlign: 'left'
// };

const naat_khawan_name_s = {
  marginLeft: '5px',
  marginTop: '0px',
  marginBottom: '0px',
}

const naat_khawan_desc_s = {
  marginLeft: '5px',
  marginTop: '0px',
  fontSize: 'small',
  overFlow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'webkit-box',
}

export default SearchRow
