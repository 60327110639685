import React, { useState, useRef } from 'react';
import './App.css';
import './other.css';
import nathData from './nath-data/naat-json.json';
import { Navbar, Nav, NavDropdown, Button, Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import './fonts/font_c.otf';
import './fonts/algreya_regular.otf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
//import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

//search bar
import { Container, Row, Col } from 'react-bootstrap';

import SearchForm from './SearchForm.js';

//Button Group
import { ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import ScrollToTop from './ScrollToTop';
import NaatRow from './NaatRow.js';

const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey) || ''
  );

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);

  return [value, setValue];
};

const Home = (props) => {
  const [theme_name, setThemeName] = useStateWithLocalStorage('theme_name');
  const [theme_background_color, setBackgroundColor] = useStateWithLocalStorage(
    'theme_background_color'
  );
  const [nav_background_color, setNavBackgroundColor] =
    useStateWithLocalStorage('nav_background_color');
  const [theme_font_color, setThemeFontColor] =
    useStateWithLocalStorage('font_color');
  const [theme_font_color1, setThemeFontColor1] =
    useStateWithLocalStorage('font_color1');
  const [theme_font_color2, setThemeFontColor2] =
    useStateWithLocalStorage('font_color2');

  const setThemeDefault = () => {
    setThemeName('default');
    setBackgroundColor('white');
    setNavBackgroundColor('#000051');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };
  const setThemeMadina = () => {
    setThemeName('madina');
    setBackgroundColor('#F0FFF0');
    setNavBackgroundColor('#003300');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };
  const setThemeMecca = () => {
    setThemeName('mecca');
    //setBackgroundColor('#3B3B3B')
    setBackgroundColor('#3F3F3F');
    setNavBackgroundColor('#212121');
    setThemeFontColor('white');
    setThemeFontColor1('#33FFE9');
    setThemeFontColor2('#FF9962');
    window.location.reload();
  };
  const setThemeAlaHazrat = () => {
    setThemeName('ala_hazrat');
    setBackgroundColor('#F0FFFF');
    setNavBackgroundColor('#00363a');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };

  if (theme_name === '') {
    setThemeName('default');
    setBackgroundColor('white');
    setNavBackgroundColor('#000051');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
  }

  // if (theme_name === '') {
  //   setThemeDefault()
  // }

  //   const setBackgroundDefault = () => setBackgroundColor('white')
  //   const setThemeMadina = () => setBackgroundColor('#F0FFF0')
  //   const setThemeMecca = () => setBackgroundColor('#1A1A1A')
  //   const setThemeAlaHazrat = () => setBackgroundColor('#F0FFFF')

  var previous_char = 'A';
  //const [theme, setTheme] = useState("light");
  //console.log("The Page type to render is: " + props.type);
  var naat_type = props.type;
  const [sortedNaats, setSortedNaats] = useState(sortNaats(nathData));

  const [show_not_found, setShowNotFound] = useState(false);
  const handleNotFoundClose = () => setShowNotFound(false);
  const handleNotFoundShow = () => setShowNotFound(true);
  const [not_found_letter, setNotFoundLetter] = useState('X');

  const a_ref = useRef(null);
  const b_ref = useRef(null);
  const c_ref = useRef(null);
  const d_ref = useRef(null);
  const e_ref = useRef(null);
  const f_ref = useRef(null);
  const g_ref = useRef(null);
  const h_ref = useRef(null);
  const i_ref = useRef(null);
  const j_ref = useRef(null);
  const k_ref = useRef(null);
  const l_ref = useRef(null);
  const m_ref = useRef(null);
  const n_ref = useRef(null);
  const o_ref = useRef(null);
  const p_ref = useRef(null);
  const q_ref = useRef(null);
  const r_ref = useRef(null);
  const s_ref = useRef(null);
  const t_ref = useRef(null);
  const u_ref = useRef(null);
  const v_ref = useRef(null);
  const w_ref = useRef(null);
  const x_ref = useRef(null);
  const y_ref = useRef(null);
  const z_ref = useRef(null);

  /*
	  var ref_obj = [];
    
	  const setRefObj = (ref) => {
		this.ref_obj.push(ref);
	  };
	*/

  function goto_abcd_section(c) {
    //console.log("I am in the abcd function");
    //debugger
    //console.log(c);
    //Handle the null refrences
    setNotFoundLetter(c);

    if (c === 'A') {
      if (a_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: a_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'B') {
      if (b_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: b_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'C') {
      if (c_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: c_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'D') {
      if (d_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: d_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'E') {
      if (e_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: e_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'F') {
      if (f_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: f_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'G') {
      if (g_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: g_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'H') {
      if (h_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: h_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'I') {
      if (i_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: i_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'J') {
      if (j_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: j_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'K') {
      if (k_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: k_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'L') {
      if (l_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: l_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'M') {
      if (m_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: m_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'N') {
      if (n_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: n_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'O') {
      if (o_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: o_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'P') {
      if (p_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: p_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'Q') {
      if (q_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: q_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'R') {
      if (r_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: r_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'S') {
      if (s_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: s_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'T') {
      if (t_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: t_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'U') {
      if (u_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: u_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'V') {
      if (v_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: v_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'W') {
      if (w_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: w_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'X') {
      if (x_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: x_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'Y') {
      if (y_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: y_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    } else if (c === 'Z') {
      if (z_ref.current === null) {
        handleNotFoundShow();
      } else {
        window.scrollTo({
          top: z_ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    }
  }

  function capital_letter(str) {
    str = str.toLowerCase();
    if (str.includes(' ')) {
      str = str.split(' ');
      for (let i = 0, x = str.length; i < x; i++) {
        if (str[i].charAt(0).match(/^[A-Za-z]+$/)) {
          str[i] = str[i].charAt(0).toUpperCase() + str[i].substr(1);
        } else {
          str[i] =
            str[i].charAt(0) +
            str[i].charAt(1).toUpperCase() +
            str[i].substr(2);
        }
      }
      return str.join(' ');
    } else {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }

  function sortNaats(nathData) {
    var naat_names = [];
    //console.log("the naat_type is " + naat_type);
    if (naat_type === 'home') {
      for (let naat of nathData.naats) {
        let holder = capital_letter(naat.naat_name) + ',' + naat.naat_url + ',';
        if (naat.naat_audio.length > 0) {
          holder = holder + '/audio_icon_on.png,';
        } else {
          holder = holder + '/audio_icon_off.png,';
        }
        if (naat.naat_video_link.length > 0) {
          holder = holder + '/youtube_icon_on.png';
        } else {
          holder = holder + '/youtube_icon_off.png';
        }
        naat_names.push(holder);
      }
      for (let naat of nathData.salaams) {
        let holder = capital_letter(naat.naat_name) + ',' + naat.naat_url + ',';
        if (naat.naat_audio.length > 0) {
          holder = holder + '/audio_icon_on.png,';
        } else {
          holder = holder + '/audio_icon_off.png,';
        }
        if (naat.naat_video_link.length > 0) {
          holder = holder + '/youtube_icon_on.png';
        } else {
          holder = holder + '/youtube_icon_off.png';
        }
        naat_names.push(holder);
      }
      for (let naat of nathData.hamds) {
        let holder = capital_letter(naat.naat_name) + ',' + naat.naat_url + ',';
        if (naat.naat_audio.length > 0) {
          holder = holder + '/audio_icon_on.png,';
        } else {
          holder = holder + '/audio_icon_off.png,';
        }
        if (naat.naat_video_link.length > 0) {
          holder = holder + '/youtube_icon_on.png';
        } else {
          holder = holder + '/youtube_icon_off.png';
        }
        naat_names.push(holder);
      }
      for (let naat of nathData.manqabats) {
        let holder = capital_letter(naat.naat_name) + ',' + naat.naat_url + ',';
        if (naat.naat_audio.length > 0) {
          holder = holder + '/audio_icon_on.png,';
        } else {
          holder = holder + '/audio_icon_off.png,';
        }
        if (naat.naat_video_link.length > 0) {
          holder = holder + '/youtube_icon_on.png';
        } else {
          holder = holder + '/youtube_icon_off.png';
        }
        naat_names.push(holder);
      }
      naat_names.sort();
      return naat_names;
    } else if (naat_type === 'naat') {
      for (let naat of nathData.naats) {
        let holder = capital_letter(naat.naat_name) + ',' + naat.naat_url + ',';
        if (naat.naat_audio.length > 0) {
          holder = holder + '/audio_icon_on.png,';
        } else {
          holder = holder + '/audio_icon_off.png,';
        }
        if (naat.naat_video_link.length > 0) {
          holder = holder + '/youtube_icon_on.png';
        } else {
          holder = holder + '/youtube_icon_off.png';
        }
        naat_names.push(holder);
      }
      naat_names.sort();
      return naat_names;
    } else if (naat_type === 'salaam') {
      for (let naat of nathData.salaams) {
        let holder = capital_letter(naat.naat_name) + ',' + naat.naat_url + ',';
        if (naat.naat_audio.length > 0) {
          holder = holder + '/audio_icon_on.png,';
        } else {
          holder = holder + '/audio_icon_off.png,';
        }
        if (naat.naat_video_link.length > 0) {
          holder = holder + '/youtube_icon_on.png';
        } else {
          holder = holder + '/youtube_icon_off.png';
        }
        naat_names.push(holder);
      }
      naat_names.sort();
      return naat_names;
    } else if (naat_type === 'hamd') {
      for (let naat of nathData.hamds) {
        let holder = capital_letter(naat.naat_name) + ',' + naat.naat_url + ',';
        if (naat.naat_audio.length > 0) {
          holder = holder + '/audio_icon_on.png,';
        } else {
          holder = holder + '/audio_icon_off.png,';
        }
        if (naat.naat_video_link.length > 0) {
          holder = holder + '/youtube_icon_on.png';
        } else {
          holder = holder + '/youtube_icon_off.png';
        }
        naat_names.push(holder);
      }
      naat_names.sort();
      return naat_names;
    } else if (naat_type === 'manqabat') {
      for (let naat of nathData.manqabats) {
        let holder = capital_letter(naat.naat_name) + ',' + naat.naat_url + ',';
        if (naat.naat_audio.length > 0) {
          holder = holder + '/audio_icon_on.png,';
        } else {
          holder = holder + '/audio_icon_off.png,';
        }
        if (naat.naat_video_link.length > 0) {
          holder = holder + '/youtube_icon_on.png';
        } else {
          holder = holder + '/youtube_icon_off.png';
        }
        naat_names.push(holder);
      }
      naat_names.sort();
      return naat_names;
    } else {
      for (let naat of nathData.naats) {
        let holder = capital_letter(naat.naat_name) + ',' + naat.naat_url + ',';
        if (naat.naat_audio.length > 0) {
          holder = holder + '/audio_icon_on.png,';
        } else {
          holder = holder + '/audio_icon_off.png,';
        }
        if (naat.naat_video_link.length > 0) {
          holder = holder + '/youtube_icon_on.png';
        } else {
          holder = holder + '/youtube_icon_off.png';
        }
        naat_names.push(holder);
      }
      naat_names.sort();
      return naat_names;
    }
  }

  // function handleSearchClick() {
  // 	console.log("Search button clicked");
  // 	//debugger;
  // 	//search_ref.focus();
  // 	//console.log(search_ref.select());
  // }

  // function handleSearchChange(event){
  // 	setSearchPattern(event.target.value);
  // }

  return (
    <React.Fragment>
      <Helmet>
        <title>{capital_letter(naat_type) + ' - My Naat Book'}</title>
      </Helmet>

      <ScrollToTop />

      <Navbar
        expand="lg"
        variant="dark"
        style={{ backgroundColor: nav_background_color }}
      >
        <Navbar.Brand
          href="/"
          style={{
            fontFamily: 'font_c',
            color: 'white',
            fontSize: '25px',
          }}
        >
          <img
            src={process.env.PUBLIC_URL + '/madina.png'}
            width="30"
            height="30"
            className="d-inline-block align-top"
            style={{ marginRight: '7px' }}
            alt="My Naat Book"
          />
          My Naat Book
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {/* <Nav.Link href="/">Home</Nav.Link> */}
            <Nav.Link href="/naats">Naat</Nav.Link>
            <Nav.Link href="/salaams">Salaam</Nav.Link>
            <Nav.Link href="/hamds">Hamd</Nav.Link>
            <Nav.Link href="/manqabats">Manqabat</Nav.Link>
            <Nav.Link href="/naatkhawans">Naat Khawans</Nav.Link>
            <Nav.Link href="/categories">Catergories</Nav.Link>
            <NavDropdown title="Theme" id="collasible-nav-dropdown">
              <NavDropdown.Item onClick={setThemeDefault}>
                Default
              </NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeMadina}>
                Madina
              </NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeMecca}>Mecca</NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeAlaHazrat}>
                Ala Hazrat
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="More" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/naatwebsites">
                Other Naat Websites
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/submit">
                Submit a Naat / Contact Us
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/naatbookpdf">
                Naat Books PDF
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/about">About</NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link href="/naatwebsites">Other Naat Websites</Nav.Link>
						<Nav.Link href="/about">About</Nav.Link>
						<Nav.Link className="justify-content-end" href="/app">Android App</Nav.Link> */}
          </Nav>
          <Button
            variant="success"
            href="https://drive.google.com/file/d/1WZ2I4RFURKy4ctVJzoLmZgZn9rB84iwk/view?usp=sharing"
            target="_blank"
          >
            Android App
            <span>
              <FontAwesomeIcon
                icon={faMobileAlt}
                style={{ marginLeft: '8px' }}
              />
            </span>
          </Button>
        </Navbar.Collapse>
      </Navbar>

      <Modal show={show_not_found} onHide={handleNotFoundClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Not Found</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ fontFamily: 'algreya_regular', color: 'darkgreen' }}
        >
          No Items Found for Letter <b>{not_found_letter}</b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleNotFoundClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/*search bar --------------------------------------------------------- */}

      {/* //Make the form a seprate component
			//Can also just get the ref and get input from there */}
      {/* <Form onSubmit={event => event.preventDefault()}>
				<Container>
					<Row className="justify-content-center">
						<Col xs={8} className="ml-2 mt-3">
							<Form.Group>
								<Form.Control 
									ref={(input) => { search_ref = input; }} 
									type="text" 
									placeholder="Search For Any Keyword" 
									onKeyPress={e => {
										if(e.key === 'Enter'){
											handleSearchClick();
										}
									}} 
								/>
							</Form.Group>
						</Col>
						<Col xs="auto">
							<Button className="mt-3" onClick={handleSearchClick}>Search</Button>
						</Col>
					</Row>
				</Container>
			</Form> */}
      <SearchForm />
      {/*search bar --------------------------------------------------------- */}

      {/*Alphabet Buttons --------------------------------------------------------- */}
      <Container>
        <Row className="justify-content-center">
          <ButtonToolbar className="justify-content-center">
            <Col
              className="justify-content-center mt-3 xs={12} sm={12} md={4} lg={4} xl={4}"
              style={{ textAlign: 'center' }}
            >
              <ButtonGroup className="flex">
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('A')}
                >
                  <b>A</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('B')}
                >
                  <b>B</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('C')}
                >
                  <b>C</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('D')}
                >
                  <b>D</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('E')}
                >
                  <b>E</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('F')}
                >
                  <b>F</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('G')}
                >
                  <b>G</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('H')}
                >
                  <b>H</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('I')}
                >
                  <b>I</b>
                </Button>
              </ButtonGroup>
            </Col>
            <Col
              className="justify-content-center mt-3 xs={12} sm={12} md={4} lg={4} xl={4}"
              style={{ textAlign: 'center' }}
            >
              <ButtonGroup>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('J')}
                >
                  <b>J</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('K')}
                >
                  <b>K</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('L')}
                >
                  <b>L</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('M')}
                >
                  <b>M</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('N')}
                >
                  <b>N</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('O')}
                >
                  <b>O</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('P')}
                >
                  <b>P</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('Q')}
                >
                  <b>Q</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('R')}
                >
                  <b>R</b>
                </Button>
              </ButtonGroup>
            </Col>
            <Col
              className="justify-content-center mt-3 xs={12} sm={12} md={4} lg={4} xl={4}"
              style={{ textAlign: 'center' }}
            >
              <ButtonGroup>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('S')}
                >
                  <b>S</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('T')}
                >
                  <b>T</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('U')}
                >
                  <b>U</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('V')}
                >
                  <b>V</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('W')}
                >
                  <b>W</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('X')}
                >
                  <b>X</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('Y')}
                >
                  <b>Y</b>
                </Button>
                <Button
                  style={{ backgroundColor: nav_background_color }}
                  className="btn btn-dark"
                  onClick={() => goto_abcd_section('Z')}
                >
                  <b>Z</b>
                </Button>
              </ButtonGroup>
            </Col>
            {/* <Col xs={12} sm={12} lg={6} md={6} xl={6}>
            <ButtonGroup >

            </ButtonGroup>
          </Col> */}
          </ButtonToolbar>
        </Row>
      </Container>
      {/*Alphabet Buttons --------------------------------------------------------- */}

      <Container style={{ marginTop: '25px' }}>
        <h1 style={{ color: theme_font_color }} ref={a_ref}>
          A --------------
        </h1>

        <hr></hr>
        {sortedNaats.map((naat, idx) => {
          //console.log(naat)
          var current_char = naat.charAt(0);
          var arr = naat.split(',');
          var n_name = arr[0];
          var n_url = arr[1];
          var n_audio = arr[2];
          var n_video = arr[3];

          //-----Continue from here!!!!

          if (previous_char !== current_char) {
            //different character
            previous_char = current_char;

            if (current_char === 'B') {
              //console.log("We are here in the B section")
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={b_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'C') {
              //console.log("We are here in the C section")
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={c_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'D') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={d_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'E') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={e_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'F') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={f_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'G') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={g_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'H') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={h_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'I') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={i_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'J') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={j_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'K') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={k_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'L') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={l_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'M') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={m_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'N') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={n_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'O') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={o_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'P') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={p_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'Q') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={q_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'R') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={r_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'S') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={s_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'T') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={t_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'U') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={u_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'V') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={v_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'W') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={w_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'X') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={x_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'Y') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={y_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            } else if (current_char === 'Z') {
              return (
                <div key={idx}>
                  <h1 style={{ color: theme_font_color }} ref={z_ref}>
                    {current_char} --------------
                  </h1>
                  <hr />
                  <NaatRow
                    naat_name={n_name}
                    naat_url={n_url}
                    naat_audio={n_audio}
                    naat_video={n_video}
                    naat_type={naat_type}
                  />
                  <hr />
                </div>
              );
            }
          } else {
            return (
              <div key={idx}>
                {/*<h6>{naat}</h6>*/}
                <NaatRow
                  naat_name={n_name}
                  naat_url={n_url}
                  naat_audio={n_audio}
                  naat_video={n_video}
                  naat_type={naat_type}
                />
                <hr />
              </div>
            );
          }
        })}
      </Container>
    </React.Fragment>
  );
};

// const abcd_buttons_style = {
// 	width: '250px'
// };

// const letter_seperator = {
// 	borderTop: '3px dashed red',
// 	borderColor: 'black'
// };

// const audio_icon_style = {
// 	width: '20px',
// 	height: '20px',
// 	//align: 'right'
// 	//marginRight: '10px'
// };

export default Home;
