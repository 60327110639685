import React, { useState, useEffect } from 'react';
import './App.css';
import './other.css';
// import nathData from './nath-data/naat-json.json';
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Image,
  Button,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';

//Button Group
import ScrollToTop from './ScrollToTop';

const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey) || ''
  );

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);

  return [value, setValue];
};

export const About = (props) => {
  const [theme_name, setThemeName] = useStateWithLocalStorage('theme_name');
  const [theme_background_color, setBackgroundColor] = useStateWithLocalStorage(
    'theme_background_color'
  );

  const [nav_background_color, setNavBackgroundColor] =
    useStateWithLocalStorage('nav_background_color');
  const [theme_font_color, setThemeFontColor] =
    useStateWithLocalStorage('font_color');
  const [theme_font_color1, setThemeFontColor1] =
    useStateWithLocalStorage('font_color1');
  const [theme_font_color2, setThemeFontColor2] =
    useStateWithLocalStorage('font_color2');

  const setThemeDefault = () => {
    setThemeName('default');
    setBackgroundColor('white');
    setNavBackgroundColor('#000051');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };
  const setThemeMadina = () => {
    setThemeName('madina');
    setBackgroundColor('#F0FFF0');
    setNavBackgroundColor('#003300');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };
  const setThemeMecca = () => {
    setThemeName('mecca');
    setBackgroundColor('#3F3F3F');
    setNavBackgroundColor('#212121');
    setThemeFontColor('white');
    setThemeFontColor1('#33FFE9');
    setThemeFontColor2('#FF9962');
    window.location.reload();
  };
  const setThemeAlaHazrat = () => {
    setThemeName('ala_hazrat');
    setBackgroundColor('#F0FFFF');
    setNavBackgroundColor('#00363a');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };

  if (theme_name === '') {
    setThemeName('default');
    setBackgroundColor('white');
    setNavBackgroundColor('#000051');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
  }

  // const [theme, setTheme] = useState("light");
  // console.log('The Page type to render is: ' + props.type);

  return (
    <React.Fragment>
      {/* <Navbar bg={theme} expand="lg" variant={theme}>
				<Navbar.Brand href="#home">My Naat Book</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="mr-auto">
						<Nav.Link href="/home">Home</Nav.Link>
						<Nav.Link href="/naats">Naat</Nav.Link>
						<Nav.Link href="#salaams">Salaam</Nav.Link>
						<Nav.Link href="#hamds">Hamd</Nav.Link>
						<Nav.Link href="#manqabats">Manqabat</Nav.Link>
						<Nav.Link href="#naat_khawans">Naat Khawans</Nav.Link>
						<Nav.Link href="#categories">Catergories</Nav.Link>
					</Nav>
					<Button onClick={() => theme === "light" ? setTheme("dark") : setTheme("light")} variant="outline-success">Theme</Button>
				</Navbar.Collapse>
			</Navbar> */}
      <Helmet>
        <title>About - My Naat Book</title>
      </Helmet>

      <Navbar
        expand="lg"
        variant="dark"
        style={{ backgroundColor: nav_background_color }}
      >
        <Navbar.Brand
          href="/"
          style={{
            fontFamily: 'font_c',
            color: 'white',
            fontSize: '25px',
          }}
        >
          <img
            src={process.env.PUBLIC_URL + '/madina.png'}
            width="30"
            height="30"
            className="d-inline-block align-top"
            style={{ marginRight: '7px' }}
            alt="My Naat Book"
          />
          My Naat Book
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {/* <Nav.Link href="/">Home</Nav.Link> */}
            <Nav.Link href="/naats">Naat</Nav.Link>
            <Nav.Link href="/salaams">Salaam</Nav.Link>
            <Nav.Link href="/hamds">Hamd</Nav.Link>
            <Nav.Link href="/manqabats">Manqabat</Nav.Link>
            <Nav.Link href="/naatkhawans">Naat Khawans</Nav.Link>
            <Nav.Link href="/categories">Catergories</Nav.Link>
            <NavDropdown title="Theme" id="collasible-nav-dropdown">
              <NavDropdown.Item onClick={setThemeDefault}>
                Default
              </NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeMadina}>
                Madina
              </NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeMecca}>Mecca</NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeAlaHazrat}>
                Ala Hazrat
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="More" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/naatwebsites">
                Other Naat Websites
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/submit">
                Submit a Naat / Contact Us
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/naatbookpdf">
                Naat Books PDF
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/about">About</NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link href="/naatwebsites">Other Naat Websites</Nav.Link>
						<Nav.Link href="/about">About</Nav.Link>
						<Nav.Link className="justify-content-end" href="/app">Android App</Nav.Link> */}
          </Nav>
          <Button
            variant="success"
            href="https://drive.google.com/file/d/1WZ2I4RFURKy4ctVJzoLmZgZn9rB84iwk/view?usp=sharing"
            target="_blank"
          >
            Android App
            <span>
              <FontAwesomeIcon
                icon={faMobileAlt}
                style={{ marginLeft: '8px' }}
              />
            </span>
          </Button>
        </Navbar.Collapse>
      </Navbar>

      <ScrollToTop />

      {/* style={{backgroundColor:"navajowhite"}} */}
      <Container>
        <Row style={{ textAlign: 'center', justifyContent: 'center' }}>
          <Image
            src={process.env.PUBLIC_URL + '/madina.png'}
            alt="About My Naat Book"
            style={{ width: '250px', height: '250px' }}
          />
        </Row>

        <Row
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            color: theme_font_color,
          }}
        >
          <h3>About My Naat Book</h3>
        </Row>

        <Row
          style={{
            marginTop: '25px',
            marginLeft: '10px',
            marginRight: '10px',
            color: theme_font_color,
          }}
        >
          <p>
            <b>
              The purpose of this website is to give the “Naat Lovers” and Naat
              Khawans a chance to read up, and in fact practice Naats in a
              language they are more accustomed to. i.e. Transliteration of
              Urdu, also called Roman Urdu.
            </b>
          </p>
          <p>
            For example, the present generation of youth may be fully conversant
            in Urdu, but might lack the fluency when it comes to reading and
            writing. Innumerable requests were made by this young generation,
            who are also keen to express their love for the Prophet, who was,
            is, and will be Rehmatul Lil Alameen,{' '}
            <b>Hazrat Muhammad Mustafa (SAW)</b>. It is intended therefore that
            this humble website will suffice their needs. At the moment the work
            on Naat Lyrics website has been started in Roman Urdu, Punjabi,
            Hindi, English and Arabic languages.
          </p>
          <p>
            Along with the Naats of <b>Ala Hazrat</b>, Imam of Ahley Sunnat,
            Mujaddidey Millat, Maulana, Shah Ahmed Raza Khan Barelvi,{' '}
            <b>Huzoor Mufti e Azam Hind</b> and <b>Tajush Shariah</b> we have
            also included other distinguished Naat writers such as{' '}
            <b>Alama Iqbal</b> and <b>Niyazi Sahab</b> to mention but a few.
          </p>

          <p>
            The 4 main categories observed in this website is as follows:
            <br />
            <b>1. Naats</b>
            <br />
            <b>2. Salaams to the Prophet Muhammad (SAW)</b>
            <br />
            <b>3. Hamd praising "ALLAH SUBHANUHU WA TA’ALA"</b>
            <br />
            <b>
              4. Manqabat (arranged in categories) dedicated to Ahle Bait e
              Athar, H. Ghaus Al Azam Dastagir Shaikh Muhyiddin Abdul Qadir
              Jilani (RA), Khwaja Gharib Nawaaz, Hazrat Moinuddin Chishti –
              Ajmeri (RA).
            </b>
            <br />
          </p>

          <p style={{ marginBottom: '50px' }}>
            Naat – Introductory Recitation <br />
            In certain “Mehfiley Naat” functions, you may wish to recite the
            introductory lines outlined below, before you recite the main Naat.
            <br />
            BALAGHAL ULA
            <br />
            <br />
            Shaikh Musleh-Ud-Din Saadi Shirazi (D. 1292), a persian Naat Khwaan
            of the rank of Hafiz Shirazi, Maulana Rumi, and Shaer Al-Mutanaby,
            composed the following Naat. As the Naat depicted the attributes of
            the Holy Prophet Muhammad (SAW), the Shaikh was therefore endowed
            with the honour of his vision, who showed his appreciation by
            commanding him to continue the recitation of the Naat that was
            composed for him.
            <br />
            <b>1. Balaghal Ula Be Kamalehi</b>
            <br />
            <b>2. Kashfad Duja Be Jamalehi</b>
            <br />
            <b>3. Hasunat Jameu Khesalehi</b>
            <br />
            <b>4. Sallu Alaihi Wa’aalehi</b>
            <br />
            <br />
            Please send your naats or suggestions to{' '}
            <b>ashiqerasool.naatbook@gmail.com</b>
          </p>
        </Row>
        <hr />
        <Row
          style={{
            marginTop: '25px',
            marginLeft: '10px',
            marginRight: '10px',
            color: theme_font_color,
          }}
        >
          <h2>Disclaimers for mynaatbook.com</h2>

          <p>
            If you require any more information or have any questions about our
            site's disclaimer, please feel free to contact us by email at{' '}
            <b>ashiqerasool.naatbook@gmail.com</b>
          </p>

          <p>
            All the information on this website -{' '}
            <a href="https://www.mynaatbook.com" target="_blank">
              https://www.mynaatbook.com
            </a>{' '}
            - is published in good faith and for general information purpose
            only. <b>mynaatbook.com</b> does not make any warranties about the
            completeness, reliability and accuracy of this information. Any
            action you take upon the information you find on this website
            (mynaatbook.com), is strictly at your own risk.{' '}
            <b>mynaatbook.com</b> will not be liable for any losses and/or
            damages in connection with the use of our website.
          </p>

          <p>
            From our website, you can visit other websites by following
            hyperlinks to such external sites. While we strive to provide only
            quality links to useful and ethical websites, we have no control
            over the content and nature of these sites. These links to other
            websites do not imply a recommendation for all the content found on
            these sites. Site owners and content may change without notice and
            may occur before we have the opportunity to remove a link which may
            have gone 'bad'.
          </p>

          <p>
            Please be aware that when you leave our website, other sites may
            have different privacy policies and terms which are beyond our
            control. Please be sure to check the Privacy Policies of these sites
            as well as their "Terms of Service" before engaging in any business
            or uploading any information.
          </p>

          <p>
            Please be also aware that any audio links found on our website are{' '}
            <b>not owned by us</b> and are provided by external sources. If you
            come across any external links that doesn't comply with copyright
            laws please email us at <b>ashiqerasool.naatbook@gmail.com</b>. We
            will remove those links at the earliest to comply with copyright
            laws
          </p>
        </Row>
        <Row
          style={{
            marginTop: '25px',
            marginLeft: '10px',
            marginRight: '10px',
            color: theme_font_color,
          }}
        >
          <h3>Consent</h3>
        </Row>
        <Row
          style={{
            marginLeft: '10px',
            marginRight: '10px',
            color: theme_font_color,
          }}
        >
          <p>
            By using our website, you hereby consent to our disclaimer and agree
            to its terms.
          </p>
        </Row>
        <Row
          style={{
            marginTop: '25px',
            marginLeft: '10px',
            marginRight: '10px',
            color: theme_font_color,
          }}
        >
          <h3>Update</h3>
        </Row>
        <Row
          style={{
            marginLeft: '10px',
            marginRight: '10px',
            color: theme_font_color,
          }}
        >
          <p style={{ marginBottom: '150px' }}>
            Should we update, amend or make any changes to this document, those
            changes will be prominently posted here.
          </p>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default About;
