import React, { useState, useEffect } from 'react';
import './App.css';
// import nathData from './nath-data/naat-json.json';
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Col,
  Image,
  Button,
} from 'react-bootstrap';
// import NaatKhawanRow from './NaatKhawanRow';
import './other.css';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';

//Button Group
import ScrollToTop from './ScrollToTop';

const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey) || ''
  );

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);

  return [value, setValue];
};

export const NaatBookPDF = (props) => {
  // var type = props.type;

  const [theme_name, setThemeName] = useStateWithLocalStorage('theme_name');
  const [theme_background_color, setBackgroundColor] = useStateWithLocalStorage(
    'theme_background_color'
  );

  const [nav_background_color, setNavBackgroundColor] =
    useStateWithLocalStorage('nav_background_color');
  const [theme_font_color, setThemeFontColor] =
    useStateWithLocalStorage('font_color');
  const [theme_font_color1, setThemeFontColor1] =
    useStateWithLocalStorage('font_color1');
  const [theme_font_color2, setThemeFontColor2] =
    useStateWithLocalStorage('font_color2');

  const setThemeDefault = () => {
    setThemeName('default');
    setBackgroundColor('white');
    setNavBackgroundColor('#000051');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };
  const setThemeMadina = () => {
    setThemeName('madina');
    setBackgroundColor('#F0FFF0');
    setNavBackgroundColor('#003300');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };
  const setThemeMecca = () => {
    setThemeName('mecca');
    setBackgroundColor('#3F3F3F');
    setNavBackgroundColor('#212121');
    setThemeFontColor('white');
    setThemeFontColor1('#33FFE9');
    setThemeFontColor2('#FF9962');
    window.location.reload();
  };
  const setThemeAlaHazrat = () => {
    setThemeName('ala_hazrat');
    setBackgroundColor('#F0FFFF');
    setNavBackgroundColor('#00363a');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };

  if (theme_name === '') {
    setThemeName('default');
    setBackgroundColor('white');
    setNavBackgroundColor('#000051');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
  }

  return (
    <React.Fragment>
      <Navbar
        expand="lg"
        variant="dark"
        style={{ backgroundColor: nav_background_color }}
      >
        <Navbar.Brand
          href="/"
          style={{
            fontFamily: 'font_c',
            color: 'white',
            fontSize: '25px',
          }}
        >
          <img
            src={process.env.PUBLIC_URL + '/madina.png'}
            width="30"
            height="30"
            className="d-inline-block align-top"
            style={{ marginRight: '7px' }}
            alt="My Naat Book"
          />
          My Naat Book
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {/* <Nav.Link href="/">Home</Nav.Link> */}
            <Nav.Link href="/naats">Naat</Nav.Link>
            <Nav.Link href="/salaams">Salaam</Nav.Link>
            <Nav.Link href="/hamds">Hamd</Nav.Link>
            <Nav.Link href="/manqabats">Manqabat</Nav.Link>
            <Nav.Link href="/naatkhawans">Naat Khawans</Nav.Link>
            <Nav.Link href="/categories">Catergories</Nav.Link>
            <NavDropdown title="Theme" id="collasible-nav-dropdown">
              <NavDropdown.Item onClick={setThemeDefault}>
                Default
              </NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeMadina}>
                Madina
              </NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeMecca}>Mecca</NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeAlaHazrat}>
                Ala Hazrat
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="More" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/naatwebsites">
                Other Naat Websites
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/submit">
                Submit a Naat / Contact Us
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/naatbookpdf">
                Naat Books PDF
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/about">About</NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link href="/naatwebsites">Other Naat Websites</Nav.Link>
						<Nav.Link href="/about">About</Nav.Link>
						<Nav.Link className="justify-content-end" href="/app">Android App</Nav.Link> */}
          </Nav>
          <Button
            variant="success"
            href="https://drive.google.com/file/d/1WZ2I4RFURKy4ctVJzoLmZgZn9rB84iwk/view?usp=sharing"
            target="_blank"
          >
            Android App
            <span>
              <FontAwesomeIcon
                icon={faMobileAlt}
                style={{ marginLeft: '8px' }}
              />
            </span>
          </Button>
        </Navbar.Collapse>
      </Navbar>

      <Helmet>
        <title>Naat Books PDF - My Naat Book</title>
      </Helmet>

      <ScrollToTop />

      <h3
        style={{
          textAlign: 'center',
          marginTop: '15px',
          marginBottom: '25px',
          color: theme_font_color,
        }}
      >
        <b>Naat Books PDF</b>
      </h3>
      {/* <hr className="type_1" /> */}

      <Container>
        <a
          href="https://drive.google.com/file/d/1ITa4ymG9MmoGONPDTkJjcuHqTsPDNNWp/view?usp=sharing"
          target="_blank"
        >
          <Row style={{ backgroundColor: 'brown' }}>
            <Col
              xs={12}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              {/* <NaatKhawanRow category="naat_khawan" name="Owais Raza Qadri" /> */}
              <Image
                src={
                  process.env.PUBLIC_URL + '/naatbooks/hadaaiq_e_bakhshish.PNG'
                }
                alt="Hadaaiq E Bakhshish By Ala Hazrat"
                style={{ width: '40%', height: 'auto' }}
              />
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>Hadaaiq E Bakhshish By Ala Hazrat</b>
              </p>
              <p
                // className="naat_khawan_desc"
                style={{
                  textAlign: 'left',
                  color: 'white',
                  fontSize: 'medium',
                  marginLeft: '10px',
                }}
              >
                The collection titled Hadaiq-e-Bakhshish, is a collection of Ala
                Hazrat's poems in praise of the Holy Prophet (Sallallahu 'alaihi
                wa Sallam), and in praise of Huzur Ghous al Azam Sheikh Abdul
                Qaadir al Jeelani Radi Allahu Anhu, in Urdu, Arabic and Farsi.
                Since it deals in Kalaam-e-Ma'rifat, only the blessed can truly
                understand its inner spiritual meanings.
              </p>
            </Col>
          </Row>
        </a>

        <hr />

        <a
          href="https://drive.google.com/file/d/1jq1p3s4SFDUw1kEX9MVt9n2ZPVWyhtRx/view?usp=sharing"
          target="_blank"
        >
          <Row style={{ backgroundColor: 'darkslateblue' }}>
            <Col
              xs={12}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              {/* <NaatKhawanRow category="naat_khawan" name="Owais Raza Qadri" /> */}
              <Image
                src={
                  process.env.PUBLIC_URL +
                  '/naatbooks/a_collection_of_most_famous_naats.PNG'
                }
                alt="A Collection Of The Most Famous Naats In English"
                style={{ width: '40%', height: 'auto' }}
              />
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>A Collection Of The Most Famous Naats In English</b>
              </p>
              <p
                // className="naat_khawan_desc"
                style={{
                  textAlign: 'left',
                  color: 'white',
                  fontSize: 'medium',
                  marginLeft: '10px',
                }}
              >
                It contains well over 300, Hamds, Na'ats, various Salaams upon
                the Prophet Hazrat Muhammad Mustafa (SAW), Manqabats, and above
                all Na'ats written by As Sahaabah, Taabi'un, Tabi' al-Tabi'in
                and Auliya Allah, 'et al reproduced from the original
                manuscripts. The book also contains Na'at Stalwarts, such as H.
                Imaame Aa'zam Abu Hanifa, Jami, Saa'di Shirazi, as well as
                contemporary, distinguished writers such as Janaab Aala Hazrat,
                Allama Hasan Raza Khan, Allama Iqbal, Niazi, Zahoori, Khaled,
                Taib, Adeeb, Saim, Iqbal A'zam, Riaz, Akbar Warsi, Naseer et al.
              </p>
            </Col>
          </Row>
        </a>

        <hr
          style={{
            marginTop: '50px',
            marginBottom: '5px',
          }}
        />

        {/* Audio Naat Websites Start Here */}
        <a href="http://naatsharif.in/books.html" target="_blank">
          <h5
            style={{
              textAlign: 'center',
              marginTop: '10px',
              marginBottom: '5px',
              color: theme_font_color,
            }}
          >
            <b>Books from http://naatsharif.in/books.html</b>
          </h5>
        </a>
        {/* <hr className="type_1" /> */}

        <hr />

        <a
          href="http://naatsharif.in/?smd_process_download=1&download_id=1580"
          target="_blank"
        >
          <Row style={{ backgroundColor: 'bisque' }}>
            <Col
              xs={12}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              {/* <NaatKhawanRow category="naat_khawan" name="Owais Raza Qadri" /> */}
              <Image
                src={process.env.PUBLIC_URL + '/naatbooks/bahaar_e_salaam.jpeg'}
                alt="Bahaar E Salaam"
                style={{ width: '40%', height: 'auto' }}
              />
              <p
                style={{
                  color: 'black',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>Bahaar E Salaam</b>
              </p>
              <p
                // className="naat_khawan_desc"
                style={{
                  textAlign: 'left',
                  color: 'black',
                  fontSize: 'medium',
                  marginLeft: '10px',
                }}
              >
                Alhamdulillah this compilation is a collection of Salaam recited
                world wide in Masaajid and Mahaafil every where. Especially
                Qaseeda Burda Shareef of Hazrat Shaykh Sharfuddin Buseeri
                Rahimahullah and Mustafa Jaane Rehmat pe Laakhon Salaam of
                Imaame Ahle Sunnat Imam Ahmed Rida Al Qadri Al Hanafi
                Rahimahullah. This book comprises of Salaam in the court of
                Rasoolullah sallallahu alaihi wa sallam, Ahlul Bait, Imame
                Hussain, Sayyiduna Abdul Qaadir Jeelani and Sultanul Hind Khwaja
                Moinuddeen Chishti Radiallahu Ta'ala anhum ajmaeen.
              </p>
            </Col>
          </Row>
        </a>

        <hr />

        <a
          href="http://naatsharif.in/?smd_process_download=1&download_id=1581"
          target="_blank"
        >
          <Row style={{ backgroundColor: 'darkslategray' }}>
            <Col
              xs={12}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              {/* <NaatKhawanRow category="naat_khawan" name="Owais Raza Qadri" /> */}
              <Image
                src={
                  process.env.PUBLIC_URL + '/naatbooks/shaan_e_ahle_bait.jpeg'
                }
                alt="Shaan E Ahle Bait"
                style={{ width: '40%', height: 'auto' }}
              />
              <p
                style={{
                  color: 'white',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>Shaan E Ahle Bait</b>
              </p>
              <p
                // className="naat_khawan_desc"
                style={{
                  textAlign: 'left',
                  color: 'white',
                  fontSize: 'medium',
                  marginLeft: '10px',
                }}
              >
                Is Kitaab 'Shaan E Ahle Bait' Ke Zariye Se Qarieen In Sha Allah
                Apne Quloob Mein Mohabbate Ahle Bait Ka Izaafa Payenge. Iske
                Shaaya Karne Ka Maqsad Yahi Hai Ke Ahle Bait Ki Ulfat Aur Ahle
                Bait Ki Mohabbat Humare Seenon Mein Samaaye. Main Is Kitaab Ke
                Compile Karne Ke Liye Biraadare Giraami Qadr Janab Sayed Khalil
                Noorie Sahab Muballighe Sunni Dawate Islami Ko Mubarakbaad Pesh
                Karta Hu Aur Qarieen Se Guzarish Karta Hu Ke Ise Khoob Khoob Aam
                Kare.
              </p>
            </Col>
          </Row>
        </a>

        <hr />

        <a
          href="http://naatsharif.in/?smd_process_download=1&download_id=1943"
          target="_blank"
        >
          <Row style={{ backgroundColor: 'salmon' }}>
            <Col
              xs={12}
              style={{
                textAlign: 'center',
                alignSelf: 'center',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              {/* <NaatKhawanRow category="naat_khawan" name="Owais Raza Qadri" /> */}
              <Image
                src={
                  process.env.PUBLIC_URL + '/naatbooks/shaan_e_gharib_nawaz.png'
                }
                alt="Shaan-e-Garib Nawaz"
                style={{ width: '40%', height: 'auto' }}
              />
              <p
                style={{
                  color: 'black',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                <b>Shaan-e-Garib Nawaz</b>
              </p>
              <p
                // className="naat_khawan_desc"
                style={{
                  textAlign: 'left',
                  color: 'black',
                  fontSize: 'medium',
                  marginLeft: '10px',
                }}
              >
                Sarkaar Khwaja Ghareeb Nawaz Radiyallahu Ta’ala Anhu Ke Manaaqib
                Par Mushtamil Ye Majmoo’a Isi Silsile Ki Ek Kadi Hai Jise
                Biraadare Azeez Sayed Khalil Noorie (Muballigh Sunni Dawate
                Islami) ne Murattab kiya hai. Mai Ghareeb Nawaz Sarkaar
                (Radiyallahu Ta’ala Anhu) Ke Tamaam Shaidaiyon Se Guzarish Karta
                Hoon Ke Ghair Mohtaat Kalaamon Se Bachte Hue In Manqabaton Ko
                Apni Zabaan Ki Zeenat Banayen Aur Apne Khwaja Ki Ulfat Ke Geet
                Gaayen
              </p>
            </Col>
          </Row>
        </a>

        <Row>
          <p className="app_background2">&nbsp;</p>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const naat_khawan_name_s = {
  //marginLeft: "5px",
  //marginTop: "0px",
  //marginBottom: "0px"
};

const naat_khawan_desc_s = {
  //marginLeft: "5px",
  //marginTop: "0px",
  fontSize: 'small',
  overFlow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'webkit-box',
};

export default NaatBookPDF;
