import React, { useState, useEffect } from 'react';
import './App.css';
import './other.css';
// import nathData from './nath-data/naat-json.json';
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import NaatKhawanRow from './NaatKhawanRow';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';

//Button Group
import ScrollToTop from './ScrollToTop';

const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey) || ''
  );

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);

  return [value, setValue];
};

export const Categories = (props) => {
  const [theme_name, setThemeName] = useStateWithLocalStorage('theme_name');
  const [theme_background_color, setBackgroundColor] = useStateWithLocalStorage(
    'theme_background_color'
  );

  const [nav_background_color, setNavBackgroundColor] =
    useStateWithLocalStorage('nav_background_color');
  const [theme_font_color, setThemeFontColor] =
    useStateWithLocalStorage('font_color');
  const [theme_font_color1, setThemeFontColor1] =
    useStateWithLocalStorage('font_color1');
  const [theme_font_color2, setThemeFontColor2] =
    useStateWithLocalStorage('font_color2');

  const setThemeDefault = () => {
    setThemeName('default');
    setBackgroundColor('white');
    setNavBackgroundColor('#000051');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };
  const setThemeMadina = () => {
    setThemeName('madina');
    setBackgroundColor('#F0FFF0');
    setNavBackgroundColor('#003300');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };
  const setThemeMecca = () => {
    setThemeName('mecca');
    setBackgroundColor('#3F3F3F');
    setNavBackgroundColor('#212121');
    setThemeFontColor('white');
    setThemeFontColor1('#33FFE9');
    setThemeFontColor2('#FF9962');
    window.location.reload();
  };
  const setThemeAlaHazrat = () => {
    setThemeName('ala_hazrat');
    setBackgroundColor('#F0FFFF');
    setNavBackgroundColor('#00363a');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
    window.location.reload();
  };

  if (theme_name === '') {
    setThemeName('default');
    setBackgroundColor('white');
    setNavBackgroundColor('#000051');
    setThemeFontColor('black');
    setThemeFontColor1('red');
    setThemeFontColor2('blue');
  }

  // const [theme, setTheme] = useState("light");
  //console.log("The Page type to render is: " + props.type);
  var type = props.type;

  return (
    <React.Fragment>
      <Navbar
        expand="lg"
        variant="dark"
        style={{ backgroundColor: nav_background_color }}
      >
        <Navbar.Brand
          href="/"
          style={{
            fontFamily: 'font_c',
            color: 'white',
            fontSize: '25px',
          }}
        >
          <img
            src={process.env.PUBLIC_URL + '/madina.png'}
            width="30"
            height="30"
            className="d-inline-block align-top"
            style={{ marginRight: '7px' }}
            alt="My Naat Book"
          />
          My Naat Book
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {/* <Nav.Link href="/">Home</Nav.Link> */}
            <Nav.Link href="/naats">Naat</Nav.Link>
            <Nav.Link href="/salaams">Salaam</Nav.Link>
            <Nav.Link href="/hamds">Hamd</Nav.Link>
            <Nav.Link href="/manqabats">Manqabat</Nav.Link>
            <Nav.Link href="/naatkhawans">Naat Khawans</Nav.Link>
            <Nav.Link href="/categories">Catergories</Nav.Link>
            <NavDropdown title="Theme" id="collasible-nav-dropdown">
              <NavDropdown.Item onClick={setThemeDefault}>
                Default
              </NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeMadina}>
                Madina
              </NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeMecca}>Mecca</NavDropdown.Item>
              <NavDropdown.Item onClick={setThemeAlaHazrat}>
                Ala Hazrat
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="More" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/naatwebsites">
                Other Naat Websites
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/submit">
                Submit a Naat / Contact Us
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/naatbookpdf">
                Naat Books PDF
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/about">About</NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link href="/naatwebsites">Other Naat Websites</Nav.Link>
						<Nav.Link href="/about">About</Nav.Link>
						<Nav.Link className="justify-content-end" href="/app">Android App</Nav.Link> */}
          </Nav>
          <Button
            variant="success"
            href="https://drive.google.com/file/d/1WZ2I4RFURKy4ctVJzoLmZgZn9rB84iwk/view?usp=sharing"
            target="_blank"
          >
            Android App
            <span>
              <FontAwesomeIcon
                icon={faMobileAlt}
                style={{ marginLeft: '8px' }}
              />
            </span>
          </Button>
        </Navbar.Collapse>
      </Navbar>

      <Helmet>
        <title>{capital_letter(type) + ' - My Naat Book'}</title>
      </Helmet>

      <ScrollToTop />

      {type === 'naat_khawan' && (
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Owais Raza Qadri" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Syed Fasihuddin Soharwardi"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Farhan Ali Qadri" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Hafiz Ahmed Raza Qadri"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Hafiz Tahir Qadri" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Khursheed Ahmed" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Qari Rizwan" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Asad Attari" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Qari Shahid Mehmood"
              />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Ghulam Mustafa Qadri"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Mushtaq Attar Qadri"
              />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Zulfiqar Ali Hussaini"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Siddique Ismail" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Mahmood Ul Hassan Ashrafi"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Waheed Zafar Qasmi" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Milad Raza Qadri" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Abdul Rauf Rufi" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Bilal Qadri" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Shahbaz Qamar Fareedi"
              />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Imran Sheikh Attari"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Hafiz Nisar Ahmed Marfani"
              />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Khalid Hasnain Khalid"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Amir Liaqat" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Sami Yusuf" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Rehan Qadri" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Hafiz Tassawor Attari"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Imran Mustafa Attari"
              />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Furqan qadri" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Junaid Jamshed" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Yusuf Memon" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Marghoob Ahmed Hamdani"
              />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Syed Sabihuddin Sabih Rehmani"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Syed Zabeeb Masood Shah"
              />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Muhammad Umair Zubair"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Amjad Sabri" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Akhtar Hussain Qureshi"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Abdul Hameed Rana" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Mehboob Ahmed Hamdani"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Abdul Basit Hassani"
              />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Rehan Naqshbandi" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Hafiz Abu Bakar" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Anas Younus" />
            </Col>
          </Row>

          <hr />

          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="naat_khawan"
                name="Hooria Faheem Qadri"
              />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Javeria Saleem" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Abida Khanum" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Umme Habiba" />
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Hina Nasrullah" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Ayesha Abdul Basit" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Syeda Amber Saleem" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="naat_khawan" name="Zara Rasheed" />
            </Col>
          </Row>

          <br />
        </Container>
      )}

      {/* For categories add below */}

      {type === 'categories' && (
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="categories" name="Ala Hazrat" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="categories"
                name="Huzoor Mufti e Azam Hind"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="categories" name="Tajush Shariah" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="categories" name="Ustaad e Zaman" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="categories"
                name="Manqabat e Ahle Bait"
              />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="categories"
                name="Manqabat e Gause Aazam"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="categories"
                name="Manqabat e Garib Nawaz"
              />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="categories" name="Nazmi Sahab" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="categories" name="Ujaagar Sahab" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow
                category="categories"
                name="Maulana Shakir Nooree"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="categories" name="Madina Munawara" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="categories" name="Makkah Sharif" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="categories" name="Ramzan" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="categories" name="Milad Un Nabi" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="categories" name="Shab E Meraj" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="categories" name="Dua" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="categories" name="Punjabi" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="categories" name="English" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="categories" name="Arabic" />
            </Col>
            <Col xs={12} md={6}>
              <NaatKhawanRow category="categories" name="Syed Abbas Muttaqui" />
            </Col>
          </Row>

          <br />
        </Container>
      )}
    </React.Fragment>
  );

  function capital_letter(str) {
    str = str.toLowerCase();
    if (str.includes(' ')) {
      str = str.split(' ');
      for (let i = 0, x = str.length; i < x; i++) {
        if (str[i].charAt(0).match(/^[A-Za-z]+$/)) {
          str[i] = str[i].charAt(0).toUpperCase() + str[i].substr(1);
        } else {
          str[i] =
            str[i].charAt(0) +
            str[i].charAt(1).toUpperCase() +
            str[i].substr(2);
        }
      }
      return str.join(' ');
    } else {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }
};

// const naat_khawan_name = {
// 	marginLeft: "5px",
// 	marginTop: "0px",
// 	marginBottom: "0px"
// };

// const naat_khawan_desc = {
// 	marginLeft: "5px",
// 	marginTop: "5px"
// };

export default Categories;
